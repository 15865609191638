import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

//管理vuex
export default new Vuex.Store({
	state: {
		websiteState: '',
		//pc登录弹窗
		loginModal: {
			show: false
		},
		//m 登录弹窗
		mLoginModal: {
			show: false
		},
		//提示弹窗
		tipModal: {
			show: false,
			text: '',
			showBtns: false,
			fun: null,
			cancelFun: null,
			confirmFun: null,
			subBtnText: '',
			cancelBtnText: '',
			ico: '',
		},
		userInfo: '',
		searchText: '',//搜索内容
		occupyDate: '',//占领信息
		killDate: '',//boss轨迹
		spaceDate: '',//我选择的战舰
		walletDate: false,//钱包弹框
		refreshDate: false,//刷新
		matchingDate: false,//匹配状态
		KICKED_OUT: false,
		chatList:'',//im消息
	},
	mutations: {
		//设置网站信息
		SetWebsiteState(state, data) {
			state.websiteState = data;
			localStorage.setItem("websiteState", JSON.stringify(data));
		},
		//获取网站信息
		GetWebsiteState(state) {
			return state.websiteState ? state.websiteState : JSON.parse(localStorage.getItem('data'));
		},
		//pc显示tip
		ShowTipModal(state, data) {
			state.tipModal.show = true;
			state.tipModal.text = data.text;
			state.tipModal.showBtns = data.showBtns ? data.showBtns : false;
			state.tipModal.fun = data.fun ? data.fun : null;
			state.tipModal.confirmFun = data.confirmFun ? data.confirmFun : null;
			state.tipModal.confirmBtnText = data.confirmBtnText ? data.confirmBtnText : '确定';
			state.tipModal.cancelFun = data.cancelFun ? data.cancelFun : null;
			state.tipModal.cancelBtnText = data.cancelBtnText ? data.cancelBtnText : '取消';
			state.tipModal.ico = data.ico ? data.ico : 'fail';
		},
		//pc
		CloseTipModal(state, data) {
			state.tipModal.show = false;
			state.tipModal.text = '';
		},
		ShowLoginModal(state) {
			state.loginModal.show = true;
		},
		//pc
		CloseLoginModal(state) {
			state.loginModal.show = false;
		},
		//m
		ShowMLoginModal(state) {
			state.mLoginModal.show = true;
		},
		//m
		CloseMLoginModal(state) {
			state.mLoginModal.show = false;
		},
		//获取用户信息
		GetUserInfo(state) {
			if (localStorage.getItem('userInfo') && localStorage.getItem('userInfo') != 'undefined') {
				state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
			} else {
				state.userInfo = JSON.parse(localStorage.getItem('user'));
			}
		},
		//清空用户信息
		ClearUserInfo(state) {
			localStorage.removeItem('userInfo');
			state.userInfo = '';
		},
		//更新搜索框内容
		updateSearch(state, newData) {
			state.searchText = newData
		},
		//更新占领星球信息
		updateOccupy(state, newData) {
			state.occupyDate = newData
		},
		//更新boss轨迹
		updateKill(state, newData) {
			state.killDate = newData
		},
		//更新战舰信息
		updateSpace(state, newData) {
			state.spaceDate = newData
		},
		//钱包弹框
		updateWallet(state, newData) {
			state.walletDate = newData
		},
		updateRefresh(state, newData) {
			state.refreshDate = newData
		},
		//匹配状态
		updateMatching(state, newData) {
			state.matchingDate = newData
		},
		//被踢下线
		updateKICKEDOUT(state, newData) {
			state.KICKED_OUT = newData
		},
		updateChatList(state, newData) {
			state.chatList = newData
		},
	}
})