<template>
  <div>
    <div class="pc-header">
      <div class="box">
        <a href="https://www.soulland.xyz/">
          <img class="logo" src="@/assets/pc/header/logo-icon1.png" alt="">
        </a>
        <div style="flex: 1;"></div>
        <div class="nav">
          <div v-for="(item, index) in $t('header.nav')" :key="index">
            <div v-if="index == 3">
              <p v-if="userInfo" class="nav-title" :class="{ 'nav-select': item.path == navIndex }"
                @click="navChange(item.path)">{{ item.name }}</p>
            </div>
            <p v-else class="nav-title" :class="{ 'nav-select': item.path == navIndex }" @click="navChange(item.path)">
              {{
            item.name }}</p>
          </div>
        </div>
        <div class="header-search flex">
          <img class="header-search-icon" src="@/assets/pc/header/search-icon2.png" alt="">
          <el-input class="header-search-input" v-model.trim="search" :placeholder="$t('common.search')"
            @change="inputChange"></el-input>
          <!-- <p class="header-search-line"></p> -->
          <!-- <img @click="searchClose" class="header-search-close" src="@/assets/pc/header/close_icon2.png"
            alt=""> -->
        </div>
        <div v-if="userInfo" style="position: relative;">
          <!-- <img style="width: 24px;cursor: pointer;margin-top: 3px;margin-left: 30px;" @click="walletShow = true"
            src="@/assets/pc/common/wallet_icon.png" alt=""> -->
          <img style="width: 24px;cursor: pointer;margin-top: 3px;margin-left: 30px;" @click="walletStateFun"
            src="@/assets/pc/common/wallet_icon.png" alt="">
          <wallet :state="walletState" @close="walletClose"></wallet>
        </div>
        <div class="language-box" @mouseenter="languageState = true" @mouseleave="languageState = false">
          <img class="language" src="@/assets/pc/header/language.png" alt="">
          <transition name="el-zoom-in-top">
            <div v-show="languageState" class="language-layer">
              <div class="language-layer-list" @click="setLanguage($t('zh'))" @mouseover="over(0)" @mouseout="out(0)">
                <img class="language-layer-icon" :src="languageList[0].url1" alt="">
                <p class="language-layer-name">中文</p>
              </div>
              <div class="language-layer-list" @click="setLanguage($t('en'))" @mouseover="over(1)" @mouseout="out(1)">
                <img class="language-layer-icon" :src="languageList[1].url1" alt="">
                <p class="language-layer-name">English</p>
              </div>
            </div>
          </transition>
        </div>
        <img v-if="$t('language.change') == 'zh'" @click="goGame" class="game-img"
          src="@/assets/m/common/enter_game_en.png" alt="">
        <img v-else @click="goGame" class="game-img" src="@/assets/m/common/enter_game_cn.png" alt="">
        <div v-if="!userInfo" class="login" @click="login()">
          <p class="login-name">{{ $t('login.log') }}</p>
          <div class="login-icon">
            <img style="width: 20px;height: 20px;" src="@/assets/pc/header/login-icon.png" alt="">
          </div>
        </div>
        <div v-if="userInfo" @mouseenter="userMouse" @mouseleave="userLeave" class="header-user flex">
          <img class="header-user-photo" :src="userInfo.userpic" alt="">
          <p class="header-user-name">{{ userInfo.username }}</p>
          <transition name="el-zoom-in-top">
            <div v-show="userState" class="header-user-layer">
              <div style="display: block;" class="header-user-wallet">
                <div class="flex" @click="exchangeStateFun(1)" style="padding: 22px 0;">
                  <img class="header-user-wallet-icon" src="@/assets/pc/header/DMD_icon.png" alt="">
                  <p class="header-user-wallet-num">{{ userBalance ?
            Math.floor(userInfo.diamond_num * 100) / 100
            : 0 }}</p>
                  <p class="header-user-wallet-name">{{ $t('wallet.Swap') }}</p>
                </div>
                <div class="flex" @click="exchangeStateFun(2)">
                  <img class="header-user-wallet-icon" src="@/assets/pc/header/SLT_icon.png" alt="">
                  <p class="header-user-wallet-num">{{ Math.floor(userInfo.integral * 100) / 100 }}</p>
                  <p class="header-user-wallet-name">{{ $t('wallet.Swap') }}</p>
                </div>
                <div @click="bpState = true" class="flex" style="margin-top: 16px;">
                  <p class="header-user-wallet-num" style="margin-left: 16px;">BP：{{ userBalance ?
            userBalance.withdrawBP
            : 0 }}</p>
                </div>
                <img @click="ruleFun" class="header-user-wallet-rule" src="@/assets/pc/wallet/rule_icon.png" alt="">
              </div>
              <div class="header-user-layer-list flex" @click="setup">
                <img class="header-user-layer-icon" src="@/assets/pc/images/user-icon2.png" alt="">
                <p class="header-user-layer-name">{{ $t('login.set') }}</p>
              </div>
              <div class="header-user-layer-list flex" @click="logout">
                <img class="header-user-layer-icon" src="@/assets/pc/images/user-icon3.png" alt="">
                <p class="header-user-layer-name">{{ $t('login.logOut') }}</p>
              </div>
            </div>
          </transition>
          <exchange :userBalanceDate="userBalance" :exchangeState="exchangeState2" :exchangeType="exchangeType"
            @close="exchangeState2 = false">
          </exchange>
        </div>
      </div>
    </div>
    <div style="width: 100px;height: 72px;"></div>
    <Login :type2="loginModal.show"></Login>
    <!-- 钱包暂未开放 -->
    <div v-if="walletShow">
      <div class="wallet-layer" @click="walletShow = false"></div>
      <div class="wallet2">
        <p class="wallet2-tips">{{ $t('wallet.tips') }}</p>
        <p class="wallet2-msg">{{ $t('wallet.msg') }}</p>
        <p class="wallet2-btn" @click="walletShow = false">{{ $t('wallet.understood') }}</p>
      </div>
    </div>
    <!-- BP值弹框 -->
    <div v-if="bpState">
      <div class="wallet-layer"></div>
      <div class="bp">
        <p class="bp-title">
          {{ $t('wallet.Attention') }}
          <img @click="bpState = false" class="bp-close" src="@/assets/pc/common/common_close_01.png" alt="">
        </p>
        <div class="bp-detail">
          <p class="bp-detail-txt">BP:{{ bpHint ? bpHint.max_user_slt.withdraw_bp : 0 }}</p>
          <p class="bp-detail-txt">
            ({{ $t('BP.txt1') }}{{ bpHint.burn_slt }}{{ $t('BP.txt4') }}{{ bpHint.proportion }}
            {{ $t('BP.txt5') }}{{ bpHint.minimux_bp }}{{ $t('BP.txt6') }})
          </p>
          <p class="bp-detail-txt">{{ $t('BP.txt2') }}:{{ bpHint ? (bpHint.max_user_slt.withdraw_bp *
            bpHint.proportion) : 0 }}</p>
          <p class="bp-detail-txt">{{ $t('BP.txt3') }}:{{ bpHint ? bpHint.max_user_slt.max_user_slt :
            0 }}</p>
        </div>
        <p @click="bpState = false" class="bp-detail-btn">{{ $t('common.Confirm') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Login from "@/components/pc/login.vue";
import wallet from "@/views/pc/wallet/walletIndex.vue";
import exchange from "@/views/pc/wallet/UserExchange.vue";
import {
  isExistaddress, setAdress, getUserbalance, getBPhint
} from '@/api/wallet';
export default {
  name: "pc-header",
  components: {
    Login, wallet, exchange
  },
  data() {
    return {
      language: '',
      navIndex: 0,
      isLogin: false,
      userState: false,
      userInfo: '',
      walletShow: false, //钱包弹框
      languageList: [
        { url1: require('@/assets/pc/common/cn_icon.png'), url2: require('@/assets/pc/common/cn_icon2.png'), transfer: '' },
        { url1: require('@/assets/pc/common/en_icon.png'), url2: require('@/assets/pc/common/en_icon2.png'), transfer: '' },
      ],
      languageState: false,
      walletState: false, //钱包页面
      exchangeState2: false, //兑换
      exchangeType: 1,//1-DMD 2-SLT
      search: '',
      addressState: 0,
      userBalance: '',
      bpState: false,
      bpHint: '',//bp提示
    };
  },
  watch: {
    $route(v) {
      this.navIndex = v.path
    },
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
        // console.log(nval)
        if (nval) {
          this.getUserbalance()
          this.isExistaddress()
        }
      }
    }
  },
  computed: {
    ...mapState(["tipModal", "loginModal"]),
    isUserInfo() {
      this.userInfo = this.$store.state.userInfo
      return this.$store.state.userInfo
    }
  },
  mounted() {
    this.navIndex = this.$route.fullPath
    this.GetUserInfo()
    this.isExistaddress()
    this.getUserbalance()
    this.getBPhint()
    // console.log(localStorage.getItem('languageStorage'))
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "GetUserInfo", "ClearUserInfo"]), //登录弹窗.
    goGame() {
      this.$router.push('/galaxyarena')
    },
    login() {
      this.ShowLoginModal()
    },
    //判断钱包地址是否存在
    isExistaddress() {
      isExistaddress().then(res => {
        if (res.errorCode) {
          return
        }
        this.addressState = res.data
      }).catch((err) => {
      });
    },
    //生成钱包地址
    setAdress() {
      this.$confirm(this.$t('wallet.msg19'), this.$t('wallet.tips'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel')
      })
        .then(_ => {
          setAdress().then(res => {
            if (res.errorCode) {
              this.$message.error(res.msg);
              return
            }
            this.isExistaddress()
          }).catch((err) => {
          });
        })
        .catch(_ => { });
    },
    walletStateFun() {
      if (this.addressState == 1) {
        this.walletState = true
      } else {
        this.setAdress()
      }
    },
    exchangeStateFun(type) {
      if (this.addressState == 1) {
        this.exchangeState2 = true
        this.exchangeType = type
      } else {
        this.setAdress()
      }
    },
    setLanguage(val) {
      localStorage.setItem('languageStorage', val);
      this.language = val;
      this.$i18n.locale = val;
    },
    homeGo() {
      this.$router.push('/')
      this.navIndex = this.$route.path
      // selected
    },
    //切换导航
    navChange(url) {
      this.navIndex = url
      this.$router.push(url)
      this.search = null
      this.$store.commit('updateSearch', this.search)
    },
    // 鼠标悬浮事件
    userMouse() {
      this.userState = true
    },
    userLeave() {
      this.userState = false
    },
    // 个人中心
    userCenter() {
      this.$router.push('/user')
    },
    //设置
    setup() {
      this.$router.push('/setUp')
    },
    //退出登录
    logout() {
      this.$confirm(this.$t('common.logoutMsg'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel')
      })
        .then(_ => {
          localStorage.clear();
          this.ClearUserInfo()
          this.GetUserInfo()
          this.$router.push('/')
        })
        .catch(_ => { });
    },
    //鼠标悬浮切换图片
    over(e) {
      this.languageList[e].transfer = this.languageList[e].url1;
      this.languageList[e].url1 = this.languageList[e].url2;
    },
    out(e) {
      this.languageList[e].url1 = this.languageList[e].transfer;
    },
    walletClose() {
      this.walletState = false
    },
    //触发搜索
    inputChange() {
      this.$store.commit('updateSearch', this.search)
    },
    searchClose() {
      this.search = null
      this.$store.commit('updateSearch', this.search)
    },
    ruleFun() {
      this.$alert(this.$t('common.SLT'), this.$t('wallet.Attention'), {
        confirmButtonText: this.$t('common.Confirm'),
        callback: action => {

        }
      });
    },
    //查询用户钱包信息
    getUserbalance() {
      getUserbalance().then(res => {
        if (res.errorCode) {
          return
        }
        this.userBalance = res.data
      }).catch((err) => {
      });
    },
    //查询BP提示
    getBPhint() {
      getBPhint().then(res => {
        if (res.errorCode) {
          return
        }
        this.bpHint = res.data
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// ::-webkit-scrollbar {
//   display: none;
// }
::v-deep .el-input__inner {
  background: #F8F9FC;
  font-size: 14px;
  color: #222222;
  border: none;
  padding: 0;

  &::placeholder {
    font-size: 14px;
  }
}

.pc-header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 89;

  .box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 72px;

    .logo {
      width: 214px;
      height: auto;
      cursor: pointer;
      margin-top: -4px;
    }



    .nav {
      display: flex;
      align-items: center;
      margin-top: 2px;
      overflow: hidden;

      .nav-title {
        font-size: 16px;
        color: #222222;
        // margin-right: 52px;
        margin: 0 40px 0 10px;
        height: 24px;
        padding-bottom: 5px;
        cursor: pointer;
        position: relative;
        // border-bottom: 2px solid #fff;
        transition: 0.2s all linear;
      }

      .nav-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 0;
        height: 100%;
        border-bottom: 2px solid #6930FF;
        transition: 0.2s all linear;
      }

      .nav-title:hover::before {
        width: 100%;
        top: 0;
        left: 0;
        transition-delay: 0.1s;
        border-bottom-color: #6930FF;
        z-index: -1;
      }

      .nav-title:hover~.nav-title::before {
        left: 0;
      }

      .nav-select {
        border-bottom: 2px solid #6930FF;
        font-family: 'bold';
        // margin-bottom: 0;
      }
    }

    .language-box {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      .language {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 30px 0 30px;
      }

      .language-layer {
        width: 138px;
        height: 98px;
        background: #F9FAFD;
        border-radius: 16px;
        position: absolute;
        top: 58px;
        left: -26px;

        .language-layer-list {
          display: flex;
          align-items: center;
          margin: 20px 0 0 30px;
          cursor: pointer;

          .language-layer-icon {
            width: 19px;
            height: auto;
          }

          .language-layer-name {
            font-size: 14px;
            color: #222222;
            margin-left: 6px;
            font-family: 'Medium';
          }
        }

        .language-layer-list:hover .language-layer-name {
          color: #6F38FF;
        }
      }
    }

    .search {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }

    //搜索框
    .header-search {
      // margin: 0 20px 0 34px;
      width: 200px;
      background: #F8F9FC;
      height: 43px;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s;

      .header-search-icon {
        width: 18px;
        height: auto;
        margin: 0 13px;
      }

      .header-search-input {
        flex: 1;
        background: #F8F9FC;

      }

      .header-search-close {
        width: 18px;
        height: auto;
        margin-right: 14px;
      }

      .header-search-line {
        width: 1px;
        height: 10px;
        background: #DBDCE5;
        margin: 0 16px;
      }

      .header-search-clear {
        font-size: 14px;
        color: #222222;
        margin-left: 20px;
      }

    }

    .game-img {
      width: auto;
      height: 36px;
      margin-right: 30px;
    }

    .login {
      width: 134px;
      height: 40px;
      background: rgba(34, 34, 34, 0.03);
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      line-height: 22px;
      justify-content: center;
      cursor: pointer;

      .login-name {
        font-size: 14px;
        color: #222222;
        font-family: 'Medium';
      }

      .login-icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }

    .header-user {
      cursor: pointer;
      position: relative;
      height: 100px;

      .header-user-photo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      .header-user-name {
        margin-left: 10px;
        font-size: 16px;
        color: #222222;
        line-height: 24px;
        font-family: 'Regular';
        white-space: nowrap;
        /* 防止换行 */
        text-overflow: ellipsis;
        /* 超出隐藏显示省略号 */
        overflow: hidden;
        width: 50px;
      }

      .header-user-layer {
        width: 180px;
        // height: 194px;
        padding-bottom: 20px;
        background: #F9FAFD;
        position: absolute;
        top: 80px;
        right: -24px;
        border-radius: 16px;

        .header-user-wallet {
          width: 160px;
          height: 122px;
          background-image: url('@/assets/pc/header/bg_icon.png');
          background-size: 100% auto;
          margin: 10px auto 0 auto;
          position: relative;

          .header-user-wallet-icon {
            width: 16px;
            height: auto;
            margin-left: 16px;
          }

          .header-user-wallet-num {
            flex: 1;
            font-size: 12px;
            color: #fff;
            margin-left: 6px;
          }

          .header-user-wallet-name {
            font-size: 12px;
            color: #FFDAB7;
            font-family: 'Medium';
            margin-right: 16px;
          }

          .header-user-wallet-rule {
            width: 16px;
            height: auto;
            position: absolute;
            top: 2px;
            right: 4px;
          }
        }

        .header-user-layer-list {
          margin: 20px 0 0 26px;

          .header-user-layer-icon {
            width: 16px;
            height: 16px;
          }

          .header-user-layer-name {
            font-size: 14px;
            color: #161B2C;
            font-family: 'Regular';
            margin-left: 6px;
          }

          .header-user-layer-name:hover {
            color: #6F38FF;
          }
        }
      }
    }
  }
}

.wallet-layer {
  width: 100%;
  height: 100%;
  background: #000000;
  box-shadow: 0px 0px 60px 0px rgba(18, 13, 37, 0.2);
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}

.wallet2 {
  width: 422px;
  height: 450px;
  background-image: url('@/assets/pc/common/wallet_bg.png');
  background-size: 100% auto;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -225px;
  margin-left: -211px;
  z-index: 91;

  .wallet2-tips {
    font-size: 16px;
    color: #222222;
    padding-top: 190px;
    text-align: center;
    font-family: 'Bold';
  }

  .wallet2-msg {
    width: 162px;
    font-size: 14px;
    color: #676A79;
    line-height: 24px;
    text-align: center;
    margin: 10px auto 20px auto;
  }

  .wallet2-btn {
    width: 168px;
    height: 59px;
    border-radius: 18px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    margin: 0 auto;
    background-image: url('@/assets/pc/common/wallet_bg2.png');
    background-size: 100% auto;
  }
}

//bp值
.bp {
  width: 400px;
  height: 400px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -200px;
  z-index: 91;
  border-radius: 12px;
  padding-top: 20px;

  .bp-title {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    position: relative;
    font-family: 'Bold';

    .bp-close {
      width: 8px;
      height: auto;
      position: absolute;
      top: 0;
      right: 20px;
      cursor: pointer;
    }
  }

  .bp-detail {
    width: 340px;
    height: 250px;
    border-radius: 12px;
    background: #F9FAFC;
    margin: 20px auto;
    padding: 22px 20px;

    .bp-detail-txt {
      font-size: 14px;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  .bp-detail-btn {
    width: 160px;
    height: 40px;
    border-radius: 4px;
    background: #5B38FF;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
