<template>
  <!-- 登录弹窗 -->
  <div class="log-modal">
    <div v-show="dialogVisible" @click="closeFun()" class="layer"></div>
    <div>
      <div class="log-layer" :style="{ bottom: dialogVisible ? '0' : '-1000px' }">
        <div class="log-box">
          <div class="log-header">
            <div class="log-header-detail">
              <p class="log-header-title">{{ $t('login.log') }}</p>
              <img @click="closeFun()" class="log-header-close" src="@/assets/pc/header/close_icon.png" alt="">
            </div>
          </div>
          <!-- 登录 -->
          <div v-if="showType == 0">
            <div class="log-detail">
              <p class="log-hello">{{ $t('login.welcome') }}</p>
              <!-- <p class="log-introduce">欢迎介绍语欢迎介绍语，欢迎介绍语欢迎介绍语</p> -->
              <div v-if="logType == 0">
                <div class="log-input">
                  <el-input v-model="email" class="pass_input" :placeholder="$t('login.placeholder1')"></el-input>
                  <p v-show="show" @click="getCode()" class="log-getCode">{{ $t('login.getCode') }}</p>
                  <p v-show="!show" class="log-getCode-msg">{{ count }}s{{ $t('login.again') }}</p>
                </div>
                <div class="log-input">
                  <el-input v-model="emailCode" class="pass_input" :placeholder="$t('login.placeholder2')"></el-input>
                </div>
              </div>
              <div v-if="logType == 1">
                <div class="log-input">
                  <el-input v-model="email" class="pass_input" :placeholder="$t('login.placeholder1')"></el-input>
                </div>
                <div class="log-input">
                  <el-input v-model="emailPassword" type="password" class="pass_input"
                    :placeholder="$t('login.placeholder3')"></el-input>
                </div>
              </div>
              <div class="log-nav">
                <p class="nav-register" @click="registerReturn(1, 0)">{{ $t('login.register') }}</p>
                <div style="flex:1"></div>
                <p v-if="logType == 0" class="nav-forget" @click="logType = 1" style="margin-left: 20px;">{{
      $t('login.passwardLog') }}</p>
                <p v-if="logType == 1" class="nav-forget" @click="logType = 0" style="margin-left: 20px;">{{
      $t('login.emailLog') }}</p>
                <p class="nav-forget" style="text-decoration: underline;margin-left: 10px;"
                  @click="registerReturn(1, 1)">
                  {{ $t('login.Forget') }}</p>
              </div>
              <p v-if="logType == 0" class="log-on" @click="logFun()">{{ $t('login.logIn') }}</p>
              <p v-if="logType == 1" class="log-on" @click="pwdLogin()">{{ $t('login.logIn') }}</p>
              <!-- 添加一个 div 用作容器 -->
              <div style="margin: 0 auto;width: 500px;" id="grecaptcha"></div>
            </div>
            <p class="log-msg">{{ $t('login.msg1') }} <span @click="clickFun(1)">《{{ $t('privacy.title') }}》</span></p>
          </div>
          <!-- 注册 -->
          <div v-if="showType == 1">
            <div class="register">
              <div class="register-header">
                <img class="register-return" src="@/assets/pc/common/common_return_01.png" alt="">
                <p class="register-header-title" @click="registerReturn(2)">{{ $t('common.retuen') }}</p>
              </div>
              <p class="register-title" v-if="forget == 0">{{ $t('login.msg2') }}</p>
              <p class="register-title" v-else>{{ $t('login.find') }}</p>
              <div v-if="registerType == 0">
                <p class="register-illustrate" v-if="forget == 0">{{ $t('login.msg3') }}</p>
                <p class="register-illustrate" v-else>{{ $t('login.msg4') }}</p>
                <div class="log-input">
                  <el-input v-model="email" class="pass_input" :placeholder="$t('login.placeholder4')"></el-input>
                </div>
                <!-- 添加一个 div 用作容器 -->
                <div v-if="showType == 1">
                  <div style="margin: 0 auto;width: 310px;" id="grecaptcha"></div>
                </div>
                <p class="register-btn" @click="registerNext(1)">{{ forget == 0 ? $t('login.signUp') :
      $t('login.getCode')
                  }}</p>
              </div>
              <!-- 验证码 -->
              <div v-if="registerType == 2">
                <p class="register-illustrate">
                  {{ $t('login.msg5') }} <span style="color: #6F38FF;">{{ email }}</span> {{ $t('login.msg6') }}
                </p>
                <div class="register-code">
                  <p class="register-code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">
                    {{ codeValue[0] }}</p>
                  <p class="register-code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">
                    {{ codeValue[1] }}</p>
                  <p class="register-code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">
                    {{ codeValue[2] }}</p>
                  <p class="register-code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">
                    {{ codeValue[3] }}</p>
                  <p class="register-code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">
                    {{ codeValue[4] }}</p>
                  <p class="register-code-item" :class="codeValue.length == 5 && inputFocus ? 'code-item-active' : ''">
                    {{ codeValue[5] }}</p>
                  <el-input class="input-code" type="number" :value="codeValue" :maxlength="6" @blur="codeInputBlur"
                    @focus="codeInputFocus" @input="codeInputChange">
                  </el-input>
                </div>
                <p class="register-btn" @click="registerNext(3)">{{ $t('login.next') }}</p>
              </div>
              <!-- 设置密码 -->
              <div v-if="registerType == 1">
                <p class="register-illustrate">
                  {{ forget == 0 ? $t('login.msg7') : $t('login.msg8') }}
                </p>
                <div class="log-input">
                  <el-input v-model="password" type="password" class="pass_input"
                    :placeholder="$t('login.placeholder5')"></el-input>
                </div>
                <div class="log-input">
                  <el-input v-model="passwordNew" type="password" class="pass_input"
                    :placeholder="$t('login.placeholder5')"></el-input>
                </div>
                <div v-if="forget == 0 || forget == ''">
                  <div v-if="!inviteCodeState" class="log-input">
                    <el-input v-model="inviteCode" type="password" class="pass_input"
                      :placeholder="$t('login.placeholder6')"></el-input>
                  </div>
                  <div v-if="inviteCodeState" class="log-input">
                    <el-input v-model="inviteCode" class="pass_input" :disabled="true"
                      :placeholder="$t('login.placeholder6')"></el-input>
                  </div>
                </div>
                <p class="register-btn" @click="registerNext(2)">{{ $t('common.Confirm') }}</p>
              </div>
              <!-- 注册成功 -->
              <div v-if="registerType == 3">
                <p class="register-illustrate">
                  {{ forget == 0 ? $t('login.msg9') : $t('login.msg10') }}
                </p>
                <img class="register-img" src="@/assets/pc/common/common_img_01.png" alt="">
                <p class="register-btn" @click="registerReturn(3)">{{ $t('login.Completed') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendcode, login, emailRegister, pwdLogin, fgPwd, forGetsendcode } from '@/api/user';
import { mapMutations } from "vuex";
import im from '@/components/mixins/im.js';

import {
  getSig
} from '@/api/game';

export default {
  name: "pc-login",
  props: {
    "type2": { type2: Boolean, default: false }
  },
  mixins: [im],
  data() {
    return {
      showType: 0, //0-登录 1-注册
      logType: 0, //0-邮箱验证码 1-邮箱密码
      //倒计时
      show: true,
      count: '',
      timer: null,
      dialogVisible: this.type2,
      email: '', //邮箱
      emailCode: '', //邮箱验证码
      emailPassword: '', //邮箱密码
      registerType: 0, //0-注册邮箱 1-邮箱验证码 2-密码 3-注册成功
      inputFocus: false,
      codeValue: '',
      password: '',
      passwordNew: '',
      forget: '',//0-注册 1-忘记密码
      //验证token
      gRecaptcha: '',
      registerState: true,
      inviteCode: '',
      inviteCodeState: false,
      // IM
      userSig: '',
      userId: '',
      sdkAppId: 20009983,
    };

  },
  watch: {
    type2: {
      handler(newVal) {
        this.dialogVisible = newVal
        // if (newVal) {
        //   const script = document.createElement('script');
        //   script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LfEfSYpAAAAAA7smFxWyaDhvWR7L8slW2sXUdZT&hl=en';
        //   document.body.appendChild(script);
        // }
      },
    }
  },
  mounted() {
    if (sessionStorage.getItem('InvitationCode')) {
      this.inviteCode = sessionStorage.getItem('InvitationCode')
      this.inviteCodeState = true
    }
  },
  beforeDestroy() {
    // window.removeEventListener("load", this.gload());
  },
  methods: {
    ...mapMutations(["CloseLoginModal", "ShowTipModal", "GetUserInfo"]), //登录弹窗
    //获取验证码
    getCode() {
      var reg = /\w+@\w+\.\w+/
      if (!reg.test(this.email)) {
        this.$message.error(this.$t('error.msg1'));
        return
      }
      // this.sendCode()
      // const TIME_COUNT = 60;
      // if (!this.timer) {
      //   this.count = TIME_COUNT;
      //   this.show = false;
      //   this.timer = setInterval(() => {
      //     if (this.count > 0 && this.count <= TIME_COUNT) {
      //       this.count--;
      //     } else {
      //       this.show = true;
      //       clearInterval(this.timer);
      //       this.timer = null;
      //     }
      //   }, 1000)
      // }
      // grecaptcha.enterprise.ready(async () => {
      //   const token = await grecaptcha.enterprise.execute('6LfEfSYpAAAAAA7smFxWyaDhvWR7L8slW2sXUdZT', { action: 'LOGIN' });
      this.getTimeout('')
      // });
      // window.grecaptcha.render("grecaptcha", {
      //   sitekey: '6Ld5cyYpAAAAAP2T5NqgnetUJ__GvwR3YpmPmcD6',
      //   callback: this.getTimeout
      // });
    },
    getTimeout(token) {
      this.gRecaptcha = token
      this.sendCode()
      const TIME_COUNT = 180;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    sendCode(type) {
      var reg = /\w+@\w+\.\w+/
      if (reg.test(this.email)) {
        let data = {
          email: this.email,
          // recaptcha: this.gRecaptcha
        }
        sendcode(data).then(res => {
          if (res.errorCode) {
            this.$message.error(res.msg);
            return
          }
          if (type == 1) {
            this.registerType = 2
          } else {
            this.$message({
              message: this.$t('error.msg2'),
              type: 'success'
            })
          }
        }).catch((err) => {
          // console.log(err)
          // this.$message.error('发送失败');
        });
      } else {
        this.$message.error(this.$t('error.msg1'));
      }
    },
    forGetsendcode() {
      var reg = /\w+@\w+\.\w+/
      if (reg.test(this.email)) {
        let data = {
          email: this.email
        }
        forGetsendcode(data).then(res => {
          if (res.errorCode) {
            this.$message.error(res.msg);
            return
          }
          this.registerType = 1
        }).catch((err) => {
          // console.log(err)
          // this.$message.error('发送失败');
        });
      } else {
        this.$message.error(this.$t('error.msg1'));
      }
    },
    //邮箱-验证码登录
    logFun() {
      var reg = /\w+@\w+\.\w+/
      if (!reg.test(this.email)) {
        this.$message.error(this.$t('error.msg1'));
        return
      }
      if (this.email == '') {
        this.$message.error(this.$t('error.msg3'));
        return
      }
      if (this.emailCode == '') {
        this.$message.error(this.$t('error.msg4'));
        return
      }
      let data = {
        code: this.emailCode,
        email: this.email
      }
      login(data).then(res => {
        if (res.errorCode) {
          return
        }
        this.$message({
          message: this.$t('error.msg5'),
          type: 'success'
        })
        localStorage.setItem("auth", JSON.stringify(res.data));
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.CloseLoginModal();
        this.GetUserInfo()
        this.getSig()
      }).catch(() => {
        // this.$message.error('登录失败');
      });
    },
    registerReturn(val, type) {
      if (val == 2) {
        if (this.registerType == 0) {
          this.showType = 0
          this.email = ''
          this.forget = ''
        } else {
          this.registerType -= 1
        }
      } else if (val == 3) {
        this.closeFun();
      } else {
        this.showType = val
        this.email = ''
        this.forget = type
      }
    },
    //注册下一步
    registerNext(val) {
      // let inviteCode
      let shareType
      // 1-发送验证码
      if (val == 1) {
        if (this.forget == 0 || this.forget == '') {
          // this.submit('')
          var reg = /\w+@\w+\.\w+/
          if (!reg.test(this.email)) {
            this.$message.error(this.$t('error.msg1'));
            return
          }
          this.registerType = val
        } else {
          this.forGetsendcode()
        }
      } else if (val == 2) {
        var p = /^(?=.*[a-z])(?=.*[0-9])(?!.*([~!@&%$^\(\)#_]).*\1.*\1)[a-z0-9~!@&%$^\(\)#_]{8,16}$/;
        if (this.password !== this.passwordNew) {
          this.$message.error(this.$t('error.msg7'));
          return
        }
        if (!p.test(this.password)) {
          this.$message.error(this.$t('error.msg8'));
          return
        }
        if (this.forget == 0 || this.forget == '') {
        this.submit('')
        }else{
          this.registerType = 2
        }
        // if (this.codeValue.length != 6) {
        //   this.$message.error(this.$t('error.msg6'));
        //   return
        // }
        // this.registerType = val
      } else if (val == 3) {
        if (!this.registerState) {
          return
        }
        if (this.codeValue.length != 6) {
          this.$message.error(this.$t('error.msg6'));
          return
        }
        if (this.password !== this.passwordNew) {
          this.$message.error(this.$t('error.msg7'));
          return
        }
        // if (sessionStorage.getItem('InvitationCode')) {
        //   inviteCode = sessionStorage.getItem('InvitationCode')
        // }
        if (sessionStorage.getItem('shareType')) {
          shareType = sessionStorage.getItem('shareType')
        }
        let data = {
          code: this.codeValue,
          email: this.email,
          password: this.password,
          inviteCode: this.inviteCode,
          shareType: shareType
        }
        if (this.forget == 0 || this.forget == '') {
          emailRegister(data).then(res => {
            if (res.errorCode) {
              this.$message.error(res.msg);
              return
            }
            this.$message({
              message: '注册成功',
              type: 'success'
            })
            this.registerState = true
            this.registerType = val
            localStorage.setItem("auth", JSON.stringify(res.data));
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            this.GetUserInfo()
            this.getSig()
          }).catch((error) => {
            // this.$message.error('注册失败');
          });
        } else {
          fgPwd(data).then(res => {
            if (res.errorCode) {
              this.$message.error(res.msg);
              return
            }
            this.$message({
              message: this.$t('error.msg9'),
              type: 'success'
            })
            this.registerState = true
            this.registerType = val
          }).catch((error) => {
            // this.$message.error('注册失败');
          });
        }

      }
    },
    // 验证码输入框失去焦点
    codeInputBlur() {
      this.inputFocus = false;
    },
    // 验证码输入框获取到焦点
    codeInputFocus() {
      this.inputFocus = true;
    },
    // 验证码输入框
    codeInputChange(e) {
      if (e) {
        // 判断输入内容是否为数字
        if ((/^\+?[0-9][0-9]*$/).test(e)) {
          this.codeValue = e;
        }
      } else {
        this.codeValue = '';
      }
    },
    closeFun() {
      this.gRecaptcha = ''
      this.showType = 0
      this.registerType = 0
      this.forget = ''
      this.email = ''
      this.emailCode = ''
      this.codeValue = ''
      this.password = ''
      this.passwordNew = ''
      this.emailPassword = ''
      this.CloseLoginModal()
    },
    //邮箱密码登录
    pwdLogin() {
      var reg = /\w+@\w+\.\w+/
      if (!reg.test(this.email)) {
        this.$message.error(this.$t('error.msg1'));
        return
      }
      if (this.emailPassword == '') {
        this.$message.error(this.$t('error.msg11'));
        return
      }
      let data = {
        password: this.emailPassword,
        email: this.email
      }
      pwdLogin(data).then(res => {
        if (res.errorCode) {
          return
        }
        localStorage.setItem("auth", JSON.stringify(res.data));
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.CloseLoginModal();
        this.GetUserInfo()
        this.getSig()
      }).catch((err) => {
        // console.log(err)
        // this.$message.error('发送失败');
      });
    },
    clickFun(val) {
      if (val == 1) {
        this.$router.push('/user/privacyPolicy')
      }
    },
    submit(token) {
      var reg = /\w+@\w+\.\w+/
      if (!reg.test(this.email)) {
        this.$message.error(this.$t('error.msg1'));
        return
      }
      this.gRecaptcha = token
      this.sendCode(1)
    },
    //获取IM签名
    getSig() {
      getSig().then(res => {
        if (res.errorCode) {
          return
        }
        this.userSig = res.data.slice(0, 150) + res.data.slice(155)
        this.userId = 'master' + JSON.parse(localStorage.getItem('userInfo')).id
        // this.userId = 'beta' + JSON.parse(localStorage.getItem('userInfo')).id
        // this.userId = 'master' + JSON.parse(localStorage.getItem('userInfo')).id
        this.initClientIM();
        // this.handleClientEventsIM();
      }).catch(() => {
      });
    },
    addSpace() { }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep .el-input__inner:focus {

  background: #FFFFFF !important;

}

::v-deep .el-input__inner {
  height: 100px !important;
  border-radius: 16px !important;
  background: #F0F2F7 !important;
  border: 1px solid #F0F2F7;
  width: 690px !important;
  font-size: 28px !important;
  padding-left: 20px !important;
}

.layer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #000;
  opacity: 0.5;
  top: 0;
  left: 0;
}

.log-layer {
  font-family: 'Normal';
  width: 100%;
  height: 1100px;
  transition: all 0.3s linear 0.1s;
  position: fixed;
  z-index: 101;
  background: #F8F9FC;
  left: 0;
  border-radius: 30px 30px 0px 0px;

  // bottom: 0;
  .log-box {
    width: 100%;
    height: 100%;
    position: relative;

    .log-header {
      width: 100%;
      height: 88px;
      background: #fff;
      position: sticky;
      top: 0px;
      border-radius: 30px 30px 0px 0px;

      .log-header-detail {
        margin: 0 30px;
        height: 88px;
        display: flex;
        align-items: center;

        .log-header-title {
          font-size: 28px;
          color: #222222;
          font-family: 'Bold';
          flex: 1;
        }

        .log-header-close {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }
    }

    .log-detail {
      margin: 0 auto;

      .log-nav {
        display: flex;
        align-items: center;
        margin: 22px 30px 0 30px;

        .nav-forget {
          font-size: 26px;
          color: #222222;
          line-height: 36px;
          cursor: pointer;
        }

        .nav-register {
          font-size: 26px;
          color: #A13FFF;
          line-height: 36px;
          cursor: pointer;
          text-decoration: underline
        }
      }

      .log-hello {
        font-size: 40px;
        color: #333333;
        font-family: 'Bold';
        line-height: 58px;
        text-align: center;
        margin: 50px auto 50px auto;
        width: 600px;
        text-align: center;
      }

      .log-introduce {
        font-size: 18px;
        color: #222222;
        line-height: 30px;
        font-family: 'Light';
        text-align: center;
        margin-bottom: 50px;
      }

      .log-input {
        position: relative;
        width: 690px;
        height: 100px;
        margin: 0 auto 30px auto;
        display: block;

        .pass_input {
          width: 690px;
          height: 100px;
          font-size: 28px;
        }

        .log-getCode {
          position: absolute;
          right: 20px;
          top: 30px;
          font-size: 26px;
          color: #A13FFF;
          text-decoration: underline;
          cursor: pointer;
        }

        .log-getCode-msg {
          position: absolute;
          right: 20px;
          top: 30px;
          font-size: 26px;
          color: #9FA1AC;
        }
      }

      .log-on {
        width: 690px;
        height: 100px;
        background: linear-gradient(111deg, #3662FD 0%, #B639FF 100%);
        border-radius: 50px;
        backdrop-filter: blur(10px);
        font-size: 30px;
        color: #fff;
        text-align: center;
        line-height: 100px;
        margin: 100px auto 0 auto;
        font-family: 'Bold';
        cursor: pointer;
      }
    }

    .log-msg {
      position: absolute;
      bottom: 60px;
      text-align: center;
      width: 100%;
      font-size: 20px;
      color: #9FA1AC;

      span {
        color: #222222;
      }
    }

    //注册
    .register {
      margin: 0 30px;
      text-align: center;

      .register-header {
        display: flex;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;

        .register-return {
          width: 32px;
          height: 32px;
        }

        .register-header-title {
          font-size: 26px;
          color: #333333;
          margin-left: 10px;
        }
      }

      .register-title {
        font-size: 40px;
        color: #333333;
        line-height: 58px;
        margin-top: 44px;
        font-family: 'Bold';
      }

      .register-illustrate {
        font-size: 28px;
        color: #222222;
        line-height: 36px;
        margin: 30px auto 56px auto;
        font-family: 'Light';
        max-width: 550px;
      }

      .log-input {
        position: relative;
        width: 690px;
        height: 100px;
        margin: 0 auto 30px auto;
        display: block;
      }

      .register-btn {
        width: 690px;
        height: 100px;
        background: linear-gradient(111deg, #3662FD 0%, #B639FF 100%);
        border-radius: 50px;
        backdrop-filter: blur(10px);
        font-size: 30px;
        color: #fff;
        text-align: center;
        line-height: 100px;
        margin: 60px auto 0 auto;
        font-family: 'Bold';
        cursor: pointer;
      }

      //验证码输入框
      .register-code {
        display: flex;
        align-items: center;
        width: 690px;
        height: 100px;
        margin: 50px auto 0 auto;
        position: relative;

        .register-code-item {
          width: 98px;
          height: 98px;
          border-radius: 16px;
          border: 1px solid #F0F2F7;
          font-size: 32px;
          text-align: center;
          line-height: 98px;
          font-family: 'Bold';
          background: #F0F2F7;
          margin: 0 10px;
          color: #222222;
        }

        .input-code {
          width: 690px;
          height: 100px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }

        .code-item-active {
          background: #fff;
          border: 1px solid #A13FFF;
        }
      }

      .register-img {
        width: 275px;
        height: 208px;
        display: block;
        margin: 56px auto 0 auto;
      }
    }
  }
}
</style>
