<template>
  <div>
    <div class="footer">
      <div class="footer-logo">
        <img src="@/assets/pc/header/logo-icon2.png" alt="">
      </div>
      <div class="footer-box">
        <div class="footer-l">
          <p>{{ $t('footer.introduce') }}</p>
          <div v-if="$t('language.change') == 'en'" class="share">
            <!-- <a href=""><img class="share-list" src="@/assets/pc/header/share-icon1.png" alt=""></a> -->
            <a href="https://discord.com/invite/R9Vg37TqDS"><img class="share-list"
                src="@/assets/pc/header/share-icon2.png" alt=""></a>
            <a href="https://twitter.com/SoulLand_cn" target="_blank"><img class="share-list"
                src="@/assets/pc/header/share-icon3.png" alt=""></a>
            <a href="https://t.me/soulland_official" target="_blank"><img class="share-list"
                src="@/assets/pc/header/share-icon4.png" alt=""></a>
          </div>
          <div v-else class="share">
            <!-- <a href=""><img class="share-list" src="@/assets/pc/header/share-icon1.png" alt=""></a> -->
            <a href="https://discord.com/invite/R9Vg37TqDS"><img class="share-list"
                src="@/assets/pc/header/share-icon2.png" alt=""></a>
            <a href="https://twitter.com/soulland_en" target="_blank"><img class="share-list"
                src="@/assets/pc/header/share-icon3.png" alt=""></a>
            <a href="https://t.me/soulland_official" target="_blank"><img class="share-list"
                src="@/assets/pc/header/share-icon4.png" alt=""></a>
          </div>
          <p style="font-size: 14px;color: #222222;line-height: 18px;">{{ $t('footer.name') }}</p>
          <!-- <p style="font-size: 14px;color: #222222;margin-top: 10px;line-height: 18px;">Beijing Kaiyuan Huasheng Network Technology Co. Ltd</p> -->
          <!-- <a href="https://beian.miit.gov.cn/" target="blank" style="font-size: 14px;color: #222222;margin-top: 30px;line-height: 18px;text-decoration: underline;display: block;">京ICP备2023021721号-1</a> -->
        </div>
        <div class="footer-m" style="margin-left: 230px;flex: 1;">
          <p class="footer-title">{{ $t('footer.aboutTitle') }}</p>
          <p v-for="(item, index) in $t('footer.about')" :key="index" @click="privacyShow = true"
            style="cursor: pointer;">
            {{ item }}</p>
        </div>
        <div class="footer-r">
          <p class="footer-title">{{ $t('footer.contactTitle') }}</p>
          <div v-for="(item, index) in $t('footer.contact')" :key="index" :class="{ 'footer-content': index == 0 }"
            style="cursor: pointer;">
            <p>{{ item }}</p>
            <div class="contact-us">
              <div @mouseover="over(0)" @mouseout="out(0)">
                <div class="contact-us-list">
                  <img class="contact-us-icon" :src="serviceList[0].url1" alt="">
                  <p class="contact-us-name">Telegram</p>
                </div>
                <a class="contact-us-link" href="https://t.me/soulland_official "
                  target="_blank">https://t.me/soulland_official </a>
              </div>
              <div @mouseover="over(1)" @mouseout="out(1)">
                <div class="contact-us-list">
                  <img class="contact-us-icon" :src="serviceList[1].url1" alt="">
                  <p class="contact-us-name">Twitter</p>
                </div>
                <p class="contact-us-copy">CN：@SoulLand_cn &nbsp;&nbsp;&nbsp;&nbsp; EN@soulland_en</p>
              </div>
              <div @mouseover="over(2)" @mouseout="out(2)">
                <div class="contact-us-list">
                  <img class="contact-us-icon" :src="serviceList[2].url1" alt="">
                  <p class="contact-us-name">Discord</p>
                </div>
                <a class="contact-us-link" href="https://discord.com/invite/R9Vg37TqDS"
                  target="_blank">https://discord.com/invite/R9Vg37TqDS </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 隐私政策 -->
    <privacy-policy :type="privacyShow" @privacyClose="privacyShow = false"></privacy-policy>
  </div>
</template>
<script>

import privacyPolicy from "@/components/pc/privacyPolicy.vue";
export default {
  name: "index",
  data() {
    return {
      privacyShow: false,
      serviceList: [
        { url1: require('@/assets/pc/common/Telegram_icon2.png'), url2: require('@/assets/pc/common/Telegram_icon.png'), transfer: '' },
        { url1: require('@/assets/pc/common/Twitter_icon2.png'), url2: require('@/assets/pc/common/Twitter_icon.png'), transfer: '' },
        { url1: require('@/assets/pc/common/Discord_icon2.png'), url2: require('@/assets/pc/common/Discord_icon.png'), transfer: '' }
      ]
    };
  },
  components: {
    privacyPolicy
  },
  mounted() {
  },
  methods: {
    //鼠标悬浮切换图片
    over(e) {
      this.serviceList[e].transfer = this.serviceList[e].url1;
      this.serviceList[e].url1 = this.serviceList[e].url2;
    },
    out(e) {
      this.serviceList[e].url1 = this.serviceList[e].transfer;
    },
  },
};
</script>
<style scoped lang="scss">
p {
  font-family: 'Light';
}

.footer {
  width: 100%;
  height: 320px;
  background: #FBFBFD;
  font-family: 'Light';

  .footer-logo {
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;

    img {
      width: 229px;
      height: auto;
    }
  }

  .footer-box {
    width: 1200px;
    margin: 28px auto 0 auto;
    display: flex;

    .footer-l {
      color: #161B2C;
      font-size: 14px;
      width: 480px;
      line-height: 24px;

      .share {
        display: flex;
        align-self: center;
        margin: 30px 0 28px 0;

        .share-list {
          width: 32px;
          height: 32px;
          margin-right: 40px;
          cursor: pointer;
        }
      }
    }

    .footer-m,
    .footer-r {
      color: #222222;
      line-height: 18px;
      font-size: 14px;

      .footer-title {
        font-size: 18px;
        line-height: 24px;
        font-family: 'Regular';
        margin-bottom: 0;
        padding-top: 0;
      }

      p {
        padding-top: 20px;
      }

      //联系我们
      .footer-content {
        position: relative;

        .contact-us {
          width: 278px;
          background: #FFFFFF;
          box-shadow: 0px 0px 17px -5px rgba(19, 12, 102, 0.09);
          border-radius: 7px;
          padding: 16px 0;
          position: absolute;
          bottom: 30px;
          left: -110px;
          display: none;

          .contact-us-list {
            display: flex;
            align-items: center;
            margin-left: 16px;
            margin-bottom: 6px;

            .contact-us-icon {
              width: 20px;
              height: 20px;
            }

            .contact-us-name {
              font-size: 12px;
              color: #222222;
              margin-left: 4px;
              margin-bottom: 0;
              font-family: 'Bold';
              padding-top: 0;
            }
          }

          .contact-us-link {
            font-size: 12px;
            color: #666666;
            margin-left: 16px;
            font-family: 'Regular';
            display: block;
            margin-bottom: 8px;
          }

          .contact-us-link:hover {
            color: #4F61F7;
          }

          .contact-us-copy {
            font-size: 12px;
            color: #666666;
            margin-left: 16px;
            font-family: 'Regular';
            display: block;
            margin-bottom: 8px;
              padding-top: 0;
          }

          .contact-us-copy:hover {
            color: #222222;
          }
        }
      }
      .footer-content:hover .contact-us{
        display: block;
      }
    }
  }
}
</style>
    