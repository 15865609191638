import request from '@/utils/axios';

//获取文章标签
export function getUserbalance() {
  return request({
      url: '/api/v1/user/userbalance',
      method: 'get',
  });
}
//获取兑换基本信息
export function getProportion(data) {
  return request({
      url: '/api/v1/diamond/proportion',
      method: 'post',
      data
  });
}
//创建购买钻石数量
export function createDiamondorder(data) {
  return request({
      url: '/api/v1/diamond/creatediamondorder',
      method: 'post',
      data
  });
}
//创建钻石兑换U订单
export function createUsdorder(data) {
  return request({
      url: '/api/v1/diamond/createusdorder',
      method: 'post',
      data
  });
}
//查询链上是否有gasfee
export function gasfeeStatus(data) {
  return request({
      url: '/api/v1/diamond/gasfeestatus',
      method: 'post',
      data
  });
}
//gas费代付
export function createGasfee(data) {
  return request({
      url: '/api/v1/diamond/creategasfee',
      method: 'post',
      data
  });
}
//获取积分兑换信息
export function getProportionIntegral(data) {
  return request({
      url: '/api/v1/integral/proportion',
      method: 'post',
      data
  });
}
//购买积分
export function createusdIntegral(data) {
  return request({
      url: '/api/v1/integral/createusdintegral',
      method: 'post',
      data
  });
}
//查询交易手续费
export function getGasfee(data) {
  return request({
      url: '/api/v1/useraccount/getgasfee',
      method: 'post',
      data
  });
}
//钱包充值记录
export function getDepositlist(type,page,size) {
  return request({
      url: '/api/v1/useraccount/depositlist/' + type + '/' + page + '/' + size,
      method: 'get',
  });
}
//U兑换钻石记录
export function getUsdconversiondiamond(page,size) {
  return request({
      url: '/api/v1/useraccount/usdconversiondiamond/' + page + '/' + size,
      method: 'get',
  });
}
//兑换积分记录
export function getUsdconversionIntegral(page,size) {
  return request({
      url: '/api/v1/useraccount/usdconversionintegral/' + page + '/' + size,
      method: 'get',
  });
}
//兑换USDT记录
export function getDiamondconversionUsd(page,size) {
  return request({
      url: '/api/v1/useraccount/diamondconversionusd/' + page + '/' + size,
      method: 'get',
  });
}
//用户钱包提现记录
export function getTransfertList(type,page,size) {
  return request({
      url: '/api/v1/useraccount/transfertlist/' + type + '/' + page + '/' + size,
      method: 'get',
  });
}
//提现
export function useraccountWithdraw(data) {
  return request({
      url: '/api/v1/useraccount/useraccountwithdraw',
      method: 'post',
      data
  });
}
//查询收入支出
export function getIncome(type,page,pagesize) {
  return request({
      url: '/api/v1/diamond/income/' + type + '/' + page + '/' + pagesize,
      method: 'get',
  });
}
//判断钱包地址是否存在
export function isExistaddress(data) {
  return request({
      url: '/api/v1/user/isexistaddress',
      method: 'post',
      data
  });
}
//生产钱包地址
export function setAdress(data) {
  return request({
      url: '/api/v1/user/setaddress',
      method: 'post',
      data
  });
}
//链上SLT兑换积分SLT
export function createSltintegral(data) {
  return request({
      url: '/api/v1/integral/createsltintegral',
      method: 'post',
      data
  });
}
export function createGasfeeMW(data) {
  return request({
      url: '/api/v1/mw/creategasfee',
      method: 'post',
      data
  });
}
//查询积分兑换SLC
export function conversionProportion(data) {
  return request({
      url: '/api/v1/slttoslc/conversionproportion',
      method: 'post',
      data
  });
}
// 兑换
export function createSlttoslc(data) {
  return request({
      url: '/api/v1/slttoslc/createslttoslc',
      method: 'post',
      data
  });
}
//SLT兑换积分SLT
export function getSltconversionintegral(page,size) {
  return request({
      url: '/api/v1/useraccount/sltconversionintegral/' + page + '/' + size,
      method: 'get',
  });
}
//SLT兑换SLC
export function getSltConversionslc(page,size) {
  return request({
      url: '/api/v1/useraccount/sltConversionslc/' + page + '/' + size,
      method: 'get',
  });
}
//bp提示
export function getBPhint() {
  return request({
      url: '/api/v1/user/getBPhint',
      method: 'get',
  });
}
//验证是否开通兑换slt
export function checkConversion() {
  return request({
      url: '/api/v1/slttoslc/checkconversion',
      method: 'get',
  });
}
//查询积分兑换SLT
export function getIntegralconversion() {
  return request({
      url: '/api/v1/integralconversion/proportion',
      method: 'get',
  });
}
//slt兑换slt
export function sltIntegralconversion(data) {
  return request({
      url: '/api/v1/integralconversion/toform',
      method: 'post',
      data
  });
}