<template>
  <div class="home">
    <!-- <Login /> -->
    <header-menu v-if="headerState" />
    <router-view />
    <foot-menu v-if="footerState" />
  </div>
</template>
<script>
// import Login from "@/components/pc/login.vue";
import headerMenu from "@/components/pc/header.vue";
import footMenu from "@/components/pc/footer.vue";

export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu
    // Login
  },
  data() {
    return {
      headerState: true,
      footerState: true,
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
html{
  font-size: 14px;
}
div{
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
</style>
