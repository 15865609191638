<template>
  <div>
    <div class="header_m">
      <div class="deader-top">
        <a href="https://www.soulland.xyz/">
          <img class="logo" src="@/assets/m/header/logo-icon1.png" alt="">
        </a>
        <div style="flex: 1;"></div>
        <!-- <img src="@/assets/m/header/search-icon.png" class="search" alt=""> -->
        <!-- <img v-if="!userInfo" class="walle-icon" @click="login()" src="@/assets/pc/common/wallet_icon.png" alt=""> -->
        <img v-if="userInfo" class="walle-icon"
          @click="isExistaddress(), getuser(), walletShow = true, updateWallet(true)"
          src="@/assets/pc/common/wallet_icon.png" alt="">
        <img class="language" @click="languageShow = true" src="@/assets/m/header/language.png" alt="">
        <!-- <img class="walle-icon" src="@/assets/pc/common/broken.png" alt=""> -->
        <img v-if="!userInfo" class="login" @click="login()" src="@/assets/m/header/login-icon2.png" alt="">
        <div v-if="userInfo" class="userInfo flex" @mouseenter="userState = true" @mouseleave="userState = false">
          <img class="userInfo-phone" :src="userInfo.userpic" alt="">
          <p class="userInfo-username">{{ userInfo.username }}</p>
          <transition name="el-zoom-in-top">
            <div v-show="userState" class="log-out" @click="logOut()">
              <img class="log-out-icon" src="@/assets/pc/active/logOut.png" alt="">
              <p class="log-out-name">{{ $t('login.logOut') }}</p>
            </div>
          </transition>
        </div>
      </div>
      <div class="nav">
        <div class="nav-detail" v-for="(item, index) in $t('header.navM')" :key="index" @click="navChange(item.path)">
          <p class="nav-title" :class="{ 'nav-select': item.path == navIndex }">{{ item.name }}</p>
          <p class="nav-line" v-if="item.path == navIndex"></p>
        </div>
        <div style="flex: 1;"></div>
        <img v-if="$t('language.change') == 'zh'" @click="goGame" class="nav-game" src="@/assets/m/common/enter_game_en.png" alt="">
        <img v-else @click="goGame" class="nav-game" src="@/assets/m/common/enter_game_cn.png" alt="">
      </div>
      <div class="search-input">
        <img class="search-input-icon" src="@/assets/pc/header/search-icon2.png" alt="">
        <el-input class="search-input-input" v-model.trim="search" :placeholder="$t('common.search')"
          @change="inputChange" enterkeyhint="search" ref="rearch"></el-input>
      </div>
    </div>
    <div class="header-zw"></div>
    <Login v-if="loginModal.show"></Login>
    <!-- 切换语言 -->
    <!-- <div v-if="languageShow">
      <div class="wallet-layer" @click="languageShow = false"></div>
      <div class="language-m">
        <div @click="setLanguage($t('zh')), languageShow = false" class="language-m-list"
          :class="{ 'language-active': $t('language.change') == 'en' }">
          <img class="language-m-icon" v-if="$t('language.change') == 'en'" src="@/assets/pc/common/cn_icon3.png"
            alt="">
          <img class="language-m-icon" v-if="$t('language.change') == 'zh'" src="@/assets/pc/common/cn_icon.png" alt="">
          <p class="language-m-name">中文</p>
        </div>
        <div @click="setLanguage($t('en')), languageShow = false" class="language-m-list"
          :class="{ 'language-active': $t('language.change') == 'zh' }">
          <img class="language-m-icon" v-if="$t('language.change') == 'zh'" src="@/assets/pc/common/en_icon3.png"
            alt="">
          <img class="language-m-icon" v-if="$t('language.change') == 'en'" src="@/assets/pc/common/en_icon.png" alt="">
          <p class="language-m-name">English</p>
        </div>
      </div>
    </div> -->
    <language :state="languageShow" @close="languageShow = false"></language>
    <!-- 钱包 -->
    <div v-if="walletShow">
      <div v-if="walletShow" class="wallet-layer" @click="walletShow = !walletShow, updateWallet(false)"></div>
      <transition name="el-zoom-in-bottom">
        <div v-show="walletShow" class="wallet-box">
          <p class="wallet-btn" @click="walletClick">{{ $t('wallet.msg20') }}</p>
          <div @click="bpState = true, walletShow = false" class="wallet-box-list flex">
            <p class="wallet-box-txt">
              BP：{{ userBalance ? userBalance.withdrawBP : 0 }}
            </p>
          </div>
          <div class="wallet-box-list flex" @click="exchangeClick(1)">
            <img class="wallet-box-icon" src="@/assets/m/wallet/wallet_icon1.png" alt="">
            <p class="wallet-box-name">{{ $t('wallet.Swap') }}</p>
            <p class="wallet-box-txt">{{ $t('wallet.Balance') }}：{{Math.floor(userInfo.diamond_num * 100) / 100 }}</p>
          </div>
          <div class="wallet-box-list flex" @click="exchangeClick(2)">
            <img class="wallet-box-icon" src="@/assets/m/wallet/wallet_icon2.png" alt="">
            <p class="wallet-box-name">{{ $t('wallet.Swap') }}</p>
            <p class="wallet-box-txt">{{ $t('wallet.Balance') }}：{{Math.floor(userInfo.integral * 100) / 100 }}</p>
          </div>
        </div>
      </transition>
    </div>
    <exchange :exchangeState="exchangeState2" :userBalanceDate="userBalance" :exchangeType="exchangeType"
      @close="exchangeState2 = false">
    </exchange>
    <div>
      <div v-show="bpState" @click="bpState = false" class="wallet-rule-layer" style="z-index: 90;"></div>
      <transition name="el-zoom-in-top">
        <div v-show="bpState" class="wallet-rule">
          <p class="wallet-rule-title">{{ $t('wallet.Attention') }}
            <img @click="bpState = false" class="wallet-rule-close" src="@/assets/pc/common/common_close_02.png" alt="">
          </p>
          <div class="wallet-rule-detail">
            <p class="wallet-rule-content">BP:{{ bpHint ? bpHint.max_user_slt.withdraw_bp : 0 }}</p>
            <p class="wallet-rule-content">
              ({{ $t('BP.txt1') }}{{ bpHint.burn_slt }}{{ $t('BP.txt4') }}{{ bpHint.proportion }}
              {{ $t('BP.txt5') }}{{ bpHint.minimux_bp }}{{ $t('BP.txt6') }})
            </p>
            <p class="wallet-rule-content">{{ $t('BP.txt2') }}:{{ bpHint ? (bpHint.max_user_slt.withdraw_bp *
          bpHint.proportion) : 0 }}</p>
            <p class="wallet-rule-content">{{ $t('BP.txt3') }}:{{ bpHint ? bpHint.max_user_slt.max_user_slt :
          0 }}</p>
          </div>
          <p @click="bpState = false" class="wallet-rule-btn">{{ $t('common.Confirm') }}</p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Login from "@/components/pc/login.vue";
import exchange from "@/views/m/wallet/UserExchange.vue";
import language from "@/views/m/common/languageChange.vue";
import {
  isExistaddress, setAdress, getUserbalance, getBPhint
} from '@/api/wallet';
import {
  getuser
} from '@/api/user';
export default {
  name: "pc-header",
  components: {
    Login, exchange, language
  },
  data() {
    return {
      language: '',
      navIndex: 0,
      walletShow: false, //钱包弹框
      languageShow: false,//切换语言
      search: '',
      userState: false,
      addressState: 0,
      exchangeState2: false, //兑换
      exchangeType: 1,//1-DMD 2-SLT
      userInfo: '',
      userBalance: '',
      bpState: false,
      bpHint: '',//bp提示
    };
  },
  watch: {
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
        this.getUserbalance()
      }
    },
    getWalletDate: {
      handler(nval) {
        this.walletShow = nval
      }
    }
  },
  computed: {
    ...mapState(["tipModal", "loginModal"]),
    isUserInfo() {
      this.userInfo = this.$store.state.userInfo
      return this.$store.state.userInfo
    },
    getWalletDate() {
      this.walletShow = this.$store.state.walletDate
      return this.$store.state.walletDate
    },
  },
  mounted() {
    // console.log('暂停')
    // this.$refs.audio.pause();
    this.navIndex = this.$route.path
    this.GetUserInfo()
    this.isExistaddress()
    this.getUserbalance()
    this.getBPhint()
    // console.log(localStorage.getItem('languageStorage'))
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "GetUserInfo", "ClearUserInfo"]), //登录弹窗
    login() {
      this.ShowLoginModal()
    },
    //判断钱包地址是否存在
    isExistaddress() {
      isExistaddress().then(res => {
        if (res.errorCode) {
          return
        }
        this.addressState = res.data
      }).catch((err) => {
      });
    },

    //生成钱包地址
    setAdress() {
      this.$confirm(this.$t('wallet.msg19'), this.$t('wallet.tips'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel'),
        customClass: 'msgbox'
      })
        .then(_ => {
          setAdress().then(res => {
            if (res.errorCode) {
              this.$message.error(res.msg);
              return
            }
            this.isExistaddress()
          }).catch((err) => {
          });
        })
        .catch(_ => { });
    },
    setLanguage(val) {
      localStorage.setItem('languageStorage', val);
      this.language = val;
      this.$i18n.locale = val;
    },
    homeGo() {
      this.$router.push('/')
      // selected
    },
    //切换导航
    navChange(url) {
      this.navIndex = url
      this.$router.push(url)
      this.search = null
      this.$store.commit('updateSearch', this.search)
    },
    //触发搜索
    inputChange() {
      this.$store.commit('updateSearch', this.search)
      this.$nextTick(() => this.$refs.rearch.blur());
    },
    //退出登录
    logOut() {
      this.$confirm(this.$t('common.logoutMsg'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel'),
        customClass: 'msgbox'
      })
        .then(_ => {
          localStorage.clear();
          this.ClearUserInfo()
        })
        .catch(_ => { });
    },
    //我的钱包
    walletClick() {
      if (this.addressState == 1) {
        this.$router.push('/walletIndex')
      } else {
        this.setAdress()
      }
    },
    exchangeClick(type) {
      if (this.addressState == 1) {
        this.exchangeState2 = true
        this.exchangeType = type
      } else {
        this.setAdress()
      }
    },

    getuser() {
      getuser().then(res => {
        if (res.errorCode) {
          return
        }
        this.userInfo = res.data
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.GetUserInfo()
      }).catch(() => {
        // this.$message.error('发送失败');
      });
    },
    // 播放背景音乐
    goGame() {
      this.$router.push('/galaxyarena')
    },
    //查询用户钱包信息
    getUserbalance() {
      getUserbalance().then(res => {
        if (res.errorCode) {
          return
        }
        this.userBalance = res.data
      }).catch((err) => {
      });
    },
    updateWallet(state) {
      this.$store.commit('updateWallet', state)
    },
    //查询BP提示
    getBPhint() {
      getBPhint().then(res => {
        if (res.errorCode) {
          return
        }
        this.bpHint = res.data
      }).catch(() => {
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-input__inner {
  background: #F7F7F8;
  font-size: 26px;
  color: #222222;
  border: none;
  padding: 0;
  font-family: 'Normal' !important;
  width: 80%;

  &::placeholder {
    font-size: 26px;
  }
}

.header_m {
  width: 100%;
  height: 250px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 89;

  .deader-top {
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;

    .logo {
      width: 320px;
      height: auto;
      margin-left: 30px;
    }

    .search {
      width: 30px;
      height: 30px;
    }

    .language {
      width: 40px;
      height: 40px;
      margin: 4px 32px 0 32px;
    }

    .walle-icon {
      width: 42px;
      height: 42px;
    }

    .login {
      width: 42px;
      height: 42px;
      margin-right: 30px;
      margin-left: 30px;
    }

    .userInfo {
      position: relative;

      .userInfo-phone {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .userInfo-username {
        font-size: 30px;
        margin: 0 20px;
        max-width: 120px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 1; //行数
        line-clamp: 1;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }

      .log-out {
        width: 250px;
        height: 70px;
        background: #16151B;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        bottom: -76px;
        right: 10px;

        .log-out-icon {
          width: 28px;
          height: 28px;
          margin-right: 16px;
        }

        .log-out-name {
          font-size: 26px;
          color: #fff;
          line-height: 30px;
        }
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding-top: 10px;

    .nav-detail {
      position: relative;
      margin-right: 50px;

      .nav-title {
        font-size: 30px;
        color: #444444;
        padding-bottom: 10px;
        cursor: pointer;
        font-family: 'Regular';
        border-bottom: 1px solid #fff;
      }

      .nav-select {
        // border-bottom: 1px solid #222222;
        font-family: 'Medium';
        color: #222222;
      }

      .nav-line {
        width: 30px;
        height: 1px;
        background: #6930FF;
        border: 2px solid #6930FF;
        border-radius: 20px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -15px;
      }
    }
    .nav-game{
      width: auto;
      height: 56px;
      margin-right: 26px;
    }
  }

  .search-input {
    width: 690px;
    height: 64px;
    background: #F7F7F8;
    border-radius: 32px;
    display: flex;
    align-items: center;
    margin: 16px auto 0 auto;

    .search-input-icon {
      width: 24px;
      height: auto;
      margin: 0 10px 0 24px;
    }

    .search-input-input {
      flex: 1;
    }
  }
}

.header-zw {
  width: 100%;
  height: 250px;
}

.wallet-layer {
  width: 100%;
  height: 100%;
  background: #000000;
  box-shadow: 0px 0px 60px 0px rgba(18, 13, 37, 0.2);
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}

.wallet-m {
  width: 750px;
  height: 898px;
  background-image: url('@/assets/pc/common/wallet_bg3.png');
  background-size: 100% auto;
  position: fixed;
  top: 20%;
  z-index: 91;

  .wallet-tips {
    font-size: 30px;
    color: #222222;
    padding-top: 380px;
    text-align: center;
    font-family: 'Bold';
  }

  .wallet-msg {
    width: 320px;
    font-size: 28px;
    color: #676A79;
    line-height: 48px;
    text-align: center;
    margin: 10px auto 50px auto;
  }

  .wallet-btn {
    width: 336px;
    height: 118px;
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 80px;
    cursor: pointer;
    margin: 0 auto;
    background-image: url('@/assets/pc/common/wallet_bg2.png');
    background-size: 100% auto;
  }
}

.language-m {
  width: 690px;
  height: 292px;
  position: fixed;
  background: #fff;
  border-radius: 50px;
  bottom: 30px;
  left: 30px;
  z-index: 91;
  padding-top: 50px;

  .language-m-list {
    width: 590px;
    height: 86px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D4D4D4;
    margin: 0 auto 20px auto;

    .language-m-icon {
      width: 28px;
      height: auto;
    }

    .language-m-name {
      font-size: 28px;
      color: #222222;
      margin-left: 10px;
    }
  }

  .language-active {
    background: #6F38FF;

    .language-m-name {
      color: #fff;
    }
  }
}

//钱包
.wallet-box {
  width: 690px;
  height: 500px;
  background: #E9E8ED;
  // backdrop-filter: blur(2px);
  position: fixed;
  z-index: 901;
  left: 30px;
  bottom: 30px;
  border-radius: 50px;

  .wallet-btn {
    width: 590px;
    height: 86px;
    background: #D4D4D4;
    border-radius: 43px;
    margin: 50px auto 0 auto;
    text-align: center;
    line-height: 86px;
    color: #222222;
    font-size: 28px;
  }

  .wallet-box-list {
    width: 590px;
    height: 86px;
    justify-content: center;
    background-image: url('@/assets/m/wallet/wallet_bg1.png');
    background-size: 100% auto;
    margin: 20px auto 0 auto;

    .wallet-box-icon {
      width: 32px;
      height: auto;
    }

    .wallet-box-name {
      font-size: 28px;
      color: #FFE8B7;
      margin: 0 20px 0 10px;
    }

    .wallet-box-txt {
      font-size: 22px;
      color: #fff;
    }
  }
}

//付费规则
.wallet-rule-layer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}

.wallet-rule {
  width: 670px;
  height: 800px;
  background: #fff;
  border-radius: 30px;
  position: fixed;
  top: 300px;
  left: 40px;
  z-index: 91;
  padding-top: 32px;

  .wallet-rule-title {
    text-align: center;
    font-size: 32px;
    color: #161B2C;
    font-family: 'Bold';
    position: relative;

    .wallet-rule-close {
      width: 32px;
      height: auto;
      position: absolute;
      right: 32px;
      top: 0;
      cursor: pointer;
    }
  }

  .wallet-rule-detail {
    width: 606px;
    height: 500px;
    background: #F6F6FD;
    border-radius: 30px;
    margin: 24px auto 40px auto;
    padding: 32px 0;
    overflow-y: auto;

    .wallet-rule-content {
      width: 542px;
      font-size: 28px;
      color: #161B2C;
      line-height: 48px;
      margin: 0 auto;
    }
  }

  .wallet-rule-detail::-webkit-scrollbar {
    display: none;
  }

  .wallet-rule-btn {
    width: 550px;
    height: 80px;
    background: #6930FF;
    border-radius: 20px;
    margin: 0 auto;
    text-align: center;
    line-height: 80px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }
}
</style>