<template>
  <div class="order-pc">
    <div class="order">
      <div class="order-header flex">
        <img @click="$emit('close')" class="order-header-return" src="@/assets/pc/wallet/order_return.png" alt="">
        <p class="order-header-title">{{ $t('wallet.History') }}</p>
      </div>
      <div class="order-nav flex">
        <div v-for="(item, index) in $t('wallet.navList')" :key="index" class="order-nav-list" @click="navChange(index)">
          <p class="order-nav-name" :class="{ 'order-nav-active': navIndex == index }">{{ item.name }}</p>
          <p v-if="navIndex == index" class="order-nav-list-line"></p>
        </div>
        <p class="order-nav-line"></p>
      </div>
      <div class="order-detail">
        <!-- 充值订单 -->
        <div v-if="navIndex == 0" class="order-recharge">
          <div class="exchange-nav flex">
            <p v-for="(item, index) in rechargeList" :key="index" class="exchange-nav-list"
              :class="{ 'exchange-nav-active': rechargeIndex == index }" @click="rechargeChange(index)">{{ item }}</p>
          </div>
          <div v-if="rechargeIndex == 0">
            <div class="order-list" v-for="(item, index) in depositlist" :key="index" @click="showDetail(1, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">USDT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;">
                </p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed1') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed1') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting1') }}
                </p>
              </div>
            </div>
            <div v-if="depositlist.length > 0">
              <p v-if="listQuery.scroll" @click="getDepositlist" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="depositlist.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="rechargeIndex == 1">
            <div class="order-list" v-for="(item, index) in depositlistDmd" :key="index" @click="showDetail(6, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">BNB</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;">
                </p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed1') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed1') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting1') }}
                </p>
              </div>
            </div>
            <div v-if="depositlistDmd.length > 0">
              <p v-if="listQuery6.scroll" @click="getDepositlist2" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="depositlistDmd.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="rechargeIndex == 2">
            <div class="order-list" v-for="(item, index) in depositSltList" :key="index" @click="showDetail(10, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
                <p class="order-list-name">SLT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;">
                </p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed1') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed1') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting1') }}
                </p>
              </div>
            </div>
            <div v-if="depositSltList.length > 0">
              <p v-if="listQuery10.scroll" @click="getDepositlist3" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="depositSltList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="rechargeIndex == 3">
            <div class="order-list" v-for="(item, index) in depositSlcList" :key="index" @click="showDetail(11, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
                <p class="order-list-name">SLC</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;">
                </p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed1') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed1') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting1') }}
                </p>
              </div>
            </div>
            <div v-if="depositSlcList.length > 0">
              <p v-if="listQuery11.scroll" @click="getDepositlist4" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="depositSlcList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
        </div>
        <!-- 提现订单 -->
        <div v-if="navIndex == 1">
          <div class="exchange-nav flex">
            <p v-for="(item, index) in withdrawalList" :key="index" class="exchange-nav-list"
              :class="{ 'exchange-nav-active': withdrawalIndex == index }" @click="withdrawalChange(index)">{{ item }}</p>
          </div>
          <div v-if="withdrawalIndex == 0">
            <div class="order-list" v-for="(item, index) in transfertList" :key="index" @click="showDetail(5, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">USDT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed2') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed2') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting2') }}
                </p>
              </div>
            </div>
            <div v-if="transfertList.length > 0">
              <p v-if="listQuery5.scroll" @click="getTransfertList" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="transfertList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="withdrawalIndex == 1">
            <div class="order-list" v-for="(item, index) in transfertListDmd" :key="index" @click="showDetail(7, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">USDT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed2') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed2') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting2') }}
                </p>
              </div>
            </div>
            <div v-if="transfertListDmd.length > 0">
              <p v-if="listQuery7.scroll" @click="getTransfertList2" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="transfertListDmd.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="withdrawalIndex == 2">
            <div class="order-list" v-for="(item, index) in transfertSltList" :key="index" @click="showDetail(12, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">SLT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed2') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed2') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting2') }}
                </p>
              </div>
            </div>
            <div v-if="transfertSltList.length > 0">
              <p v-if="listQuery12.scroll" @click="getTransfertList3" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="transfertSltList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="withdrawalIndex == 3">
            <div class="order-list" v-for="(item, index) in transfertSlcList" :key="index" @click="showDetail(13, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
                <p class="order-list-name">SLC</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed2') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed2') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting2') }}
                </p>
              </div>
            </div>
            <div v-if="transfertSlcList.length > 0">
              <p v-if="listQuery13.scroll" @click="getTransfertList4" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="transfertSlcList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
        </div>
        <!-- 兑换 -->
        <div v-if="navIndex == 2">
          <div class="exchange-nav flex">
            <p v-for="(item, index) in exchangeList" :key="index" class="exchange-nav-list exchange-nav-list2"
              :class="{ 'exchange-nav-active': exchangeIndex == index }" @click="exchangeChange(index)">{{ item }}</p>
          </div>
          <!-- DMD -->
          <div v-if="exchangeIndex == 0">
            <div class="order-list" v-for="(item, index) in diamondList" :key="index" @click="showDetail(2, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">USDT</p>
                <img class="order-list-exchang" src="@/assets/pc/wallet/exchange_icon2.png" alt="">
                <img class="order-list-icon" src="@/assets/pc/wallet/DMD_icon.png" alt="">
                <p class="order-list-name">DMD</p>
                <p class="order-list-num">{{ item.diamond_amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed3') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed3') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting3') }}
                </p>
              </div>
            </div>
            <div v-if="diamondList.length > 0">
              <p v-if="listQuery2.scroll" @click="getUsdconversiondiamond" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="diamondList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <!-- USDT -->
          <div v-if="exchangeIndex == 1">
            <div class="order-list" v-for="(item, index) in usdtList" :key="index" @click="showDetail(4, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/DMD_icon.png" alt="">
                <p class="order-list-name">DMD</p>
                <img class="order-list-exchang" src="@/assets/pc/wallet/exchange_icon2.png" alt="">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">USDT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed3') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed3') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting3') }}
                </p>
              </div>
            </div>
            <div v-if="usdtList.length > 0">
              <p v-if="listQuery4.scroll" @click="getDiamondconversionUsd" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="usdtList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <!-- SLT -->
          <div v-if="exchangeIndex == 2">
            <div class="order-list" v-for="(item, index) in sltList" :key="index" @click="showDetail(14, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
                <p class="order-list-name">SLT</p>
                <img class="order-list-exchang" src="@/assets/pc/wallet/exchange_icon2.png" alt="">
                <img class="order-list-icon" src="@/assets/pc/header/SLT_icon.png" alt="">
                <p class="order-list-name">SLT</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed3') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed3') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting3') }}
                </p>
              </div>
            </div>
            <div v-if="sltList.length > 0">
              <p v-if="listQuery14.scroll" @click="getSltconversionintegral" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="sltList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <div v-if="exchangeIndex == 3">
            <div class="order-list" v-for="(item, index) in slcList" :key="index" @click="showDetail(15, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/header/SLT_icon.png" alt="">
                <p class="order-list-name">SLT</p>
                <img class="order-list-exchang" src="@/assets/pc/wallet/exchange_icon2.png" alt="">
                <img class="order-list-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
                <p class="order-list-name">SLC</p>
                <p class="order-list-num">{{ item.amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed3') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed3') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting3') }}
                </p>
              </div>
            </div>
            <div v-if="slcList.length > 0">
              <p v-if="listQuery15.scroll" @click="getSltConversionslc" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="slcList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
          <!-- SLT -->
          <div v-if="exchangeIndex == 4">
            <div class="order-list" v-for="(item, index) in integralList" :key="index" @click="showDetail(3, item)">
              <div class="order-list-top flex">
                <img class="order-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="order-list-name">USDT</p>
                <img class="order-list-exchang" src="@/assets/pc/wallet/exchange_icon2.png" alt="">
                <img class="order-list-icon" src="@/assets/pc/header/SLT_icon.png" alt="">
                <p class="order-list-name">SLT</p>
                <p class="order-list-num">{{ item.integral_amount }}</p>
              </div>
              <div class="order-list-bottom flex">
                <p class="order-list-time">{{ item.create_time }}</p>
                <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
                </p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                  style="background: #FFBF23;"></p>
                <p v-if="item.confirm == 900" class="order-list-state">{{ $t('wallet.completed3') }}</p>
                <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-state">{{ $t('wallet.Failed3') }}</p>
                <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-state">{{
                  $t('wallet.Waiting3') }}
                </p>
              </div>
            </div>
            <div v-if="integralList.length > 0">
              <p v-if="listQuery3.scroll" @click="getUsdconversionIntegral" class="order-more">{{ $t('home.lodingMsg') }}
              </p>
              <p v-else class="order-more">{{ $t('common.noMore') }}</p>
            </div>
            <div v-if="integralList.length == 0">
              <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
              <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
            </div>
          </div>
        </div>
        <!-- 收入 -->
        <div v-if="navIndex == 3">
          <div class="order-list" v-for="(item, index) in incomeLIstanbul" :key="index">
            <div class="order-list-top flex">
              <img class="order-list-icon" src="@/assets/pc/wallet/DMD_icon.png" alt="">
              <p class="order-list-name">DMD</p>
              <p class="order-list-num">+ {{ item.intnum }}</p>
            </div>
            <div class="order-list-bottom flex">
              <p class="order-list-time">{{ item.create_time }}</p>
              <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
              <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
              </p>
              <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                style="background: #FFBF23;"></p>
              <p v-if="item.type == 'topic'" class="order-list-state">{{ $t('wallet.Guild') }}</p>
              <p v-if="item.type == 'goods_order'" class="order-list-state">{{ $t('wallet.Mall') }}</p>

            </div>
          </div>
          <div v-if="incomeLIstanbul.length > 0">
            <p v-if="listQuery8.scroll" @click="getIncome" class="order-more">{{ $t('home.lodingMsg') }}
            </p>
            <p v-else class="order-more">{{ $t('common.noMore') }}</p>
          </div>
          <div v-if="incomeLIstanbul.length == 0">
            <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
            <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
          </div>
        </div>
        <!-- 支出 -->
        <div v-if="navIndex == 4">
          <div class="order-list" v-for="(item, index) in expenditureList" :key="index">
            <div class="order-list-top flex">
              <img class="order-list-icon" src="@/assets/pc/wallet/DMD_icon.png" alt="">
              <p class="order-list-name">DMD</p>
              <p class="order-list-num">- {{ item.intnum }}</p>
            </div>
            <div class="order-list-bottom flex">
              <p class="order-list-time">{{ item.create_time }}</p>
              <p v-if="item.confirm == 900" class="order-list-point" style="background: #25C289;"></p>
              <p v-if="item.confirm == 901 || item.confirm == 0" class="order-list-point" style="background: #F92676;">
              </p>
              <p v-if="item.confirm != 900 && item.confirm != 901 && item.confirm != 0" class="order-list-point"
                style="background: #FFBF23;"></p>
              <p v-if="item.type == 'topic'" class="order-list-state">{{ $t('wallet.Guild') }}</p>
              <p v-if="item.type == 'goods_order'" class="order-list-state">{{ $t('wallet.Mall') }}</p>

            </div>
          </div>
          <div v-if="expenditureList.length > 0">
            <p v-if="listQuery9.scroll" @click="getIncome" class="order-more">{{ $t('home.lodingMsg') }}
            </p>
            <p v-else class="order-more">{{ $t('common.noMore') }}</p>
          </div>
          <div v-if="expenditureList.length == 0">
            <img class="empty_img" src="@/assets/pc/wallet/empty_img.png" alt="">
            <p class="empty_msg">{{ $t('wallet.msg11') }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单详情 -->
    <div>
      <div v-if="orderDetailState" @click="closeDetail" class="order-detail-layer"></div>
      <transition name="el-zoom-in-bottom">
        <div v-show="orderDetailState" class="detail-box">
          <div class="detail-box-header flex">
            <p class="detail-box-header-title" v-if="detailState == 1 || detailState == 6 || detailState == 10 || detailState == 11">{{ $t('wallet.Details1') }}</p>
            <p class="detail-box-header-title" v-if="detailState == 2 || detailState == 3 || detailState == 4 || detailState == 14 || detailState == 15">{{
              $t('wallet.Details2') }}</p>
            <p class="detail-box-header-title" v-if="detailState == 5 || detailState == 7 || detailState == 12 || detailState == 13">{{ $t('wallet.Details3') }}</p>
            <img @click="closeDetail" class="detail-box-header-close" src="@/assets/pc/wallet/arrow_icon.png" alt="">
          </div>
          <div class="detail-box-content">
            <p class="detail-box-content-title">{{ $t('wallet.Quantity') }}</p>
            <div class="flex" style="margin-top: 10px;align-items: end;">
              <p class="detail-box-num" v-if="detailState == 2">{{ detail.diamond_amount }}</p>
              <p class="detail-box-unit" v-if="detailState == 2">DMD</p>
              <p class="detail-box-num" v-if="detailState == 3">{{ detail.integral_amount }}</p>
              <p class="detail-box-num" v-if="detailState == 4 || detailState == 5 || detailState == 1 || detailState == 10 || detailState == 11 || detailState == 12 || detailState == 13">{{ detail.amount
              }}</p>
              <p class="detail-box-unit" v-if="detailState == 4 || detailState == 5 || detailState == 1">USDT</p>
              <p class="detail-box-num" v-if="detailState == 6 || detailState == 7|| detailState == 14|| detailState == 15">{{ detail.amount }}</p>
              <p class="detail-box-unit" v-if="detailState == 6 || detailState == 7">BNB</p>
              <p class="detail-box-unit" v-if="detailState == 3 || detailState == 10 || detailState == 12|| detailState == 14">SLT</p>
              <p class="detail-box-unit" v-if="detailState == 11 || detailState == 13|| detailState == 15">SLC</p>
              <img v-if="detail.confirm == 900" class="detail-box-state-icon" src="@/assets/pc/wallet/state_icon.png"
                alt="">
              <img v-if="detail.confirm == 901 || detail.confirm == 0" class="detail-box-state-icon"
                src="@/assets/pc/wallet/tips_icon.png" alt="">
              <img v-if="detail.confirm != 900 && detail.confirm != 901 && detail.confirm != 0"
                class="detail-box-state-icon" src="@/assets/pc/wallet/state_icon2.png" alt="">
              <p v-if="detail.confirm == 900" class="detail-box-state">{{ $t('wallet.Completed') }}</p>
              <p v-if="detail.confirm == 901 || detail.confirm == 0" class="detail-box-state">{{ $t('wallet.Failed4') }}
              </p>
              <p v-if="detail.confirm != 900 && detail.confirm != 901 && detail.confirm != 0" class="detail-box-state">{{
                $t('wallet.Waiting4')
              }}</p>
            </div>
            <p class="detail-box-msg" v-if="detailState == 2 && detail.confirm == 900">DMD{{ $t('wallet.msg12') }}</p>
            <p class="detail-box-msg" v-if="detailState == 14 && detail.confirm == 900">SLT{{ $t('wallet.msg12') }}</p>
            <p class="detail-box-msg" v-if="detailState == 15 && detail.confirm == 900">SLC{{ $t('wallet.msg12') }}</p>
            <p class="detail-box-msg" v-if="detailState == 3 && detail.confirm == 900">SLT{{ $t('wallet.msg12') }}</p>
            <p class="detail-box-msg" v-if="detailState == 4 && detail.confirm == 900">USDT{{ $t('wallet.msg12') }}</p>
            <p class="detail-box-msg" v-if="detailState == 5 && detail.confirm == 900">USDT{{ $t('wallet.msg13') }}</p>
            <p class="detail-box-msg" v-if="detailState == 12 && detail.confirm == 900">SLT{{ $t('wallet.msg13') }}</p>
            <p class="detail-box-msg" v-if="detailState == 13 && detail.confirm == 900">SLC{{ $t('wallet.msg13') }}</p>
            <p class="detail-box-msg" v-if="detailState == 1 && detail.confirm == 900">USDT{{ $t('wallet.msg2') }}</p>
            <p class="detail-box-msg" v-if="detailState == 10 && detail.confirm == 900">SLT{{ $t('wallet.msg2') }}</p>
            <p class="detail-box-msg" v-if="detailState == 11 && detail.confirm == 900">SLC{{ $t('wallet.msg2') }}</p>
            <p class="detail-box-msg" v-if="detailState == 6 && detail.confirm == 900">BNB{{ $t('wallet.msg2') }}</p>
            <p class="detail-box-msg" v-if="detailState == 7 && detail.confirm == 900">BNB{{ $t('wallet.msg13') }}</p>
            <p class="detail-box-msg" v-if="detailState == 2 || detailState == 3 || detailState == 4"
              style="font-size: 14px;color: #8F93A6;">{{ $t('wallet.Consumption') }}</p>
            <p class="detail-box-consume" v-if="detailState == 2 || detailState == 3">{{ detail.amount }} USDT</p>
            <p class="detail-box-consume" v-if="detailState == 4">{{ detail.diamond_amount }} DMD</p>
          </div>
          <div class="detail-box-list flex">
            <p class="detail-box-list-name">{{ $t('common.Confirm') }}</p>
            <p class="detail-box-list-content">{{ detail.confirmed_number }} / {{ detail.confirming_threshold }}</p>
          </div>
          <div class="detail-box-list flex">
            <p class="detail-box-list-name">{{ $t('wallet.Mainnet') }}</p>
            <p class="detail-box-list-content">BNB Smart Chain</p>
          </div>
          <div class="detail-box-list flex">
            <p class="detail-box-list-name">{{ $t('wallet.Address2') }}</p>
            <p class="detail-box-list-content">{{ detail.to_address }}</p>
            <img @click="copyFun(detail.to_address)" class="detail-box-copy" src="@/assets/pc/wallet/copy_icon.png"
              alt="">
          </div>
          <div class="detail-box-list flex">
            <p class="detail-box-list-name">{{ $t('wallet.Hash') }}</p>
            <p class="detail-box-list-content">{{ detail.tx_hash }}</p>
            <img @click="copyFun(detail.tx_hash)" class="detail-box-copy" src="@/assets/pc/wallet/copy_icon.png" alt="">
          </div>
          <div class="detail-box-list flex">
            <p class="detail-box-list-name">{{ $t('wallet.Date') }}</p>
            <p class="detail-box-list-content">{{ detail.create_time }}</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import {
  getDepositlist, getUsdconversiondiamond, getUsdconversionIntegral,
  getDiamondconversionUsd, getTransfertList, getIncome, getSltconversionintegral,
  getSltConversionslc
} from '@/api/wallet';

export default {
  name: "pc-order",
  components: {
  },
  data() {
    return {
      navList: ["充值", "提现", "兑换", "收入", "支出"],
      exchangeList: ["DMD", "USDT", "SLT","SLC", "USDT→SLT"],
      withdrawalList: ["USDT", "BNB","SLT","SLC"],
      rechargeList: ["USDT", "BNB","SLT","SLC"],
      navIndex: 0,
      exchangeIndex: 0,
      withdrawalIndex: 0,
      rechargeIndex: 0,
      orderDetailState: false,
      //充值记录
      listQuery: {
        page: 1,
        size: 10,
        scroll: true
      },
      depositlist: [],
      listQuery6: {
        page: 1,
        size: 10,
        scroll: true
      },
      depositlistDmd: [],
      //U兑换DMD记录
      listQuery2: {
        page: 1,
        size: 10,
        scroll: true
      },
      diamondList: [],
      //积分兑换记录
      listQuery3: {
        page: 1,
        size: 10,
        scroll: true
      },
      integralList: [],
      //USDT兑换记录
      listQuery4: {
        page: 1,
        size: 10,
        scroll: true
      },
      usdtList: [],
      //提现记录
      listQuery5: {
        page: 1,
        size: 10,
        scroll: true
      },
      transfertList: [],
      listQuery7: {
        page: 1,
        size: 10,
        scroll: true
      },
      transfertListDmd: [],
      // 收入支出
      listQuery8: {
        page: 1,
        size: 10,
        scroll: true
      },
      incomeLIstanbul: [],
      listQuery9: {
        page: 1,
        size: 10,
        scroll: true
      },
      expenditureList: [],
      //详情
      detailState: -1,
      detail: {},
      //SLT SLC
      depositSltList:[],
      listQuery10: {
        page: 1,
        size: 10,
        scroll: true
      },
      depositSlcList:[],
      listQuery11: {
        page: 1,
        size: 10,
        scroll: true
      },
      transfertSltList:[],
      listQuery12: {
        page: 1,
        size: 10,
        scroll: true
      },
      transfertSlcList:[],
      listQuery13: {
        page: 1,
        size: 10,
        scroll: true
      },
      sltList:[],
      listQuery14: {
        page: 1,
        size: 10,
        scroll: true
      },
      slcList:[],
      listQuery15: {
        page: 1,
        size: 10,
        scroll: true
      },
    };
  },
  watch: {

  },
  mounted() {
    this.getDepositlist()
    this.getDepositlist2()
    this.getDepositlist3()
    this.getDepositlist4()
    this.getUsdconversiondiamond()
    this.getUsdconversionIntegral()
    this.getDiamondconversionUsd()
    this.getTransfertList()
    this.getTransfertList2()
    this.getTransfertList3()
    this.getTransfertList4()
    this.getIncome()
    this.getIncome2()
    this.getSltconversionintegral()
    this.getSltConversionslc()
  },
  methods: {
    navChange(val) {
      this.navIndex = val
    },
    exchangeChange(val) {
      this.exchangeIndex = val
    },
    withdrawalChange(val) {
      this.withdrawalIndex = val
    },
    rechargeChange(val) {
      this.rechargeIndex = val
    },
    //查询钱包充值记录
    getDepositlist() {
      if (!this.listQuery.scroll) return
      this.listQuery.scroll = false
      getDepositlist(2, this.listQuery.page, this.listQuery.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.depositlist = this.depositlist.concat(res.data)
        if (res.data.length == this.listQuery.size) {
          this.listQuery.scroll = true
          this.listQuery.page += 1
        }
      }).catch((err) => {
      });
    },
    getDepositlist2() {
      if (!this.listQuery6.scroll) return
      this.listQuery6.scroll = false
      getDepositlist(1, this.listQuery6.page, this.listQuery6.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.depositlistDmd = this.depositlistDmd.concat(res.data)
        if (res.data.length == this.listQuery6.size) {
          this.listQuery6.scroll = true
          this.listQuery6.page += 1
        }
      }).catch((err) => {
      });
    },
    getDepositlist3() {
      if (!this.listQuery10.scroll) return
      this.listQuery10.scroll = false
      getDepositlist(3, this.listQuery10.page, this.listQuery10.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.depositSltList = this.depositSltList.concat(res.data)
        if (res.data.length == this.listQuery10.size) {
          this.listQuery10.scroll = true
          this.listQuery10.page += 1
        }
      }).catch((err) => {
      });
    },
    getDepositlist4() {
      if (!this.listQuery11.scroll) return
      this.listQuery11.scroll = false
      getDepositlist(4, this.listQuery11.page, this.listQuery11.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.depositSlcList = this.depositSlcList.concat(res.data)
        if (res.data.length == this.listQuery11.size) {
          this.listQuery11.scroll = true
          this.listQuery11.page += 1
        }
      }).catch((err) => {
      });
    },
    //查询U兑换DMD
    getUsdconversiondiamond() {
      if (!this.listQuery2.scroll) return
      this.listQuery2.scroll = false
      getUsdconversiondiamond(this.listQuery2.page, this.listQuery2.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.diamondList = this.diamondList.concat(res.data)
        if (res.data.length == this.listQuery2.size) {
          this.listQuery2.scroll = true
          this.listQuery2.page += 1
        }
      }).catch((err) => {
      });
    },
    //查询积分兑换记录
    getUsdconversionIntegral() {
      if (!this.listQuery3.scroll) return
      this.listQuery3.scroll = false
      getUsdconversionIntegral(this.listQuery3.page, this.listQuery3.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.integralList = this.integralList.concat(res.data)
        if (res.data.length == this.listQuery3.size) {
          this.listQuery3.scroll = true
          this.listQuery3.page += 1
        }
      }).catch((err) => {
      });
    },
    //查询U兑换记录
    getDiamondconversionUsd() {
      if (!this.listQuery4.scroll) return
      this.listQuery4.scroll = false
      getDiamondconversionUsd(this.listQuery4.page, this.listQuery4.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.usdtList = this.usdtList.concat(res.data)
        if (res.data.length == this.listQuery4.size) {
          this.listQuery4.scroll = true
          this.listQuery4.page += 1
        }
      }).catch((err) => {
      });
    },
    //钱包提现记录
    getTransfertList() {
      if (!this.listQuery5.scroll) return
      this.listQuery5.scroll = false
      getTransfertList(2, this.listQuery5.page, this.listQuery5.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.transfertList = this.transfertList.concat(res.data)
        if (res.data.length == this.listQuery5.size) {
          this.listQuery5.scroll = true
          this.listQuery5.page += 1
        }
      }).catch((err) => {
      });
    },
    getTransfertList2() {
      if (!this.listQuery7.scroll) return
      this.listQuery7.scroll = false
      getTransfertList(1, this.listQuery7.page, this.listQuery7.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.transfertListDmd = this.transfertListDmd.concat(res.data)
        if (res.data.length == this.listQuery7.size) {
          this.listQuery7.scroll = true
          this.listQuery7.page += 1
        }
      }).catch((err) => {
      });
    },
    getTransfertList3() {
      if (!this.listQuery12.scroll) return
      this.listQuery12.scroll = false
      getTransfertList(3, this.listQuery12.page, this.listQuery12.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.transfertSltList = this.transfertSltList.concat(res.data)
        if (res.data.length == this.listQuery12.size) {
          this.listQuery12.scroll = true
          this.listQuery12.page += 1
        }
      }).catch((err) => {
      });
    },
    getTransfertList4() {
      if (!this.listQuery13.scroll) return
      this.listQuery13.scroll = false
      getTransfertList(4, this.listQuery13.page, this.listQuery13.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.transfertSlcList = this.transfertSlcList.concat(res.data)
        if (res.data.length == this.listQuery13.size) {
          this.listQuery13.scroll = true
          this.listQuery13.page += 1
        }
      }).catch((err) => {
      });
    },
    // 查询收入支出
    getIncome() {
      if (!this.listQuery8.scroll) return
      this.listQuery8.scroll = false
      getIncome(1, this.listQuery8.page, this.listQuery8.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.incomeLIstanbul = this.incomeLIstanbul.concat(res.data)
        if (res.data.length == this.listQuery8.size) {
          this.listQuery8.scroll = true
          this.listQuery8.page += 1
        }
      }).catch((err) => {
      });
    },
    getIncome2() {
      if (!this.listQuery9.scroll) return
      this.listQuery9.scroll = false
      getIncome(0, this.listQuery9.page, this.listQuery9.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.expenditureList = this.expenditureList.concat(res.data)
        if (res.data.length == this.listQuery9.size) {
          this.listQuery9.scroll = true
          this.listQuery9.page += 1
        }
      }).catch((err) => {
      });
    },
    //查看订单详情
    showDetail(type, val) {
      this.detailState = type
      this.detail = val
      this.orderDetailState = true
      // console.log(val)
    },
    closeDetail() {
      this.orderDetailState = false
      this.detailState = false
      this.detail = {}
    },
    //复制链接
    copyFun(val) {
      const input = document.createElement("input"); //创建input
      let url = val
      input.setAttribute("value", url); //把input设置value
      document.body.appendChild(input); //添加这个dom对象
      input.select(); //选中该输入框
      document.execCommand("copy"); //复制该文本
      document.body.removeChild(input); //移除输入框
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    //查询SLT兑换SLT积分
    getSltconversionintegral() {
      if (!this.listQuery14.scroll) return
      this.listQuery14.scroll = false
      getSltconversionintegral(this.listQuery14.page, this.listQuery14.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.sltList = this.sltList.concat(res.data)
        if (res.data.length == this.listQuery14.size) {
          this.listQuery14.scroll = true
          this.listQuery14.page += 1
        }
      }).catch((err) => {
      });
    },
    getSltConversionslc() {
      if (!this.listQuery15.scroll) return
      this.listQuery15.scroll = false
      getSltConversionslc(this.listQuery15.page, this.listQuery15.size).then(res => {
        if (res.errorCode) {
          return
        }
        this.slcList = this.slcList.concat(res.data)
        if (res.data.length == this.listQuery15.size) {
          this.listQuery15.scroll = true
          this.listQuery15.page += 1
        }
      }).catch((err) => {
      });
    },
  },
};
</script>

<style lang="scss">
.order-pc {

  .order {
    width: 100%;
    height: 620px;
    background: #F9FAFD;
    box-shadow: 0px 10px 36px 0px rgba(15, 13, 46, 0.1);
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;

    .order-header {
      width: 100%;
      height: 60px;
      background: #F9FAFD;
      position: sticky;
      top: 0;
      left: 0;

      .order-header-return {
        width: 20px;
        height: auto;
        margin: 0 10px 0 20px;
        cursor: pointer;
      }

      .order-header-title {
        font-size: 16px;
        color: #161B2C;
        font-family: 'Medium';
      }
    }

    .order-nav {
      width: 100%;
      position: relative;
      position: sticky;
      top: 60px;
      left: 0;
      z-index: 1;
      background: #F9FAFD;

      .order-nav-list {
        margin: 0 10px 0 20px;
        text-align: center;
        cursor: pointer;
        position: relative;

        .order-nav-name {
          font-size: 14px;
          color: #8F93A6;
          line-height: 22px;
          font-family: 'Bold';
          padding-bottom: 8px;
          transition: all 0.2s;
        }

        .order-nav-list-line {
          width: 20px;
          height: 2px;
          border-radius: 2px;
          background: #6930FF;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -10px;
          z-index: 1;
        }

        .order-nav-active {
          color: #222222;
        }
      }

      .order-nav-line {
        width: 100%;
        height: 1px;
        background: #F3F2F8;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    .order-detail {
      .exchange-nav {
        width: 100%;
        background: #F9FAFD;
        padding-top: 10px;

        .exchange-nav-list {
          width: 70px;
          height: 24px;
          background: #EFF0F8;
          border-radius: 15px;
          font-size: 12px;
          color: #222222;
          text-align: center;
          line-height: 24px;
          margin-left: 20px;
          cursor: pointer;
          transition: all 0.2s;
        }
        .exchange-nav-list2{
          width: auto;
          padding: 0 12px;
          margin-left: 14px;
        }

        .exchange-nav-active {
          background: #6F38FF;
          color: #fff;
        }
      }
    }
  }

  .order::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .order-list {
    margin: 30px 20px 0 20px;
    cursor: pointer;

    p {
      font-family: 'Medium';
    }

    .order-list-top {
      .order-list-icon {
        width: 16px;
        height: auto;
      }

      .order-list-name {
        font-size: 14px;
        color: #222222;
        margin-left: 4px;
      }

      .order-list-num {
        font-size: 14px;
        color: #222222;
        flex: 1;
        text-align: right;
      }

      .order-list-exchang {
        width: 12px;
        height: auto;
        margin: 0 12px;
      }
    }

    .order-list-bottom {
      margin-top: 10px;

      .order-list-time {
        font-size: 12px;
        color: #8F93A6;
        flex: 1;
      }

      .order-list-point {
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }

      .order-list-state {
        font-size: 12px;
        color: #8F93A6;
        margin-left: 6px;
      }
    }
  }

  .order-more {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: #8F93A6;
    padding: 10px 0;
  }

  .empty_img {
    width: 120px;
    height: auto;
    display: block;
    margin: 110px auto 30px auto;
  }

  .empty_msg {
    font-size: 14px;
    color: #8F93A6;
    text-align: center;
  }

  //订单详情
  .order-detail-layer {
    width: 100%;
    height: 620px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 16px;
  }

  .detail-box {
    width: 100%;
    height: 560px;
    background: #FFFFFF;
    border-radius: 16px;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;

    .detail-box-header {
      width: 100%;
      padding-top: 20px;
      position: sticky;
      top: 0;
      left: 0;
      background: #fff;

      .detail-box-header-title {
        flex: 1;
        margin-left: 20px;
        font-size: 16px;
        color: #161B2C;
        font-family: 'Medium';
      }

      .detail-box-header-close {
        width: 20px;
        height: auto;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    .detail-box-content {
      width: 335px;
      margin: 20px;
      border-radius: 15px;
      padding: 16px;
      background: #F9FAFD;

      .detail-box-content-title {
        font-size: 14px;
        color: #8F93A6;
      }

      .detail-box-num {
        font-size: 24px;
        color: #222222;
        line-height: 38px;
        font-family: 'Bold';
      }

      .detail-box-unit {
        font-size: 12px;
        color: #8F93A6;
        flex: 1;
        margin-left: 4px;
        line-height: 30px;
      }

      .detail-box-state-icon {
        width: 20px;
        height: auto;
      }

      .detail-box-state {
        font-size: 18px;
        font-family: 'Bold';
        margin-left: 4px;
      }

      .detail-box-msg {
        font-size: 12px;
        color: #C6C9D3;
        margin-top: 10px;
        line-height: 16px;
      }

      .detail-box-consume {
        font-size: 14px;
        color: #222222;
        font-family: 'Bold';
        line-height: 22px;
        margin-top: 6px;
      }
    }

    .detail-box-list {
      margin: 0 20px 15px 20px;
      border-bottom: 0.5px solid #F2F1F5;
      padding-bottom: 16px;

      .detail-box-list-name {
        flex: 1;
        font-size: 14px;
        color: #8F93A6;
        font-family: 'Medium';
      }

      .detail-box-list-content {
        font-size: 14px;
        color: #222222;
        font-family: 'Medium';
        max-width: 170px;
        word-break: break-word;
      }

      .detail-box-copy {
        width: 12px;
        height: auto;
        margin-left: 6px;
      }
    }

    .detail-box-list:last-child {
      border-bottom: none;
    }
  }

  .detail-box::-webkit-scrollbar {
    width: 0;
  }
}
</style>
