<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "app",
  data() {
    return {
    };
  },
  components: {

  },
  mounted() {
    // console.log(localStorage.getItem('languageStorage'),'语言')
    if (!localStorage.getItem('languageStorage')) {
      localStorage.setItem('languageStorage', 'en');
    }
    if(localStorage.getItem('languageStorage') != 'en' && localStorage.getItem('languageStorage') != 'zh'){
      localStorage.setItem('languageStorage', 'en');
    }
    document.addEventListener("keydown", function (e) {
      if (e.key == "F12") {
        e.preventDefault(); // 如果按下键F12,阻止事件
      }
    });
    document.oncontextmenu = new Function("event.returnValue=false");

    //google验证
    // const script = document.createElement('script');
    // console.log(localStorage.getItem('languageStorage'))
    // script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LfEfSYpAAAAAA7smFxWyaDhvWR7L8slW2sXUdZT&hl=en';
    // script.src = 'https://www.recaptcha.net/recaptcha/enterprise.js?render=6LfEfSYpAAAAAA7smFxWyaDhvWR7L8slW2sXUdZT&hl=cn';
    // document.body.appendChild(script);
  },
};
</script>
<style lang="scss">
.news-list-platform {
  color: #6F38FF !important;
}

.dialogClass .el-dialog__body {
  padding: 0;
}

.dialogClass .el-dialog {
  border-radius: 16px;
}

html,
body,
div,
ul,
li,
input,
button,
img,
textarea,
p,
h1,
a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent; //禁用按压时有背景颜色
  box-sizing: border-box;
  font-family: 'Normal';
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

img {
  font-size: 0;
  line-height: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

.text {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}

.flex {
  display: flex;
  align-items: center;
}

.line {
  margin: 0 auto;
  display: flex;
  align-items: center;
  background: linear-gradient(to right,
      transparent 0%,
      transparent 90%,
      #000000 80%,
      #000000 100%);
  background-size: 10px 1px;
  background-repeat: repeat-x;
  height: 1px;
}

.el-input__inner {
  height: 50px !important;
  color: #222222;
  font-family: 'Medium' !important;
}



// .el-progress-bar__outer {
//   background-color: #7A41F5 !important;
// }

.el-progress-circle__track {
  stroke: rgba(255, 255, 255, 0.03) !important;
}

//loading
.el-loading-spinner .el-loading-text{
  color: #fff !important;
}
.el-loading-spinner .el-icon-loading{
    color: #e2e2e2;
}
</style>
