import request from '@/utils/axios';

//获取关卡列表
export function getGameland(query) {
    return request({
        url: '/api/g1/playgame/getgameland',
        method: 'get',
        params: query
    });
}
//获取我的战舰列表
export function getUserSpaceShip(query) {
    return request({
        url: '/api/g1/playgame/getuserspaceship',
        method: 'get',
        params: query
    });
}
//加入游戏关卡
export function addSpace(data) {
    return request({
        url: '/api/g1/playgame/addspace',
        method: 'post',
        data
    });
}
//获取im签名
export function getSig(query) {
    return request({
        url: '/api/v1/user/getsig',
        method: 'get',
        params: query
    });
}
//选择星球
export function setUserGameHome(data) {
    return request({
        url: '/api/g1/playgame/setusergamehome',
        method: 'post',
        data
    });
}
//游戏开始
export function gameStart(data) {
    return request({
        url: '/api/g1/playgame/gamestart',
        method: 'post',
        data
    });
}
//游戏结算
export function getSetTlement(data) {
    return request({
        url: '/api/g1/playgame/getsettlement',
        method: 'post',
        data
    });
}
//游戏结束
export function endGame(data) {
    return request({
        url: '/api/g1/playgame/endgame',
        method: 'post',
        data
    });
}
//校验用户是否未结算游戏
export function checkTlement(query) {
    return request({
        url: '/api/g1/playgame/checktlement',
        method: 'get',
        params: query
    });
}

//获取我的
export function getHistory(query) {
    return request({
        url: '/api/g1/gethistory/' + query,
        method: 'get',
    });
}
//查询游戏状态
export function gameOuttime(data) {
    return request({
        url: '/api/g1/playgame/gameouttime',
        method: 'post',
        data
    });
}
//退出游戏
export function userLogoutgame(data) {
    return request({
        url: '/api/g1/playgame/userlogoutgame',
        method: 'post',
        data
    });
}