<template>
  <div class="m-home">
    <header-menu v-if="headerState" />
    <router-view />
    <!-- <foot-menu v-if="footerState" /> -->
    <!-- <Login :type2="loginModal.show"></Login> -->
    <!-- {{ $t('about.title') }} -->
    <!-- <el-button-group> -->
    <!-- <el-button @click="setLanguage('zh')">中文</el-button>
      <el-button @click="setLanguage('en')">英文</el-button> -->
    <!-- </el-button-group> -->
    <!-- <Tip :text="tipModal.text" :ico="tipModal.ico" :showBtns="tipModal.showBtns" v-if="tipModal.show" :fun="tipModal.fun" :confirmFun="tipModal.confirmFun" :confirmBtnText="tipModal.confirmBtnText" :cancelFun="tipModal.cancelFun" :cancelBtnText="tipModal.cancelBtnText" /> -->
  </div>
</template>
<script>
import headerMenu from "@/views/m/common/header.vue";
import footMenu from "@/views/m/common/footer.vue";
import Login from "@/views/m/common/login.vue";
// import Tip from "@/views/m/common/tip.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "MHome",
  components: {
    Login,
    headerMenu,
    footMenu
    // Tip,
  },
  data() {
    return {
      language: '',
      headerState: true,
      footerState: true,
    };
  },
  computed:{
    ...mapState(["tipModal", "loginModal", "userInfo"]),
    getKICKEDOUT() {
      return this.$store.state.KICKED_OUT
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      immediate: true,
    },
    getKICKEDOUT: {
      handler(nval) {
        if(nval){
          const that = this
          that.$confirm(that.$t('logout'), that.$t('wallet.Attention'), {
            confirmButtonText: that.$t('common.Confirm'),
            cancelButtonText: that.$t('common.Cancel'),
            customClass: 'msgbox',
            type: 'warning'
          }).then(() => {
              this.$store.commit('updateKICKEDOUT', false)
            localStorage.clear();
            that.ClearUserInfo()
            that.$router.push({ path: '/' })
          }).catch(() => {
              this.$store.commit('updateKICKEDOUT', false)
            localStorage.clear();
            that.ClearUserInfo()
            that.$router.push({ path: '/' })
          });
        }
      }
    },
  },
  mounted() {

  },
  methods: {
    ...mapMutations(["ClearUserInfo"]), //登录弹窗
    setLanguage(val) {
      localStorage.setItem('languageStorage', val);
      this.language = val;
      this.$i18n.locale = val;
    }
  },
};
</script>
<style>
.msgbox {
  width: 620px !important;
}
</style>
<style lang="scss" scoped>
.scroll-box {
  height: 100vh;
  overflow-y: auto;
}

.msgbox {
  width: 620px !important;
}
</style>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}

.overflow_o {
  text-overflow: ellipsis;
  /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  white-space: nowrap;
  /*让文字不换行*/
  overflow: hidden;
  /*超出要隐藏*/
}

.fixed_bgm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 5;
}

.absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>