import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    // name: 'pcHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('../views/pc/selected.vue')
      },
      // {
      //   path: '/selected',
      //   name: 'selected',
      //   component: () => import('../views/pc/selected.vue')
      // },
      {
        path: '/deep',
        name: 'deep',
        component: () => import('../views/pc/deep/deep.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/pc/news/news.vue')
      },
      {
        path: '/create',
        name: 'create',
        component: () => import('../views/pc/create/createCenter.vue'),
        meta: {
          footerIsShow: true,
        }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/pc/user/userCenter.vue'),
        meta: {
          footerIsShow: true,
        }
      },
      {
        path: '/setUp',
        name: 'setUp',
        component: () => import('../views/pc/user/setUp.vue'),
        meta: {
          footerIsShow: true,
        }
      },
      // {
      //   path: '/activity',
      //   name: 'activity',
      //   component: () => import('../views/pc/activity/home.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      // {
      //   path: '/activity/answer',
      //   name: 'answer',
      //   component: () => import('../views/pc/activity/answer.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      {
        path: '/create/articleDetail',
        name: 'answer',
        component: () => import('../views/pc/create/articleDetail.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/create/newsDetail',
        name: 'answer',
        component: () => import('../views/pc/create/newsDetail.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //隐私政策
      {
        path: '/user/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import('../views/pc/user/privacyPolicy.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //新年活动
      // {
      //   path: '/newYear',
      //   name: 'newYear',
      //   component: () => import('../views/pc/activity/newYear.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },

      {
        path: '/loongActivity',
        name: 'loongActivity',
        component: () => import('../views/pc/activity/loongActivity.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      
      {
        path: '/galaxyarena',
        name: 'galaxyarena',
        component: () => import('../views/pc/game/notOnline.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      // {
      //   path: '/galaxyarena',
      //   name: 'galaxyarena',
      //   component: () => import('../views/pc/game/gameHome.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      // {
      //   path: '/game/gameFighting',
      //   name: 'gameFighting',
      //   component: () => import('../views/pc/game/gameFighting.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      // {
      //   path: '/game/gameMate',
      //   name: 'gameMate',
      //   component: () => import('../views/pc/game/gameMate.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    // name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('../views/m/selected.vue'),

      },
      // {
      //   path: '/selected',
      //   name: 'selected',
      //   component: () => import('../views/m/selected.vue')
      // },
      {
        path: '/deep',
        name: 'deep',
        component: () => import('../views/m/deep/deep.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/m/news/news.vue')
      },
      {
        path: '/create',
        name: 'create',
        component: () => import('../views/m/create/createCenter.vue')
      },
      // {
      //   path: '/activity',
      //   name: 'activity',
      //   component: () => import('../views/m/activity/home.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      // {
      //   path: '/activity/answer',
      //   name: 'answer',
      //   component: () => import('../views/m/activity/answer.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      {
        path: '/create/articleDetail',
        name: 'answer',
        component: () => import('../views/m/create/articleDetail.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/create/newsDetail',
        name: 'answer',
        component: () => import('../views/m/create/newsDetail.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //隐私政策
      {
        path: '/user/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import('../views/m/user/privacyPolicy.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //下载
      {
        path: '/download',
        name: 'download',
        component: () => import('../views/m/download/download.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //新年活动
      // {
      //   path: '/newYear',
      //   name: 'newYear',
      //   component: () => import('../views/m/activity/newYear.vue'),
      //   meta: {
      //     headerIsShow: true,
      //     footerIsShow: true,
      //   }
      // },
      {
        path: '/loongActivity',
        name: 'loongActivity',
        component: () => import('../views/m/activity/loongActivity.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/walletIndex',
        name: 'walletIndex',
        component: () => import('../views/m/wallet/walletIndex.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/UserExchange',
        name: 'UserExchange',
        component: () => import('../views/m/wallet/UserExchange.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/galaxyarena',
        name: 'galaxyarena',
        component: () => import('../views/m/game/gameHome.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/game/gameFighting',
        name: 'gameFighting',
        component: () => import('../views/m/game/gameFighting.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/game/gameMate',
        name: 'gameMate',
        component: () => import('../views/m/game/gameMate.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      {
        path: '/game/historyRanking',
        name: 'historyRanking',
        component: () => import('../views/m/game/historyRanking.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
    ]
  }
]
const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes: IsPC() ? routes_pc : routes_m
})

export default router
