import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
import 'lib-flexible'
// import Qs from 'qs'
// import MD5 from 'js-md5';
import Meta from "vue-meta"
import Axios from '@/utils/axios'
import {Base64} from 'js-base64'
import i18n from '@/components/language/index.ts'
import $ from 'jquery'
// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import './assets/font/font.css'
//拼图验证
import SlideVerify from 'vue-monoplasty-slide-verify';
// three.js
Vue.use(VueAwesomeSwiper);

Vue.use(Base64)
Vue.use(Meta)
Vue.use(SlideVerify)
// Vue.use($)
Vue.config.productionTip = false
Vue.prototype.$axios = Axios //引用axios
// Vue.prototype.$MD5 = MD5;
window.jQuery = $;
window.$ = $

import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
Vue.use(element)
// Vue.use(VueLazyload)
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('../src/assets/a1.jpg'),
  // loading: require('../src/assets/pc/images/loading.gif'),
  loading: require('./assets/loadLazy/loading.svg'),
  attempt: 1
  })

new Vue({
  router,
  // Qs,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
