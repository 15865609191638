<template>
  <div class="wallet-home">
    <div v-if="dialogVisible" @click="closeWallet" class="walletIndex-layer"></div>
    <transition name="el-zoom-in-top">
      <div v-show="dialogVisible" class="wallet">
        <div v-show="!orderState" class="user-wallet">
          <img class="user-wallet-bg" src="@/assets/pc/wallet/wallet_bg01.png" alt="">
          <div class="user-wallet-top">
            <div class="user-wallet-top-detail">
              <p class="user-wallet-top-name">{{ $t('wallet.Wallet') }}</p>
              <div class="user-wallet-address">
                <p class="user-wallet-address-txt">{{ userInfo.address ? userInfo.address : '' | nameFilter }}</p>
                <img class="user-wallet-address-copy" @click="copyFun(userInfo.address)"
                  src="@/assets/pc/common/common_copy.png" alt="">
              </div>
            </div>
            <img class="user-wallet-top-photo" :src="userInfo.userpic" alt="">
          </div>
          <!-- 总资产 -->
          <div
            :style="{ height: walletState == 0 ? '120px' : '0px', overflow: walletState == 0 ? '' : 'hidden', transition: walletState == 0 ? 'all 0.2s' : 'all 0.3s', }">
            <p class="user-wallet-total-title">{{ $t('wallet.Total') }}</p>
            <div class="user-wallet-total flex">
              <img class="user-wallet-total-icon" src="@/assets/pc/wallet/asset_icon.png" alt="">
              <p class="user-total" v-if="userBalance">{{ userBalance.balance.usdt.balance ?
      Math.floor(userBalance.balance.usdt.balance * 1000000) / 1000000 : '' }}</p>
              <!-- @click="orderState = true" -->
              <img @click="orderState = true" class="user-wallet-total-order"
                src="@/assets/pc/wallet/wallet_order_icon.png" alt="">
              <img @click="ruleState = true" style="margin-left: 30px;" class="user-wallet-total-order"
                src="@/assets/pc/wallet/rule_icon.png" alt="">
            </div>
            <div class="user-operate flex">
              <div class="user-operate-detail flex" @click="operateChange(1)">
                <img class="user-operate-icon" src="@/assets/pc/wallet/wallet_broken.png" alt="">
                <p class="user-operate-name">Receive</p>
              </div>
              <!-- @click="operateChange(2)" -->
              <div @click="operateChange(2)" class="user-operate-detail flex user-operate-end">
                <img class="user-operate-icon" src="@/assets/pc/wallet/wallet_send.png" alt="">
                <p class="user-operate-name">Withdraw</p>
              </div>
            </div>
          </div>
          <div :style="{ height: walletHeight }" class="wallet-detail">
            <!-- 我的资产 -->
            <div v-if="walletState == 0" class="user-wallet-detail">
              <div class="user-wallet-detail-top flex">
                <p class="user-wallet-detail-title">{{ $t('wallet.mineTotal') }}</p>
                <p class="user-wallet-detail-test">Bsc Main</p>
              </div>
              <!-- @click="operateChange(3)" -->
              <div class="user-wallet-list flex">
                <img class="user-wallet-list-icon" src="@/assets/pc/wallet/USDT_icon.png" alt="">
                <p class="user-wallet-list-name">USDT</p>
                <p class="user-wallet-list-num" v-if="userBalance">
                  {{ userBalance.balance.usdt.balance ? Math.floor(userBalance.balance.usdt.balance * 1000000) / 1000000
      :
      '' }}
                </p>
              </div>
              <div class="user-wallet-list flex">
                <img class="user-wallet-list-icon" src="@/assets/pc/wallet/BNB_icon.png" alt="">
                <p class="user-wallet-list-name">BNB</p>
                <p class="user-wallet-list-num" v-if="userBalance">
                  {{ userBalance.balance.bnb.balance ? Math.floor(userBalance.balance.bnb.balance * 1000000) / 1000000 :
      '' }}
                </p>
              </div>
              <div>
                <div style=" border-radius: 14px 14px 0 0;" class="user-wallet-list flex">
                  <img class="user-wallet-list-icon" src="@/assets/pc/wallet/DMD_icon2.png" alt="">
                  <p class="user-wallet-list-name">DMD</p>
                  <p class="user-wallet-list-num" v-if="userBalance">
                    {{ userBalance.diamond_num ? Math.floor(userBalance.diamond_num * 1000000) / 1000000 :
      '' }}
                  </p>
                </div>
                <div class="freeze">
                  <p class="freeze-line"></p>
                  <p v-if="userBalance.frozen_diamond" class="freeze-dmd">{{ userBalance.frozen_diamond }}DMD <span
                      style="color: #FF3B8A;font-family: 'Bold';">冻结中</span> </p>
                  <p class="freeze-msg">{{ $t('wallet.msg18') }}</p>
                </div>
              </div>
              <!-- <div class="user-wallet-list flex">
                <img class="user-wallet-list-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
                <p class="user-wallet-list-name">SLC</p>
                <p class="user-wallet-list-num" v-if="userBalance">
                  {{ userBalance.balance.slc.balance ? Math.floor(userBalance.balance.slc.balance * 1000000) / 1000000 :
      '' }}</p>
              </div> -->
              <div class="user-wallet-list flex">
                <img class="user-wallet-list-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
                <p class="user-wallet-list-name">SLT</p>
                <p class="user-wallet-list-num" v-if="userBalance">{{ userBalance.balance.slt.balance ?
      Math.floor(userBalance.balance.slt.balance * 1000000) / 1000000 :
      '' }}</p>
              </div>
            </div>
            <!-- 充值 -->
            <div v-if="walletState == 1" class="wallet-recharge">
              <p class="wallet-recharge-title">{{ $t('wallet.Address3') }}</p>
              <p class="wallet-recharge-address">{{ userInfo.address }}</p>
              <div class="wallet-recharge-tips">
                <img class="wallet-recharge-tip-icon" src="@/assets/pc/wallet/tips_icon.png" alt="">
                <p class="wallet-recharge-msg">{{ $t('wallet.msg10') }}</p>
              </div>
              <p class="wallet-recharge-copy" @click="copyFun(userInfo.address)">{{ $t('wallet.Copy') }}</p>
              <p class="wallet-recharge-cancel" @click="operateChange(0)">{{ $t('common.Cancel') }}</p>
            </div>
            <!-- 提现 -->
            <div v-if="walletState == 2" class="wallet-withdrawal">
              <p class="withdrawal-title">{{ $t('wallet.Withdraw') }}</p>
              <div class="withdrawal-usdt flex">
                <div @mouseenter="withdrawalFlag = true" @mouseleave="withdrawalFlag = false"
                  class="withdrawal-select flex">
                  <div v-if="withdrawalState != 4" class="flex" style="flex:1">
                  <img class="withdrawal-select-icon" :src="withdrawalList[withdrawalState - 1].img" alt="">
                  <p class="withdrawal-select-txt">{{ withdrawalList[withdrawalState - 1].name }}</p>
                </div>
                <div v-else class="flex" style="flex:1">
                  <img class="withdrawal-select-icon" :src="withdrawalList[withdrawalState - 2].img" alt="">
                  <p class="withdrawal-select-txt">{{ withdrawalList[withdrawalState - 2].name }}</p>
                </div>
                  <img class="withdrawal-select-img" src="@/assets/pc/wallet/wallet_change_icon.png" alt="">
                  <transition name="el-zoom-in-top">
                    <div v-show="withdrawalFlag" class="withdrawal-select-list">
                      <div v-for="(item, index) in withdrawalList" :key="index" @click="withdrawalState = item.state"
                        :style="{ background: withdrawalState == item.state ? '#F4F5F9' : '#fff' }"
                        class="withdrawal-select-list-detail flex">
                        <img class="withdrawal-select-list-icon" :src="item.img" alt="">
                        <p class="withdrawal-select-list-name">{{ item.name }}</p>
                      </div>
                    </div>
                  </transition>
                </div>
                <div v-if="userBalance" class="withdrawal-usdt-num">
                  <p v-if="withdrawalState == 1">
                    {{ Math.floor(userBalance.balance.usdt.balance * 1000000) / 1000000 }}
                  </p>
                  <p v-if="withdrawalState == 2">
                    {{ Math.floor(userBalance.balance.bnb.balance * 1000000) / 1000000 }}
                  </p>
                  <p v-if="withdrawalState == 3">
                    {{ Math.floor(userBalance.balance.slc.balance * 1000000) / 1000000 }}
                  </p>
                  <p v-if="withdrawalState == 4">
                    {{ Math.floor(userBalance.balance.slt.balance * 1000000) / 1000000 }}
                  </p>
                </div>
              </div>
              <div class="withdrawal-input flex">
                <!-- if(value){value=value.replace(/[^\d]/g,'')} -->
                <!-- if(value<0){value=''} if(value>999999999){value=999999999} -->
                <el-input onkeyup="value=value.replace(/[^0-9.]/g,'')" v-model="usdtNum" class="withdrawal-input-class"
                  placeholder="0"></el-input>
                <!-- <p class="withdrawal-input-line"></p> -->
                <!-- <p v-if="userBalance" class="withdrawal-input-all"
                  @click="usdtNum = withdrawalState ? Math.floor(userBalance.balance.usdt.balance * 1000000) / 1000000 : Math.floor(userBalance.balance.bnb.balance * 1000000) / 1000000">
                  全部</p> -->
              </div>
              <p class="withdrawal-title" style="margin-top: 20px;">{{ $t('wallet.Address') }}</p>
              <div class="withdrawal-input flex">
                <el-input v-model="walletAddress" class="withdrawal-input-class"
                  :placeholder="$t('wallet.placeholder1')"></el-input>
                <p class="withdrawal-input-line"></p>
                <p class="withdrawal-input-all" @click="paste">{{ $t('wallet.Paste') }}</p>
              </div>
              <div class="wallet-recharge-tips" style="margin-top: 10px;">
                <img class="wallet-recharge-tip-icon" src="@/assets/pc/wallet/tips_icon.png" alt="">
                <p class="wallet-recharge-msg">{{ $t('wallet.msg10') }}</p>
              </div>
              <p class="withdrawal-title" style="margin-top: 20px;">{{ $t('wallet.emailAddress') }}</p>
              <p class="withdrawal-address">{{ userInfo.email }}</p>
              <div class="withdrawal-input flex">
                <el-input v-model="emailCode" class="withdrawal-input-class"
                  :placeholder="$t('wallet.placeholder2')"></el-input>
                <p v-show="show" class="withdrawal-input-all" style="text-decoration:underline;" @click="getCode">{{
      $t('login.getCode') }}</p>
                <p v-show="!show" class="withdrawal-input-all" style="opacity: 0.5;">{{ count }}s{{ $t('login.again') }}
                </p>
              </div>
              <p v-if="gasfee" class="withdrawal-cost">{{ $t('wallet.Transaction') }} <span>{{ gasfee }} BNB</span>：</p>
              <p class="wallet-recharge-copy" @click="withdrawalFun()" style="margin-top: 28px;">{{ $t('common.Confirm')
                }}</p>
              <p class="wallet-recharge-cancel" @click="operateChange(0)">{{ $t('common.Cancel') }}</p>
            </div>
            <!-- 兑换 -->
            <div v-if="walletState == 3" class="wallet-exchange">
              <div class="flex" style="position: relative;z-index: 1;">
                <p class="withdrawal-title" style="flex: 1;">{{ $t('common.Swap') }}</p>
                <div class="wallet-type flex">
                  <p @click="walletSelected = !walletSelected" class="wallet-type-name">{{ walletType == 0 ? 'DMD' :
      'SLT'
                    }}</p>
                  <img @click="walletSelected = !walletSelected" :class="{ 'wallet-type-ico-trans': walletSelected }"
                    class="wallet-type-icon" src="@/assets/pc/wallet/wallet_change_icon.png" alt="">
                  <transition name="el-zoom-in-top">
                    <div v-if="walletSelected" class="wallet-Selected">
                      <p class="wallet-Selected-list" :class="{ 'wallet-Selected-active': walletType == 0 }"
                        @click="walletType = 0, walletSelected = false, exchangeState = true">DMD</p>
                      <p class="wallet-Selected-list" :class="{ 'wallet-Selected-active': walletType == 1 }"
                        @click="walletType = 1, walletSelected = false, exchangeState = true">SLT</p>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="exchange-list flex">
                <el-input v-model="exchangeNum" class="exchange-input" placeholder="0"></el-input>
                <div class="exchange-list-detail">
                  <div class="exchange-list-top flex" style="justify-content:right;">
                    <img class="exchange-list-icon"
                      :src="exchangeState ? require('@/assets/pc/wallet/USDT_icon.png') : require('@/assets/pc/wallet/DMD_icon.png')"
                      alt="">
                    <p class="exchange-list-name">{{ exchangeState ? 'USDT' : 'DMD' }}</p>
                  </div>
                  <div class="exchange-list-top flex" style="margin-top: 16px;">
                    <p class="exchange-list-balance">余额：11,129.12</p>
                    <p class="exchange-list-max">最大值</p>
                  </div>
                </div>
                <img v-if="walletType == 0" class="exchange_icon" @click="exchangeState = !exchangeState"
                  src="@/assets/pc/wallet/exchange_icon.png" alt="">
              </div>
              <div v-if="walletType == 0" class="exchange-list flex" style="margin-top: 16px;">
                <el-input v-model="exchangeNum" class="exchange-input" placeholder="0"></el-input>
                <div class="exchange-list-detail">
                  <div class="exchange-list-top flex" style="justify-content:right;">
                    <img class="exchange-list-icon"
                      :src="!exchangeState ? require('@/assets/pc/wallet/USDT_icon.png') : require('@/assets/pc/wallet/DMD_icon.png')"
                      alt="">
                    <p class="exchange-list-name">{{ !exchangeState ? 'USDT' : 'DMD' }}</p>
                  </div>
                  <div class="exchange-list-top flex" style="margin-top: 16px;">
                    <p class="exchange-list-balance">余额：0</p>
                  </div>
                </div>
              </div>
              <div v-if="walletType == 1" class="exchange-list flex" style="margin-top: 16px;">
                <el-input v-model="exchangeNum" class="exchange-input" placeholder="0"></el-input>
                <div class="exchange-list-detail">
                  <div class="exchange-list-top flex" style="justify-content:right;">
                    <img class="exchange-list-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
                    <p class="exchange-list-name">SLT</p>
                  </div>
                  <div class="exchange-list-top flex" style="margin-top: 16px;">
                    <p class="exchange-list-balance">余额：0</p>
                  </div>
                </div>
              </div>
              <p v-if="walletType == 0" class="exchange-tips">1USDT≈23.231DMD</p>
              <p v-if="walletType == 1" class="exchange-tips">1USDT≈23.231DMD</p>
              <p class="wallet-recharge-copy" style="margin-top: 112px;">兑换</p>
              <p class="wallet-recharge-cancel" @click="operateChange(0)">取消</p>
            </div>
          </div>
          <!-- 遮罩 -->
          <div class="user-wallet-layer" style="display: none;">
            <img class="user-wallet-layer-img" src="@/assets/pc/wallet/image_01.png" alt="">
            <p class="user-wallet-layer-msg">{{ $t('wallet.msg17') }}</p>
          </div>
          <!-- 付费规则 -->
          <div>
            <div v-show="ruleState" @click="ruleState = false" class="wallet-rule-layer" style="z-index: 10;"></div>
            <transition name="el-zoom-in-top">
              <div v-show="ruleState" class="wallet-rule">
                <p class="wallet-rule-title">{{ $t('wallet.Attention') }}
                  <img @click="ruleState = false" class="wallet-rule-close" src="@/assets/pc/common/common_close_02.png"
                    alt="">
                </p>
                <div class="wallet-rule-detail">
                  <p class="wallet-rule-content">{{ $t('common.pay') }}</p>
                </div>
                <p @click="ruleState = false" class="wallet-rule-btn">{{ $t('common.Confirm') }}</p>
              </div>
            </transition>
          </div>
        </div>
        <transition name="el-zoom-in-top">
          <order v-if="orderState" @close="orderState = false"></order>
        </transition>
        <!-- gas费用不足 -->
        <div v-show="gasState">
          <div class="exchange-layer" style="z-index: 101;" @click="gasState = false"></div>
          <div class="gas-box">
            <div class="gas-top flex">
              <p class="gas-top-title">{{ $t('wallet.Attention') }}</p>
              <img class="gas-top-icon" @click="gasState = false" src="@/assets/pc/common/common_close_01.png" alt="">
            </div>
            <p class="gas-content">{{ gasContent }}</p>
            <div class="gas-footer flex">
              <p style="flex: 1;"></p>
              <p class="gas-btn gas-cancel" @click="gasState = false">{{ gasChangeState == 1 ? $t('common.Cancel') :
      $t('wallet.wait') }}</p>
              <p class="gas-btn gas-confirm" @click="gasCreate">{{ gasChangeState == 1 ? $t('wallet.Swap') :
                $t('wallet.again') }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import order from "@/views/pc/wallet/walletOrder.vue";
import {
  getUserbalance, getGasfee, useraccountWithdraw,
  createGasfeeMW, gasfeeStatus
} from '@/api/wallet';
import {
  getuser, sendcode
} from '@/api/user';

export default {
  filters: {
    nameFilter(name) {
      if (name.length > 10) {
        let a = name.slice(0, 4)
        let b = name.slice(38, 42)
        return a + '****' + b;
      } else {
        return name;
      }

    }
  },
  name: "wallet",
  props: {
    "state": { state: Boolean, default: false }
  },
  components: {
    order
  },
  data() {
    return {
      dialogVisible: this.state,
      walletState: 0, //0-首页 1-充值 2-提现 3-兑换
      walletHeight: '460px',
      //提现
      usdtNum: '', //USDT
      walletAddress: '', //钱包地址
      exchangeNum: '', //兑换
      //订单列表
      orderState: false,
      //倒计时
      show: true,
      count: '',
      timer: null,
      emailCode: '',//邮箱验证码
      walletType: 0, //0-DMD 1-SLT
      walletSelected: false,
      exchangeState: true, //true-U false -D
      userInfo: '',//用户信息
      userBalance: '',//用户钱包信息
      withdrawalState: 1, //1-usdt 2-bnb 3-slc 4-slt
      gasfee: null,//gas费
      timer2: null,//定时器
      gasState: false,
      gasChangeState: 1,
      gasContent: '',
      gasInfo: '',//gas内容
      gRecaptcha: '',
      withdrawalFlag: false,
      ruleState: false, //付费规则
      withdrawalList: [
        {
          img: require('@/assets/pc/wallet/USDT_icon.png'),
          name: 'USDT',
          state: 1,
        },
        {
          img: require('@/assets/pc/wallet/BNB_icon.png'),
          name: 'BNB',
          state: 2,
        },
        // {
        //   img: require('@/assets/pc/wallet/SLT_icon.png'),
        //   name: 'SLC',
        //   state: 3,
        // },
        {
          img: require('@/assets/pc/wallet/SLT_icon2.png'),
          name: 'SLT',
          state: 4,
        }
      ]
    };
  },
  watch: {
    state: {
      handler(newVal) {
        this.dialogVisible = newVal
        if (newVal) {
          this.getUserbalance()
          this.getuser()
          // const script = document.createElement('script');
          // script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LfEfSYpAAAAAA7smFxWyaDhvWR7L8slW2sXUdZT&hl=en';
          // document.body.appendChild(script);
        }
      },
    },
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
      }
    },
    usdtNum: {
      handler(nval) {
        if (this.usdtNum && this.walletAddress) {
          clearTimeout(this.timer2)
          this.timer2 = setInterval(() => {
            this.getGasfee()
            clearTimeout(this.timer2)
          }, 1000)
        } else {
          this.gasfee = null
        }
      }
    },
    walletAddress: {
      handler(nval) {
        if (this.usdtNum && this.walletAddress) {
          clearTimeout(this.timer2)
          this.timer2 = setInterval(() => {
            this.getGasfee()
            clearTimeout(this.timer2)
          }, 1000)
        } else {
          this.gasfee = null
        }
      }
    }
  },
  computed: {
    ...mapState(["tipModal", "loginModal"]),
    isUserInfo() {
      this.userInfo = this.$store.state.userInfo
      return this.$store.state.userInfo
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["GetUserInfo"]), //登录弹窗.
    closeWallet() {
      this.$emit("close")
      this.walletHeight = '460px'
      this.walletState = 0
      this.walletType = 0
      this.walletSelected = false
      this.exchangeState = true
      this.orderState = false
      this.usdtNum = ''
      this.walletAddress = ''
      this.emailCode = ''
      this.gasInfo = ''
      this.withdrawalState = 1
    },
    operateChange(val) {
      //0-首页 1-充值地址 2-提现 3-兑换
      this.walletState = val
      if (val == 0) {
        this.walletHeight = '460px'
        this.walletType = 0
        this.walletSelected = false
        this.exchangeState = true
      } else if (val == 1) {
        this.walletHeight = '511px'
      } else if (val == 2) {
        this.walletHeight = '637px'
      } else if (val == 3) {
        this.walletHeight = '510px'
      }
    },
    //复制链接
    copyFun(val) {
      const input = document.createElement("input"); //创建input
      let url = val
      input.setAttribute("value", url); //把input设置value
      document.body.appendChild(input); //添加这个dom对象
      input.select(); //选中该输入框
      document.execCommand("copy"); //复制该文本
      document.body.removeChild(input); //移除输入框
      this.$message({
        message: 'Succeeded',
        type: 'success'
      });
    },
    //发送验证码
    getCode() {
      var reg = /\w+@\w+\.\w+/
      if (!reg.test(this.userInfo.email)) {
        this.$message.error(this.$t('error.msg1'));
        return
      }
      // grecaptcha.enterprise.ready(async () => {
      //   const token = await grecaptcha.enterprise.execute('6LfEfSYpAAAAAA7smFxWyaDhvWR7L8slW2sXUdZT', { action: 'LOGIN' });
      // console.log(token)
      this.getTimeout('')
      // });
    },
    getTimeout(token) {
      this.gRecaptcha = token
      this.sendCode()
      const TIME_COUNT = 180;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    sendCode() {
      let data = {
        email: this.userInfo.email,
        // recaptcha: this.gRecaptcha
      }
      sendcode(data).then(res => {
        if (res.errorCode) {
          return
        }
        this.$message({
          message: this.$t('error.msg2'),
          type: 'success'
        })
      }).catch((err) => {
        this.show = true;
        clearInterval(this.timer);
        this.timer = null;
      });
    },
    //粘贴
    paste() {
      navigator.clipboard.readText().then((res) => {
        // console.log('剪切板内容:', res);
        this.walletAddress = res
      }).catch((err) => {
        // console.log('获取剪切板内容失败:', err);
      });
    },
    //查询用户钱包信息
    getUserbalance() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getUserbalance().then(res => {
        if (res.errorCode) {
        loading.close();
          return
        }
        this.userBalance = res.data
        loading.close();
      }).catch((err) => {
        loading.close();
      });
    },
    getuser() {
      getuser().then(res => {
        if (res.errorCode) {
          return
        }
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.GetUserInfo()
      }).catch(() => {
        // this.$message.error('发送失败');
      });
    },
    //查询交易手续费
    getGasfee() {
      let coin_type
      if (this.withdrawalState == 1) {
        coin_type = this.userBalance.balance.usdt.coin_type
      } else if (this.withdrawalState == 2) {
        coin_type = this.userBalance.balance.bnb.coin_type
      } else if (this.withdrawalState == 3) {
        coin_type = this.userBalance.balance.slc.coin_type
      } else if (this.withdrawalState == 4) {
        coin_type = this.userBalance.balance.slt.coin_type
      }
      let data = {
        to_address: this.walletAddress,
        amount: this.usdtNum,
        coin_type: coin_type
      }
      getGasfee(data).then(res => {
        if (res.errorCode) {
          return
        }
        this.gasfee = Math.floor(Number(res.data.gas_price) * 1000000) / 1000000
      }).catch((err) => {
      });
    },
    //提现
    withdrawalFun() {
      // console.log(Number(this.gasfee), Number(this.userBalance.balance.bnb.balance))
      // console.log(Number(this.gasfee) > Number(this.userBalance.balance.bnb.balance))
      if (this.usdtNum == '' || this.usdtNum == 0) {
        this.$message.error(this.$t('wallet.msg14'))
        return
      }
      if (this.withdrawalState == 1) {
        if (Number(this.usdtNum) > Number(this.userBalance.balance.usdt.balance)) {
          this.$message.error(this.$t('wallet.msg15'));
          return
        }
      } else if (this.withdrawalState == 2) {
        if (Number(this.usdtNum) > Number(this.userBalance.balance.bnb.balance)) {
          this.$message.error(this.$t('wallet.msg15'));
          return
        }
      } else if (this.withdrawalState == 3) {
        if (Number(this.usdtNum) > Number(this.userBalance.balance.slc.balance)) {
          this.$message.error(this.$t('wallet.msg15'));
          return
        }
      } else if (this.withdrawalState == 4) {
        if (Number(this.usdtNum) > Number(this.userBalance.balance.slt.balance)) {
          this.$message.error(this.$t('wallet.msg15'));
          return
        }
      }
      if (this.walletAddress == '') {
        this.$message.error(this.$t('wallet.placeholder1'))
        return
      }
      if (this.emailCode == '') {
        this.$message.error(this.$t('wallet.placeholder2'))
        return
      }

      this.$confirm(this.$t('common.transfer'), this.$t('wallet.Attention'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel')
      }).then(() => {
        // if (this.withdrawalState) {
        //   if (Number(this.gasfee) > Number(this.userBalance.balance.bnb.balance)) {
        //     this.gasState = true
        //     this.getGasfeeStatus()
        //     return
        //   }
        // } else {
        //   if (Number(this.gasfee) > Number(this.userBalance.balance.bnb.balance)) {
        //     this.$message.error(this.$t('wallet.msg16'))
        //     return
        //   }
        // }
        let coin_type
        if (this.withdrawalState == 1) {
          coin_type = this.userBalance.balance.usdt.coin_type
        } else if (this.withdrawalState == 2) {
          coin_type = this.userBalance.balance.bnb.coin_type
        } else if (this.withdrawalState == 3) {
          coin_type = this.userBalance.balance.slc.coin_type
        } else if (this.withdrawalState == 4) {
          coin_type = this.userBalance.balance.slt.coin_type
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = {
          to_address: this.walletAddress,
          amount: this.usdtNum,
          coin_type: coin_type,
          email: this.userInfo.email,
          code: this.emailCode
        }
        useraccountWithdraw(data).then(res => {
          if (res.errorCode) {
            loading.close();
            this.$message.error(res.msg);
            return
          }
          if (res.data.status == 0) {
            this.gasInfo = res.data
            this.getGasfeeStatus()
            loading.close();
          } else {
            loading.close();
            this.$message(this.$t('wallet.Completed'))
            this.closeWallet()
            this.getUserbalance()
          }
        }).catch((err) => {
          loading.close();
        });
      }).catch(() => {
        loading.close();
      });


    },
    //兑换gas费
    gasCreate() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      createGasfeeMW({ amount: this.gasInfo.gas_fee, coin_type: this.gasInfo.coin_type }).then(res => {
        if (res.errorCode) {
        loading.close();
          return
        }
        loading.close();
        this.gasState = false
        this.closeWallet()
        this.$message(this.$t('wallet.msg9'));
      }).catch((err) => {
        loading.close();
      });
    },
    //查询链上是否有gasfee
    getGasfeeStatus() {
      this.getUserbalance()
      gasfeeStatus().then(res => {
        if (res.errorCode) {
          return
        }
        if (res.data.status == 1) {
          this.gasContent = this.$t('wallet.msg5') + this.gasInfo.gas_fee + this.gasInfo.coin_title + this.$t('wallet.msg66')
          this.gasState = true
        } else if (res.data.status == 0) {
          this.gasContent = this.$t('wallet.msg8') + res.data.count + this.$t('wallet.msg7')
          this.gasState = true
        }
        this.gasChangeState = res.data.status
      }).catch((err) => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  background: #fff !important;
  z-index: 1 !important;
}

.walletIndex-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  // background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 90;
}

.wallet {
  width: 375px;
  position: absolute;
  z-index: 99;
  top: 40px;
  right: -236px;

  // 钱包主页
  .user-wallet {
    width: 100%;
    padding-top: 30px;
    background: #141629;
    border-radius: 16px;
    position: relative;
    z-index: 1;

    .user-wallet-top {
      display: flex;
      align-items: center;
      margin: 0 20px;
      position: relative;
      z-index: 1;

      .user-wallet-top-detail {
        flex: 1;

        .user-wallet-top-name {
          font-size: 16px;
          color: #9395A4;
          font-family: 'Bold';
        }

        .user-wallet-address {
          display: flex;
          align-items: center;
          margin-top: 6px;
          cursor: pointer;

          .user-wallet-address-txt {
            font-size: 12px;
            color: #fff;
            line-height: 18px;
          }

          .user-wallet-address-copy {
            width: 12px;
            height: 12px;
            margin-left: 5px;
          }
        }
      }

      .user-wallet-top-photo {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #9395A4;
      }
    }

    .user-wallet-total-title {
      font-size: 12px;
      color: #9395A4;
      font-family: 'Medium';
      margin: 30px 0 10px 20px;
    }

    .user-wallet-total {
      margin: 0 20px;
      position: relative;
      z-index: 1;

      .user-wallet-total-icon {
        width: 12px;
        height: auto;
      }

      .user-total {
        font-size: 24px;
        color: #fff;
        font-family: 'Bold';
        margin-left: 6px;
        flex: 1;
      }

      .user-wallet-total-order {
        width: 20px;
        height: auto;
        cursor: pointer;
      }
    }

    .user-operate {
      margin: 20px 20px 30px 20px;
      justify-content: center;
      position: relative;
      z-index: 1;

      .user-operate-detail {
        width: 162px;
        height: 38px;
        background: linear-gradient(125deg, #6BFF3D 0%, #CEF05E 100%);
        border-radius: 20px;
        justify-content: center;
        cursor: pointer;

        .user-operate-icon {
          width: 16px;
          height: auto;
        }

        .user-operate-name {
          font-size: 14px;
          color: #161B2C;
          margin-left: 4px;
          font-family: 'Medium';
        }
      }

      .user-operate-end {
        background: linear-gradient(125deg, #673DFF 0%, #C547FF 100%);
        border-radius: 20px;
        margin-left: 10px;

        .user-operate-name {
          color: #fff;
        }
      }
    }

    .wallet-detail {
      width: 100%;
      background: #F9FAFD;
      padding-top: 20px;
      border-radius: 16px 16px 14px 14px;
      transition: all 0.3s;
      margin-top: 20px;
      font-family: 'Medium';
      position: relative;
      z-index: 1;

      .user-wallet-detail {
        position: relative;
        z-index: 1;

        .user-wallet-detail-top {
          margin: 0 20px;

          .user-wallet-detail-title {
            flex: 1;
            font-size: 16px;
            color: #222222;
            font-family: 'Medium';
          }

          .user-wallet-detail-test {
            font-size: 12px;
            color: #7E6CFF;
            font-family: 'Bold';
            cursor: pointer;
          }
        }

        .user-wallet-list {
          width: 335px;
          height: 72px;
          margin: 10px 20px 0 20px;
          background: #FFFFFF;
          border-radius: 14px;
          cursor: pointer;

          .user-wallet-list-icon {
            width: 32px;
            height: auto;
            margin: 0 10px 0 20px;
          }

          .user-wallet-list-name {
            flex: 1;
            font-size: 14px;
            color: #161B2C;
            font-family: 'Bold';
          }

          .user-wallet-list-num {
            font-size: 12px;
            color: #8F93A6;
            margin-right: 20px;
            font-family: 'Medium';
          }

        }

        .freeze {
          background: #fff;
          width: 335px;
          padding-bottom: 14px;
          margin: 0 auto;
          border-radius: 0 0 14px 14px;

          .freeze-line {
            width: 300px;
            height: 1px;
            background: #F6F6FB;
            margin: 0 auto 14px auto;
          }

          .freeze-dmd {
            font-size: 12px;
            color: #161B2C;
            margin: 0 0 4px 20px;
          }

          .freeze-msg {
            font-size: 12px;
            color: #8F93A6;
            margin-left: 20px;
          }
        }

      }

      //充值
      .wallet-recharge {
        .wallet-recharge-title {
          font-size: 16px;
          color: #222222;
          line-height: 22px;
          font-family: 'Medium';
          margin-left: 20px;
        }

        .wallet-recharge-address {
          width: 334px;
          height: auto;
          background: #EFF0F8;
          border-radius: 20px;
          margin: 20px auto 10px auto;
          line-height: 20px;
          color: #161B2C;
          font-size: 14px;
          font-family: 'Medium';
          padding: 15px 20px;
          word-break: break-word;
        }

      }

      //提现
      .wallet-withdrawal {
        position: relative;
        z-index: 1;

        .withdrawal-usdt {
          margin: 20px 20px 0 20px;

          .withdrawal-usdt-icon {
            width: 32px;
            height: 32px;
          }

          .withdrawal-usdt-name {
            font-size: 14px;
            color: #161B2C;
            margin: 0 10px;
            font-family: 'Bold';
          }

          .withdrawal-usdt-num {
            font-size: 12px;
            color: #8F93A6;
            font-family: 'Medium';
            flex: 1;
            text-align: right;
          }

          .withdrawal-select {

            width: 110px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #E8E9F0;
            backdrop-filter: blur(5px);
            cursor: pointer;
            // position: relative;
            z-index: 2;

            .withdrawal-select-icon {
              width: 22px;
              height: auto;
              margin: 0 6px 0 10px;
            }

            .withdrawal-select-txt {
              flex: 1;
              font-size: 14px;
              color: #161B2C;
              font-family: 'Bold';
            }

            .withdrawal-select-img {
              width: 8px;
              height: auto;
              margin-right: 10px;
            }

            .withdrawal-select-list {
              width: 110px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #E8E9F0;
              position: absolute;
              top: 40px;
              left: 0;
              z-index: 10;
              padding: 4px 0;

              .withdrawal-select-list-detail {
                width: 102px;
                height: 34px;
                border-radius: 6px;
                margin: 0 auto;

                .withdrawal-select-list-icon {
                  width: 22px;
                  height: auto;
                  margin: 0 6px 0 8px;
                }

                .withdrawal-select-list-name {
                  flex: 1;
                  font-size: 14px;
                  color: #161B2C;
                  font-family: 'Bold';
                }
              }
            }
          }
        }

        .withdrawal-input {
          width: 335px;
          height: 50px;
          background: #fff;
          border-radius: 20px;
          margin: 14px 20px 0 20px;

          .withdrawal-input-class {
            flex: 1;
            height: 50px;
            font-family: 'Medium';
            resize: none;
            border: none;
            margin: 0 20px;
            display: flex;
            align-items: center;

            .el-input__inner {
              flex: 1;
              height: 50px !important;
              font-size: 14px;
              font-family: 'Medium';
              resize: none;
              border: none;
              padding: 0;
              color: #161B2C;
            }
          }

          .withdrawal-input-line {
            width: 1px;
            height: 14px;
            background: #E5E4EE;
          }

          .withdrawal-input-all {
            font-size: 14px;
            color: #7E6CFF;
            font-family: 'Medium';
            margin: 0 20px;
            cursor: pointer;
          }
        }

        .withdrawal-address {
          font-size: 14px;
          color: #8F93A6;
          margin: 10px 20px 0 20px;
          font-family: 'Medium';
        }

        .withdrawal-cost {
          font-size: 12px;
          color: #8F93A6;
          margin: 22px auto 0 20px;
          font-family: 'Medium';

          span {
            color: #FF3B8A;
          }
        }
      }

      //兑换
      .wallet-exchange {
        position: relative;
        z-index: 1;

        .wallet-type {
          width: 80px;
          height: 28px;
          background: #FFFFFF;
          border-radius: 20px;
          border: 1px solid #E8E9F0;
          backdrop-filter: blur(5px);
          margin-right: 20px;
          justify-content: center;
          cursor: pointer;
          position: relative;

          .wallet-type-name {
            font-size: 14px;
            color: #222222;
            margin-right: 5px;
          }

          .wallet-type-icon {
            width: 8px;
            height: auto;
            transition: all 0.2s;
          }

          .wallet-type-ico-trans {
            transform: rotate(180deg);
          }

          .wallet-Selected {
            width: 72px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 20px;
            border: 1px solid #E8E9F0;
            position: absolute;
            top: 34px;
            right: 3px;
            z-index: 1;

            .wallet-Selected-list {
              width: 64px;
              height: 28px;
              background: #fff;
              border-radius: 6px;
              margin: 4px auto 0 auto;
              text-align: center;
              font-size: 14px;
              color: #222222;
              line-height: 28px;
              font-family: 'Medium';
              transition: all 0.2s;
            }

            .wallet-Selected-list:hover {
              color: #6930FF;
            }

            .wallet-Selected-active {
              background: #6930FF;
              color: #fff !important;
            }
          }
        }

        .exchange-list {
          width: 335px;
          height: 72px;
          background: #fff;
          border-radius: 20px;
          margin: 22px auto 16px auto;
          position: relative;

          .exchange-input {
            flex: 1;
            height: 42px;
            margin: 0 10px 0 20px;

            .el-input__inner {
              height: 42px !important;
              font-size: 16px;
              font-family: 'Medium';
              resize: none;
              border: none;
              padding: 0;
              color: #222222;
            }
          }

          .exchange-list-detail {
            text-align: right;
            font-family: 'Medium';
            margin-right: 20px;

            .exchange-list-top {
              .exchange-list-icon {
                width: 16px;
                height: auto;
              }

              .exchange-list-name {
                font-size: 12px;
                color: #222222;
                margin-left: 4px;
              }

              .exchange-list-balance {
                font-size: 12px;
                color: #C6C9D3;
              }

              .exchange-list-max {
                cursor: pointer;
                font-size: 12px;
                color: #7E6CFF;
                margin-left: 10px;
              }
            }
          }

          .exchange_icon {
            width: 24px;
            height: 24px;
            position: absolute;
            bottom: -22px;
            left: 155px;
            z-index: 1;
            cursor: pointer;
          }
        }

        .exchange-tips {
          font-size: 12px;
          color: #C6C9D3;
          margin-left: 20px;
        }

      }
    }

    .user-wallet-bg {
      width: 216px;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
    }

    .user-wallet-layer {
      width: 100%;
      height: 438px;
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;

      .user-wallet-layer-img {
        width: 80px;
        height: auto;
        margin: 180px auto 10px auto;
        display: block;
      }

      .user-wallet-layer-msg {
        font-size: 14px;
        color: #161B2C;
        text-align: center;
      }
    }

    //付费规则
    .wallet-rule-layer {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 16px;
    }

    .wallet-rule {
      width: 335px;
      height: 327px;
      background: #fff;
      border-radius: 30px;
      position: absolute;
      top: 100px;
      left: 20px;
      z-index: 20;
      padding-top: 16px;

      .wallet-rule-title {
        text-align: center;
        font-size: 16px;
        color: #161B2C;
        font-family: 'Bold';
        position: relative;

        .wallet-rule-close {
          width: 16px;
          height: auto;
          position: absolute;
          right: 16px;
          top: 0;
          cursor: pointer;
        }
      }

      .wallet-rule-detail {
        width: 303px;
        height: 198px;
        background: #F6F6FD;
        border-radius: 15px;
        margin: 12px auto 20px auto;
        padding: 16px 0;
        overflow-y: auto;

        .wallet-rule-content {
          width: 271px;
          font-size: 14px;
          color: #161B2C;
          line-height: 24px;
          margin: 0 auto;
        }
      }

      .wallet-rule-detail::-webkit-scrollbar {
        display: none;
      }

      .wallet-rule-btn {
        width: 275px;
        height: 40px;
        background: #6930FF;
        border-radius: 20px;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        color: #fff;
        cursor: pointer;
      }
    }
  }


}

.wallet-recharge-tips {
  display: flex;
  align-items: center;
  margin: 0 20px;

  .wallet-recharge-tip-icon {
    width: 12px;
    height: 12px;
  }

  .wallet-recharge-msg {
    flex: 1;
    margin-left: 6px;
    font-size: 12px;
    color: #8F93A6;
    line-height: 16px;
    font-family: 'Medium';
  }
}

.wallet-recharge-copy {
  width: 335px;
  height: 44px;
  background: #6930FF;
  border-radius: 22px;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  margin: 234px auto 20px auto;
}

.wallet-recharge-cancel {
  width: 335px;
  height: 44px;
  background: #EFF0F8;
  border-radius: 22px;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  color: #222222;
  font-size: 14px;
  margin: 0 auto;
}

.withdrawal-title {
  font-size: 16px;
  color: #222222;
  line-height: 22px;
  font-family: 'Medium';
  margin-left: 20px;
}

.exchange-layer {
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.5;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
}

//gas费
.gas-box {
  width: 400px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 8px;
  position: fixed;
  z-index: 102;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -200px;
  padding: 20px 0;

  .gas-top {
    margin: 0 20px;
    align-items: start;

    .gas-top-title {
      flex: 1;
      font-size: 16px;
      color: #1E2333;
      font-family: 'Bold';
      line-height: 26px;
    }

    .gas-top-icon {
      width: 8px;
      height: auto;
      cursor: pointer;
    }
  }

  .gas-content {
    margin: 22px 20px 18px 20px;
    font-size: 14px;
    color: #222222;
    line-height: 18px;
  }

  .gas-footer {
    margin: 0 20px;

    .gas-btn {
      height: 30px;
      font-size: 14px;
      line-height: 30px;
      padding: 0 18px;
      border-radius: 4px;
      margin-left: 10px;
      cursor: pointer;
    }

    .gas-cancel {
      color: #222222;
      border: 1px solid #9FA1AC;
    }

    .gas-confirm {
      border: 1px solid #5B38FF;
      color: #fff;
      background: #5B38FF;
    }
  }
}
</style>
