<template>
  <div v-if="languageShow">
    <div class="wallet-layer" @click="languageShow = false"></div>
    <div class="language-m">
      <div @click="setLanguage($t('zh')),close()" class="language-m-list"
        :class="{ 'language-active': $t('language.change') == 'en' }">
        <img class="language-m-icon" v-if="$t('language.change') == 'en'" src="@/assets/pc/common/cn_icon3.png" alt="">
        <img class="language-m-icon" v-if="$t('language.change') == 'zh'" src="@/assets/pc/common/cn_icon.png" alt="">
        <p class="language-m-name">中文</p>
      </div>
      <div @click="setLanguage($t('en')),close()" class="language-m-list"
        :class="{ 'language-active': $t('language.change') == 'zh' }">
        <img class="language-m-icon" v-if="$t('language.change') == 'zh'" src="@/assets/pc/common/en_icon3.png" alt="">
        <img class="language-m-icon" v-if="$t('language.change') == 'en'" src="@/assets/pc/common/en_icon.png" alt="">
        <p class="language-m-name">English</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {
  },
  props: {
    "state": { type: Boolean, default: false },
  },
  data() {
    return {
      languageShow: this.state
    };
  },
  watch: {
    state: {
      handler(newVal) {
        this.languageShow = newVal
      },
    },
  },
  mounted() {
  },
  methods: {
    close(){
      this.$emit("close")
    },
    setLanguage(val) {
      localStorage.setItem('languageStorage', val);
      // this.language = val;
      this.$i18n.locale = val;
    },
  },
};
</script>
<style scoped lang="scss">
.wallet-layer {
  width: 100%;
  height: 100%;
  background: #000000;
  box-shadow: 0px 0px 60px 0px rgba(18, 13, 37, 0.2);
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}

.language-m {
  width: 690px;
  height: 292px;
  position: fixed;
  background: #fff;
  border-radius: 50px;
  bottom: 30px;
  left: 30px;
  z-index: 91;
  padding-top: 50px;

  .language-m-list {
    width: 590px;
    height: 86px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D4D4D4;
    margin: 0 auto 20px auto;

    .language-m-icon {
      width: 28px;
      height: auto;
    }

    .language-m-name {
      font-size: 28px;
      color: #222222;
      margin-left: 10px;
    }
  }

  .language-active {
    background: #6F38FF;

    .language-m-name {
      color: #fff;
    }
  }
}
</style>