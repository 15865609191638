// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
// v2.24.0起，SDK 支持使用本地审核插件
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
// import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      clientIM: null,
      isJoining: false,
      isJoined: false,
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["ClearUserInfo"]), //登录弹窗.

    // 初始化客户端
    async initClientIM() {
      let options = {
        SDKAppID: this.sdkAppId // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
      };
      this.clientIM = TIM.create(options)
      await this.joinIM();
    },
    //进入房间
    async joinIM() {
      let that = this
      that.isJoining = true;
      !that.clientIM && await that.initClientIM();
      try {
        let promise = that.clientIM.login({ userID: that.userId, userSig: that.userSig });
        promise.then(function (imResponse) {
          console.log('登录成功-----')
          that.handleClientEventsIM()
          // console.log(imResponse.data); // 登录成功
          that.isJoining = false;
          that.isJoined = true;
          if (imResponse.data.repeatLogin === true) {
            // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
            console.log(imResponse.data.errorInfo);
          }
          that.addSpace()
          that.joinGroupIM()
        }).catch(function (imError) {
          console.log('登录失败-----', imError)
          // console.warn('login error:', imError); // 登录失败的相关信息
        });
      } catch (error) {
        that.isJoining = false;
        throw error;
      }
    },
    //退出房间
    async logoutIM() {
      let that = this
      let promise = that.clientIM.logout();
      promise.then(function (imResponse) {
        that.isJoined = false;
        that.quitGroup()
        console.log(imResponse.data); // 登出成功
      }).catch(function (imError) {
        console.warn('logout error:', imError);
      });
    },
    //加入群聊
    joinGroupIM() {
      let that = this
      let promise = that.clientIM.joinGroup({ groupID: String(that.roomId) });
      that.updateMyProfile()
      promise.then(function (imResponse) {
        switch (imResponse.data.status) {
          case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
            console.log('等待管理员同意---')
            break;
          case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
            console.log('加入群聊成功---')
            console.log(imResponse.data.group); // 加入的群组资料
            break;
          case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
            console.log('已经在群中---')
            that.isJoined = true;
            break;
          default:
            break;
        }
      }).catch(function (imError) {
        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
      });
    },
    //退出群聊
    quitGroup() {
      let that = this
      let promise = that.clientIM.quitGroup(String(this.roomId));
      promise.then(function (imResponse) {
        console.log(imResponse.data.groupID); // 退出成功的群 ID
      }).catch(function (imError) {
        console.warn('quitGroup error:', imError); // 退出群组失败的相关信息
      });
    },
    //获取群列表
    getGroupListIM() {
      let that = this
      // 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
      let promise = that.clientIM.getGroupList();
      promise.then(function (imResponse) {
        // console.log('群列表===')
        console.log(imResponse.data.groupList); // 群组列表
      }).catch(function (imError) {
        console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
      });
    },
    //发送群信息
    async createTextMessage() {
      let that = this
      let message = that.clientIM.createTextMessage({
        to: String(that.roomId),
        // to:'@TGS#2OXUDQNMN',
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          text: that.inputChat
        },
        // v2.18.0起支持群消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
        needReadReceipt: true
      });
      // 发送消息
      let promise = that.clientIM.sendMessage(message);
      promise.then(function (imResponse) {
        that.inputChat = ''
        setTimeout(() => {
          that.scrollToBottom()
        }, 100)
        // console.log('发送消息成功---')
        // 发送成功
        console.log(imResponse);
      }).catch(function (imError) {
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
    },
    //更新会员资料
    updateMyProfile() {
      // 修改个人标配资料
      let promise = this.clientIM.updateMyProfile({
        nick: '我的昵称',
        avatar: 'http://qn.soulland.info/ddc53aaa2023040314270238514.jpg',
        gender: TIM.TYPES.GENDER_MALE,
        selfSignature: '我的个性签名',
        allowType: TIM.TYPES.ALLOW_TYPE_ALLOW_ANY
      });
      promise.then(function (imResponse) {
        console.log(imResponse.data); // 更新资料成功
      }).catch(function (imError) {
        console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
      });
    },
    //获取直播在线人数
    getGroupOnlineMemberCount() {
      // v2.8.0起，支持查询直播群在线人数
      let promise = this.clientIM.getGroupOnlineMemberCount(String(this.roomId));
      promise.then(function (imResponse) {
        console.log(imResponse.data.memberCount);
      }).catch(function (imError) {
        console.warn('getGroupOnlineMemberCount error:', imError); // 获取直播群在线人数失败的相关信息
      });
    },

    handleClientEventsIM() {
      // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
      // this.clientIM.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      // tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用

      // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
      // this.clientIM.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });

      // 注册腾讯云即时通信 IM 本地审核插件
      // this.clientIM.registerPlugin({ 'tim-profanity-filter-plugin': TIMProfanityFilterPlugin });

      // 监听事件，如：
      this.clientIM.on(TIM.EVENT.SDK_READY, function (event) {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        // event.name - TIM.EVENT.SDK_READY
        // console.log('加载完毕--')
        console.log(`TIM.EVENT.SDK_READY`, event);
      });

      this.clientIM.on(TIM.EVENT.MESSAGE_MODIFIED, function (event) {
        // 收到消息被第三方回调修改的通知，消息发送方可通过遍历 event.data 获取消息列表数据并更新页面上同 ID 消息的内容（v2.12.1起支持）
        // event.name - TIM.EVENT.MESSAGE_MODIFIED
        // event.data - 存储被第三方回调修改过的 Message 对象的数组 - [Message]
        console.log(`TIM.EVENT.MESSAGE_MODIFIED`, event);
      });

      this.clientIM.on(TIM.EVENT.MESSAGE_REVOKED, function (event) {
        // 收到消息被撤回的通知。使用前需要将SDK版本升级至v2.4.0或更高版本
        // event.name - TIM.EVENT.MESSAGE_REVOKED
        // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
        console.log(`TIM.EVENT.MESSAGE_REVOKED`, event);
      });

      this.clientIM.on(TIM.EVENT.MESSAGE_READ_BY_PEER, function (event) {
        // SDK 收到对端已读消息的通知，即已读回执。使用前需要将SDK版本升级至v2.7.0或更高版本。仅支持单聊会话
        // event.name - TIM.EVENT.MESSAGE_READ_BY_PEER
        // event.data - event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
        console.log(`TIM.EVENT.MESSAGE_READ_BY_PEER`, event);
      });

      // this.clientIM.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, function (event) {
      // });
      this.clientIM.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, (event) => {
        // console.log('收到会话列表更新通知')
        // console.log(event)
        if (event.data.length < 1) {
          return
        }
        // if ((event.data[0].lastMessage.messageForShow) == 'DIRECTEDMESSAGE') {
        //   return
        // }
        event.data.forEach(item => {
          let searchString1 = "10001";
          if (item.lastMessage.messageForShow.indexOf(searchString1) !== -1) {
            let IMgame = JSON.parse(item.lastMessage.messageForShow)
            if (IMgame.errorCode == 10001) {
              this.$store.commit('updateMatching', true)
              let timer = setInterval(() => {
                this.$router.push({ path: '/game/gameFighting', query: { date: item.lastMessage.messageForShow } })
                clearTimeout(timer)
              }, 2000)
              return
            }
          }
        })
        if (event.data[0].lastMessage.messageForShow.indexOf("10002") !== -1) {
          let IMgame = JSON.parse(event.data[0].lastMessage.messageForShow)
          console.log(IMgame)
          console.log(IMgame.errorCode)
          if (IMgame.errorCode == 10002) {
            this.$store.commit('updateOccupy', IMgame)
          }
          return
        }
        if (event.data[0].lastMessage.messageForShow.indexOf("10003") !== -1) {
          let IMgame = JSON.parse(event.data[0].lastMessage.messageForShow)
          console.log(IMgame)
          console.log(IMgame.errorCode)
          if (IMgame.errorCode == 10003) {
            console.log('星球轨迹')
            this.$store.commit('updateKill', IMgame)
          }
          return
        }
        if (event.data[0].lastMessage.fromAccount.indexOf("@TIM#SYSTEM") !== -1 || event.data[0].lastMessage.messageForShow.indexOf("10001") !== -1) {
          return
        }
        if (event.data[0].groupProfile.groupID == this.roomId) {
          return
          let that = this
          let data
          let promise = that.clientIM.getUserProfile({
            userIDList: [event.data[0].lastMessage.fromAccount] // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
          });
          promise.then(function (imResponse) {
            // console.log('用户信息', imResponse.data); // 存储用户资料的数组 - [Profile]
            data = {
              txt: event.data[0].lastMessage.messageForShow,
              statu: 1,
              nick: event.data[0].lastMessage.nick,
              photo: imResponse.data[0].avatar,
              lastTime: event.data[0].lastMessage.lastTime
            }
            // console.log('消息', data)
            that.$store.commit('updateChatList', data)
          }).catch(function (imError) {
            console.warn('getUserProfile error:', imError); // 获取其他用户资料失败的相关信息
          });
          // }
        }
      });

      this.clientIM.on(TIM.EVENT.GROUP_LIST_UPDATED, function (event) {
        // 收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面
        // event.name - TIM.EVENT.GROUP_LIST_UPDATED
        // event.data - 存储 Group 对象的数组 - [Group]
        // console.log(`TIM.EVENT.GROUP_LIST_UPDATED`, event)
      });

      this.clientIM.on(TIM.EVENT.PROFILE_UPDATED, function (event) {
        // 收到自己或好友的资料变更通知
        // event.name - TIM.EVENT.PROFILE_UPDATED
        // event.data - 存储 Profile 对象的数组 - [Profile]
        console.log(`TIM.EVENT.PROFILE_UPDATED`, event)
      });

      this.clientIM.on(TIM.EVENT.BLACKLIST_UPDATED, function (event) {
        // 收到黑名单列表更新通知
        // event.name - TIM.EVENT.BLACKLIST_UPDATED
        // event.data - 存储 userID 的数组 - [userID]
        console.log(`TIM.EVENT.BLACKLIST_UPDATED`, event)
      });

      this.clientIM.on(TIM.EVENT.ERROR, function (event) {
        // 收到 SDK 发生错误通知，可以获取错误码和错误信息
        // event.name - TIM.EVENT.ERROR
        // event.data.code - 错误码
        // event.data.message - 错误信息
        console.log(`TIM.EVENT.ERROR`, event)
      });

      this.clientIM.on(TIM.EVENT.SDK_NOT_READY, function (event) {
        // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
        // event.name - TIM.EVENT.SDK_NOT_READY
        console.log(`TIM.EVENT.SDK_NOT_READY`, event)
      });

      // this.clientIM.on(TIM.EVENT.KICKED_OUT, function (event) {
      //   // 收到被踢下线通知
      //   // event.name - TIM.EVENT.KICKED_OUT
      //   // event.data.type - 被踢下线的原因，例如 :
      //   //   - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
      //   //   - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
      //   //   - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢（v2.4.0起支持）。
      //   console.log('下线--')
      //   console.log(`TIM.EVENT.KICKED_OUT`, event)
      //   this.offlineFun()
      // });
      this.clientIM.on(TIM.EVENT.KICKED_OUT, this.kickOut, this)

      this.clientIM.on(TIM.EVENT.NET_STATE_CHANGE, function (event) {
        // 网络状态发生改变（v2.5.0 起支持）
        // event.name - TIM.EVENT.NET_STATE_CHANGE
        // event.data.state 当前网络状态，枚举值及说明如下：
        //   - TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
        //   - TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
        //   - TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
        console.log(`TIM.EVENT.NET_STATE_CHANGE`, event)
      });

      this.clientIM.on(TIM.EVENT.FRIEND_LIST_UPDATED, function (event) {
        // 收到好友列表更新通知（v2.13.0起支持）
        // event.name - TIM.EVENT.FRIEND_LIST_UPDATED
        // event.data - 存储 Friend 对象的数组 - [Friend]
        console.log(`TIM.EVENT.FRIEND_LIST_UPDATED`, event)
      });

      this.clientIM.on(TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED, function (event) {
        // 收到好友申请列表更新通知（v2.13.0起支持）
        // event.name - TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED
        // friendApplicationList - 好友申请列表 - [FriendApplication]
        // unreadCount - 好友申请的未读数
        // const { friendApplicationList, unreadCount } = event.data;
        // 发送给我的好友申请（即别人申请加我为好友）
        // const applicationSentToMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_TO_ME);
        // 我发送出去的好友申请（即我申请加别人为好友）
        // const applicationSentByMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_BY_ME);
        console.log(`TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED`, event)
      });

      this.clientIM.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, function (event) {
        // 收到好友分组列表更新通知（v2.13.0起支持）
        // event.name - TIM.EVENT.FRIEND_GROUP_LIST_UPDATED
        // event.data - 存储 FriendGroup 对象的数组 - [FriendGroup]
        console.log(`TIM.EVENT.FRIEND_GROUP_LIST_UPDATED`, event)
      });

      //接收群主传递信息
      let onMessageReadByPeer = function (event) {

      }
      this.clientIM.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReadByPeer);
      // 开始登录
      // this.clientIM.login({ userID: this.userId, userSig: this.userSig });
    },








    //被踢下线
    kickOut(event) {
      // console.log('下线--')
      console.log(`TIM.EVENT.KICKED_OUT`, event)
      this.$nextTick(() => {
        this.$store.commit('updateKICKEDOUT', true)
      })
    },
  },
};
