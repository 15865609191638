module.exports = {
  language: {
    name: '中文',
    change:'en',
    text:'语言'
  },
  header:{
    nav:[{name:'精选',path:'/'},{name:'投研',path:'/deep'},{name:'快讯',path:'/news'},{name:'创作中心',path:'/create'}],
    navM:[{name:'精选',path:'/'},{name:'投研',path:'/deep'},{name:'快讯',path:'/news'}],
    // nav:[{name:'首页',path:'/'},{name:'精选',path:'/selected'},{name:'快讯',path:'/news'},{name:'创作中心',path:'/create'}],
    // navM:[{name:'首页',path:'/'},{name:'精选',path:'/selected'},{name:'快讯',path:'/news'}],
  },
  footer:{
    introduce:'汇聚于区块链行业各类数据和资讯。数据资讯快、准、全。致力于为广大区块链爱好者提供7x24小时的全球实时财经快讯。',
    name:'Soul Land Group Inc',
    aboutTitle:'关于我们',
    about:['隐私协议'],
    contactTitle:'联系我们',
    contact:['联系客服']
  },
  //首页
  home:{
    lableTitle:'推荐标签',
    appMsg:'APP内打开',
    newsTitle:'快讯',
    lodingMsg:'加载更多',
    allMsg:'查看全部',
    hotTitle:'热门文章',
    articleAll:'更多文章',
    common:'条评论',
  },
  about: {
    title: '我是中文',
  },
  //创作中心
  create:{
    manageTitle:'文章管理',
    nav:['全部','审核中','发布成功','未通过审核','草稿箱'],
    createName:'开始创作',
    createTitle:'创作中心',
    edit:'编辑',
    del:'删除',
    prompt:'投稿核审不通过，请联系',
    kefu:'客服',
    createTitle1:'文章创作',
    draft:'存入草稿箱',
    articletitle:'请输入文章标题…',
    abstract:'文章摘要（推送和分享时显示内容。一句话描述文章内容，突出核心观点，必须填写，不超过100字。）',
    articlePhoto:'文章封面',
    articlePhotoMsg:'优质的封面便于推荐，请使用清晰度较高的图片，建议尺寸：16/9图片格式支持.jpg，jpeg，png，图片大小不超过5M。',
    lableTitle:'文章标签',
    languageTitle:'发布语言频道',
    lableTitleMsg:'请选择文章所属标签（最多可同时选择五个）',
    submitText:'完成创作',
    successMsg:'恭喜您文章创作完成',
    successMsg2:'请耐心等待文章的审核，文章审核通过后，文章会自动进行发布，请您耐心等待',
    confirmText:'确认',
    state1:'发布成功',
    state2:'审核失败',
    state3:'审核中',
    msg1:'您还未创作过内容，或者删除了自己的创作内容，努力创作吧！',
    msg2:'您确定要删除这篇文章吗',
    msg3:'删除以后，该文章将不再平台内展示，您也无法对该文章进行找回，请确认后操作',
  },
  //活动
  activity:{
    time:'2023年12月21日 8PM UTC+8~2024年1月4日 8PM UTC+8',
    IntroductionTitle:'活动介绍',
    StepsTitle:'参与步骤',
    DistributionTitle:'奖励发放',
    AttentionsTitle:'活动须知',
    IntroductionTime:'2023年12月21日 8PM UTC+8~2024年1月4日 8PM UTC+8',
    IntroductionContent:'欢迎参加SoulLandXHopeCard知识探秘答题竞赛！在这场为期14天的活动中，您将有机会赢取总奖池',
    IntroductionContent3:'10000USDT和10张HopeCard',
    IntroductionContent4:'只需回答10道小白题中的6道正确，即可参与开奖，每位幸运儿将获得20USDT奖励或者1张HopeCard的V3等级账户权益。活动截止后，我们将从参赛者中随机选出500+10名幸运儿，通过下载APP后台私信方式发送奖励。还能获得额外10积分和下载APP、创建钱包等精彩体验。赶快来挑战吧！活动从12月21日开始，敬请期待。',
    StepsName1:'注册参与',
    StepsName2:'答题挑战',
    StepsName3:'下载APP和连接钱包',
    StepsName4:'领取奖励',
    StepsContent1:'用户在答题活动期间，首先需要注册SoulLand。如果尚未注册，可以在活动上线期间前往APP Store或Google Play Store下载并注册SoulLand App。也可以通过Web端线上注册成为SoulLand用户。',
    StepsContent2:'只要注册登录SoulLand官网，用户可以参与答题挑战。每套问题需要回答10道题目。用户需要在一套题中成功答对至少6道题，即并达到及格分数（60分），才有资格参与开奖，每个用户有10次机会参与答题。',
    StepsContent3:'用户完成答题后，会被引导跳转下载SoulLand App，登录ID，并创建钱包。这个步骤不仅有助于了解更多关于SoulLand的信息，还能为用户提供额外的积分和其他体验奖励。',
    StepsContent4:'达到开奖资格的答题活动参与者可以在活动结束后，获得参与开奖资格。系统会在7个工作日内，与幸运儿进行联系，获奖用户的钱包将自动领取20USDT奖励以及额外赠送Hope Card v3等级账户权益。新下载app用户还可以获得10个积分的奖励。邀请好友增加中奖几率。',
    IntroductionContent2:'活动结束后，如果答题人数达到1000人以上，将触发开奖。开奖将通过系统自动抽奖方式进行，邀请好友增加中奖几率。',
    BonusTitle:'下  载  A P P',
    BonusContent:'下载SoulLand APP、登录ID，创建钱包等，以获得更多精彩体验。',
    Eligibility:'答题条件：要有资格获得奖励，您需要正确答对至少6道题目，并下载我们的SoulLand App。我们的目标是吸引至少1000名参与者来激活奖池，因此请您积极邀请您的朋友一同参与，一场融合知识与奖励的盛宴即将拉开帷幕。',
    Duration:'活动期间：答题活动将持续14天，活动结束后，我们将对答题人数进行统计并进行系统自动抽奖，以确保公平性。请确保您在活动截止日期前完成答题，截止日期为2024年01月04日20:00。请注意，一旦截止日期过去，将无法继续答题或领取奖励。发放奖励：活动结算发奖日期，是活动截止日期后7个工作日，系统公告会自动通知中奖用户，一切获奖信息以SoulLand官方发布为准。',
    Distribution:'发放奖励：活动结算发奖日期，是活动截止日期后7个工作日，系统公告会自动通知中奖用户，一切获奖信息以SoulLand官方发布为准。',
    UserIdentity:'用户身份：已拥有SoulLand App且曾领取过积分奖励的用户不算做新用户，系统会自行识别。此外，您可以邀请多名用户参与答题，但无法邀请自己。',
    Qualification:'重复开奖资格：一旦您获得开奖资格，系统将不会再次授予您此资格，每位用户只有一次机会参与抽奖',
    FinalInterpretation:'最终解释权：活动的最终解释权归SoulLand官方所有。如有任何争议或疑问，请随时联系我们的客服团队。',
    tips:'温馨提示',
    tipsContent:'本次活动答题次数已用尽，感谢您参与SoulLand活动，请等待最终开奖结果公布，谢谢！',
    down:'点 击 立 即 下 载',
    Question:'第',
    ti:'题',
    total:'共计 10 题',
    erroeTitle:'回答错误！',
    Analysis:'答案解析：',
    correctAnswer:'正确答案',
    correct:'回答正确！',
    next:'恭喜您，开始下一题吧！',
    try:'继续努力，开始下一题吧！',
    completeTitle:'答题完成',
    completeMsg1:'本次答题，共计回答 10 道题目，答对',
    completeMsg2:'道题目，答错 ',
    completeMsg3:'道题目，共计得分：',
    completeMsg4:'恭喜您，获取抽奖资格！',
    completeMsg5:'您也可以进行再次答题（您已获取资格，再次答题不会影响到您的资格）',
    completeMsg6:'抱歉，您未获取抽奖资格！',
    completeMsg7:'您可以进行再次答题，来获取资格（每人十次答题机会）',
    completeMsg8:'我们将从参赛者中随机选出500名幸运儿，通过下载APP后台私信方式发送奖励（每位幸运儿将获得',
    completeMsg9:'奖励）。还能获得额外10SLT和下载APP、创建钱包等精彩体验。',
    completeMsg10:'下载SoulLand APP、登录ID，创建钱包等，以获得更多精彩体验。',
    complete:'完成',
    copy:'复制邀请链接',
    copyMsg:'邀请链接复制成功',
    EligibilityTitle:'活动对象',
    Rewards:"活动奖励",
    Sponsorship:"特别支持",
    EligibilityContent:"所有对SoulLand和Hope产品感兴趣的Web3用户。无论是新用户还是现有用户，只要满足答题要求，都有资格参与这个活动。",
    PrizePool:"奖金池",
    PrizePoolContent:"总奖金池为10000USDT+Hope Card V3 X10",
    Rules:"开奖规则",
    RulesContent:"活动结束后，如果答题人数达到1000人以上，将触发开奖。开奖将通过系统自动抽奖方式进行，邀请好友增加中奖几率。个人奖励：成功答对10题中的6题并达到及格分数（60分）的用户将有资格参与开奖。每位幸运儿将获得20USDT奖励或者一张V3等级Hope Card。积分奖励：新用户完成答题后，下载APP，将获得10个积分。额外体验奖励：用户完成答题后，将被引导跳转下载SoulLand APP、登录ID，创建钱包等，以获得更多精彩体验。",
    SLT:"积分奖励",
    SLTContent:"新用户完成答题后，下载APP，将获得10个积分。",
    AdditionalBonus:"额外体验奖励",
    AdditionalBonusContent:"用户完成答题后，将被引导跳转下载SoulLand APP、登录ID，创建钱包等，以获得更多精彩体验。",
    DistributionContent:"活动结束后7个工作日内开奖，幸运儿将通过官方系统通知方式告知，同时奖励会通过钱包发放，中奖用户每人获得20USDT或是1张V3等级的Hope Card，同时新用户还会获得10个积分和下载App、创建钱包等额外体验奖励",
    Individual:"个人奖励",
    IndividualContent:"成功答对10题中的6题并达到及格分数（60分）的用户将有资格参与开奖。每位幸运儿将获得20USDT奖励或者一张V3等级Hope Card。",
    Duration1:"活动期间:",
    UserIdentity1:"用户身份:",
    Qualification1:"重复开奖资格:",
    Final:"最终解释权:",
    endMsg:"活动已结束",
    startMsg:"活动未开始",
    points:"%",
  },
  // 设置中心
  set:{
    nav:['用户信息'],
    title:"设置中心",
    username:"用户名",
    Edit:"编辑",
    brief:"用户简介",
    photo:"用户头像",
    picture:"更换头像",
    msg1:"KOL认证",
    msg2:"未认证",
    msg3:"审核中请耐心等待结果",
    msg4:"申请审核失败，可在24小时后再进行认证申请",
    msg5:"认证成功，您可以下载APP进行建造公会！",
    msg6:"去认证",
    msg7:"审核中",
    msg8:"认证",
    msg9:"APP下载",
  },
  //隐私政策
  privacy:{
    title:'隐私政策',
    content1:'本隐私政策适用于 SoulLand Group Inc （“SoulLand”、“我们”或“我们的”）并解释和描述了我们如何收集、使用、披露和存储您使用我们的服务和任何其他相关的平台以及线上和线下互动过程中涉及的信息，包括但不限于任何销售、营销或活动（统称为“服务”）。',
    content55:"",
    content56:"",
    content57:"",
    content58:"",
    content2:'通过使用我们的服务，即表示您接受我们的隐私政策中描述的做法。如果您不同意本隐私政策的条款，请不要使用服务或向我们提供任何（直接或间接）识别、涉及、描述或合理地能够与特定个人相关联的信息，包括受数据保护法约束的任何信息（“个人信息”）。',
    content59:'',
    content60:'',
    content3:'请注意，如果您不提供我们请求的个人信息，或者您不同意我们处理您的个人信息，那么您可能无法使用全部或部分服务。',
    content4:'我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品或服务过程中主动提供或因使用产品或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。在您注销账号时，我们将停止使用并删除上述信息。',
    content5:'SoulLand 致力于保护通过其服务收集的信息的机密性和安全性。请仔细阅读以下内容。',
    content6:'通过我们的服务收集的任何信息均受收集此类信息时有效的隐私政策的约束。我们可能会不时修改本隐私政策。如果我们对本隐私政策做出任何重大变更，我们将通知您这些变更，例如通过在服务上发布这些变更或提供电子邮件或其他通知。如果您继续使用本服务，将被视为您已接受更新后的隐私政策。',
    content7:'个人信息',
    content8:"因情况而异，我们会根据所提供或使用服务的性质来收集个人信息，可能收集以下类别的个人信息（受适用法律和法规的约束）：",
    content9:'身份信息：如您的姓名、Web3钱包地址、区块链地址、社交媒体账号、出生日期等；如果您选择将服务链接到您的社交网络，Facebook、Google+ 或 Twitter 将根据其隐私政策和您的设置与我们分享您的社交网络中的信息。',
    content10:'联系信息：如电子邮件地址和电话号码；',
    content11:'客户记录：包括包含个人信息的电子客户记录，包括在第三方支付处理器处理付款时的记录；',
    content12:'我们与您的通信记录：包括与我们帖子的任何互动记录；',
    content13:'财务历史和详细信息：如购买和付款信息的记录；',
    content14:'位置信息：可对您地理位置进行定位的信息；',
    content15:'偏好记录：您在使用我们的服务过程中产生的包括但不限于点赞、关注、收藏、互动、搜索等偏好信息。',
    content16:'我们会以以下方式来收集有关您的某些个人信息：',
    content17:'直接来自于您自己，例如当您使用本软件、注册帐户、与我们互动、连接Web3钱包、提供您的电子邮件地址、参与交易或任务、参与活动并提出查询时；',
    content18:'来自第三方，例如我们的关联公司和其他第三方、社交媒体软件、公共记录，向我们提供服务的第三方供应商、社交网络和联合营销合作伙伴等；',
    content19:'自动收集的数据，例如当您使用本软件或与我们通信时收集的数据，或者基于您的个人信息收集有关您、您的兴趣和偏好的数据。',
    content20:'我们收集来自不同来源的信息。我们可能整合来自离线、在线和第三方来源的信息。',
    content21:'当您提供有关其他人的任何个人信息时，您表示您有权代表他们行事，并已向该人提供本声明的副本，以及我们如何使用这些信息。',
    content22:'另外，根据相关法律法规及国家标准，以下情形中，我们可能会处理你的相关个人信息而无需征求你的授权同意：',
    content23:'为个人信息处理者履行法定义务或法定职责所必需；',
    content24:'为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；',
    content25:'依法在合理的范围内处理你自行公开或者其他已经合法公开的个人信息；',
    content26:'法律、行政法规规定的其他情形。',
    content27:'我们收集设备 ID、cookie、用户设置和偏好、操作系统和应用程序版本以及技术数据，如IP地址、提交来源（Web、App、Wap）、浏览器类型和版本、时区设置和位置信息等。',
    content28:'您可以提供您要发布内容的名称、描述、简介图片、覆盖范围、博客网站等。',
    content29:'我们使用 cookie 和类似技术来收集所有访问者有关网站使用情况的信息，并帮助我们在您重新访问网站时记住您和您的偏好。这些 cookie 将来可能会保留在您的浏览器中，直到它们过期或您将其删除。有关 cookie 及其工作原理的更多一般信息，请访问 www.allaboutcookies.org。我们可能允许选定的第三方通过网站放置 cookie，用于分析或广告目的，并且他们可能会收集有关消费者在使用我们网站时在不同网站上随时间推移的在线活动的信息。',
    content30:'我们收集和处理信息的主要目的是更好的向您提供我们的服务（履行与您的合同）、改进我们的服务、管理您对服务的使用、向您推销服务，以及使您能够更好的浏览我们的服务。此外，我们可能会根据我们的合法利益（例如欺诈预防、网络和信息安全、研究，包括营销研究以及直接营销）以及在获得您的同意的情况下处理您的信息我们将通过以下一种或多种方式使用您的信息：',
    content31:'自动更新您设备上的应用程序；提高用户体验；对应用程序的用户进行数据统计和营销分析；与您就您的问题、索赔或技术支持进行沟通；与您沟通我们的政策变更，并向您发送有关我们网站和应用程序迭代的信息。',
    content32:'个性化我们服务的内容和体验，包括根据您的偏好向您提供报告、建议、广告和反馈，以及衡量和分析该广告（例如，哪些广告更有效或更有吸引力）；向您推荐我们认为您可能感兴趣的产品和服务。',
    content33:'总的来说，帮助我们评估和修改现有服务，并帮助我们开发用户可能感兴趣的其他服务；优化或改进我们的产品、服务和运营；监控质量控制并确保遵守我们的法律义务、准则和条例、政策和程序。',
    content34:'检测、调查和防止可能违反我们的政策（包括我们服务的所有适用条款）或非法的活动；识别重复访问者并促进持续访问服务；验证您或您的授权代理人的身份。',
    content35:'我们与政府和执法官员或私人团体合作，执行和遵守适用的法律和法规。',
    content36:'我们的服务不适用于18岁以下的儿童，因此我们限制年龄未满18岁或未达到相关司法管辖区法定成年年龄的人使用我们的服务。我们不会故意或有意地收集或处理与儿童相关的个人信息。如果我们得知儿童向我们提供了个人信息，我们将尽快采取措施删除此类个人信息。',
    content37:'对于未满14周岁的未成年人，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。',
    content38:'提供给SoulLand的所有个人信息将由授权人员进行安全存储，仅限授权人员访问。我们使用加密和安全软件对数据传输进行加密，以安全格式加密您的个人信息，确保其隐私和安全，防止未经授权的访问或披露、意外丢失、更改或破坏。您的个人信息的安全对我们很重要，但请理解，互联网上的传输方法并不具备完全安全的保证。虽然我们努力采用商业上可接受的手段保护您的个人信息，但我们无法保证其绝对安全。',
    content39:'如果我们将您的个人信息转移到您所在国家/地区以外的国家，我们将采取措施，以符合您所在国家/地区根据适用法律和法规的要求进行此类转移。',
    content40:'一旦不再需要您的个人信息，我们将停止保留您的个人信息，或删除、去标识化这些个人信息。根据适用的法律和法规，我们保留收取合理费用以处理任何数据请求的权利。我们将采取合理的措施，确保我们持有的个人信息的准确性。如果我们得知个人信息不准确，我们将不使用该数据，并将告知已转移此类个人信息的任何第三方。',
    content41:'我们在遵守“合法正当、最小必要、目的明确原则”的前提下共享您的个人信息。同时，我们将对个人信息的共享活动事先进行个人信息保护影响评估，对输出形式、流转、使用等采取有效的技术保护措施。在合作协议层面，严格要求合作伙伴的信息保护义务与责任。',
    content42:'我们不会与本公司之外的任何第三方共享您的信息，但以下情形除外：',
    content43:'事先获得您明确的同意或授权。',
    content44:'根据适用的法律法规、法律程序的要求、强制性的行政或司法要求对外共享您的信息。',
    content45:'只有共享您的个人信息，才能实现我们的产品和服务的核心功能或提供您需要的服务。',
    content46:'为实现我们向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账户与交易安全等，我们可能会将您的个人信息与我们的关联方共享。我们将会根据本隐私政策的约定与我们的关联方共享您的个人信息，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。         ',
    content47:'我们可能会向合作伙伴等第三方共享您的信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。',
    content48:'基于学术研究而提供。',
    content49:'根据与您签署的单项服务协议或其他的法律文件约定所提供。',
    content50:'每一方（i）放弃其在法院解决本协议引起或与之相关的任何和所有争议、索赔、诉讼、行动、起诉原因、要求或程序（统称为“争议”）的权利，以及（ii）放弃其在法院审理任何争议的权利。相反，各方应通过具有约束力的仲裁对争议进行仲裁（即将争议提交给一名或多名负责审查争议并做出最终且具有约束力的解决决定的人员，而不是由法官或陪审团在法庭处理争议）。',
    content51:'因本协议引起或与本协议相关的任何争议均属于您个人，只能通过个人仲裁解决，在任何情况下都不得作为集体仲裁、集体诉讼或任何其他类型的代表性诉讼提起。不会有集体仲裁或以一方实体试图解决他人或一组个人的争议的仲裁。此外，无论在仲裁内还是在仲裁外，均不得提起集体或其他类型的代表性诉讼，也不得代表任何其他个人或个人团体提起诉讼。',
    content52:'任何因本协议而产生或与之相关的争议（包括但不限于本条款的效力、存在、有效性或终止等问题）应提交给并最终由新加坡国际仲裁中心（"SIAC"）根据当时有效的新加坡国际仲裁中心仲裁规则（"SIAC规则"）进行仲裁，这些规则被视为本条款的一部分。仲裁地点应为新加坡。仲裁庭将由1名仲裁员组成。仲裁语言应为英语。',
    content53:'仲裁员无权进行集体仲裁或代表性或集体诉讼，这在本协议中是被禁止的。仲裁员只能进行个人仲裁，不能合并超过一名个人的索赔，也不能主持任何类型的集体或代表性程序，也不能主持涉及多个个人的程序。',
    content54:'如果本条的任何条款、子句或规定被认定为无效或不可执行，将最小程度适用和法律所要求的方式予以认定，而本条的所有其他条款、子句和规定将保持有效且可执行。',
    title1:'本隐私政策的修订',
    title2:'我们收集的信息',
    title3:'设备相关信息',
    title4:'您作为内容提供商时提供的信息',
    title5:'Cookies',
    title6:'我们如何使用您的信息',
    title7:'为您提供服务',
    title8:'广告定制与衡量',
    title9:'内部研发',
    title10:'安全检测、保护和执行；功能调试、错误修复',
    title11:'法律义务',
    title12:'儿童隐私',
    title13:'安全',
    title14:'个人信息的转移',
    title15:'保留',
    title16:'共享',
    title17:'争议解决',
    title18:'仲裁 请仔细阅读以下条款，因为其中包含某些规定，例如具有约束力的仲裁条款和集体诉讼豁免条款，它们影响您的法律权利。此条款要求您通过仲裁解决某些争议和索赔。',
  },
  //登录注册
  login:{
    log:'登录/注册',
    Forget:'忘记密码',
    passwardLog:'账号密码登录',
    emailLog:'邮箱验证码登录',
    register:'账号注册',
    welcome:'欢迎来到Soul Land,请登录您的帐号',
    placeholder1:'请输入您的邮箱',
    getCode:'获取验证码',
    again:'后重新获取',
    placeholder2:'请输入您的邮箱验证码',
    placeholder3:'请输入您的密码',
    placeholder4:'请输入您注册的邮箱地址',
    placeholder5:'请输入密码',
    placeholder6:'请输入您的邀请码',
    logIn:'登 录',
    msg1:'登录/注册即代表您已阅读并同意',
    msg2:'注册Soul Land，精彩纷呈立马为您呈现',
    msg3:'请在下方填写一个全新的邮箱来进行注册',
    msg4:'请在下方输入您的注册邮箱，我们将会给您发送一条验证码',
    msg5:'我们已经为邮箱为',
    msg6:'发送了一条验证码，请输入正确的验证码',
    msg7:'请设置您的密码，并且牢记密码',
    msg8:'现在您可以设置新的密码了',
    msg9:'恭喜您，账号已经注册完成！',
    msg10:'新密码已设置成功，请您牢记您新的密码',
    find:'密码找回',
    signUp:'注册',
    next:'下一步',
    Completed:'完成',
    set:'设置',
    logOut:'退出登录',

  },
  //公用
  common:{
    Refresh:'后刷新',
    retuen:'返回',
    Confirm:'确认',
    download:'下载',
    msg1:'全球首款元宇宙资讯社交平台',
    msg2:'声明：本文由Soul Land的作者撰写，观点仅代表作者本人，绝不代表Soul Land赞同其观点或证实其描述。',
    msg3:'提示：投资有风险，入市需谨慎。本资讯不作为投资理财建议。',
    Comments:'评论',
    Comment:'评论',
    Author:'作者',
    Delete:'删除',
    Reply:'回复',
    CommentAll:'查看更多评论',
    CommentAll2:'全部评论',
    Share:'分享',
    time:'发布时间',
    Cancel:'取消',
    understood:'知道了',
    Submit:'提交',
    noMore:'暂无更多',
    Source:'来源',
    search:'搜索',
    floolw:'关注',
    say:'说点什么吧...',
    understoodBtn:"我同意",
    downloadMsg:'敬请期待',
    Intro:"简介",
    enter:"请输入内容...",
    logoutMsg:"您确定要退出登录吗?",
    delMsg:"删除成功",
    SLT:'DMD：DMD用于加入付费公会和购买推送卡时使用，DMD需要充值购买，1USDT=1DMD。SLT：SLT是生态内积分，用户可以通过参与平台内任务（签到、答题、邀请新用户、参与战舰玩法等）来获取SLT积分激励。SLT可用于进普通公会、升级公会等级、在商城内购买道具、抽奖、参与战舰升级和星域挑战等。',
    transfer:'用户从平台钱包向其他钱包转账时，需要根据链上情况收取Gas费。请确保在同一网络上进行转账，否则该交易不会成功。 SoulLand 对因个人误操作而造成的任何损失或后果不承担任何责任。',
    pay:'1.付费公会的费用由会长自行决定，平台不参与制定会费标准。2.用户在加入付费公会之前需要充分了解公会的详细信息，包括但不限于会费数额、支付频率、接受的付款方式等。3.会长应制定规则，维护公会社区的秩序以及和谐氛围，防止任何形式的滥用或不当行为。4.用户与任何第三方之间达成的任何一致平台均不承担任何责任。',
    
  },
  // KOL认证
  Kol:{
    state1:'KOL未认证',
    state2:'KOL认证',
    msg1:'您还未认证为KOL，暂不能使用创作中心，请先进行 KOL认证',
    msg2:'您的KOL认证正在审核中，请耐心等待',
    msg3:'您的KOL认证失败，请重新提交认证信息或联系客服',
    msg4:'恭喜您！KOL认证已通过！',
    apply:'去认证',
    placeholder1:"请输入您的网络昵称",
    placeholder2:"您擅长的领域",
    placeholder3:"您的推特",
    placeholder4:"您的Telegram",
    placeholder5:"请输入您具体的经历",
    placeholder6:"联系方式",
    placeholder7:"其他平台链接",
    placeholder8:"粉丝数量",
    userName:"编辑用户名",
    msg5:"请勿使用包含特殊符号或含有明显营销推广意图的用户名，谢谢您的配合！",
    save:"保存",
    brief:"编辑用户简介",
    msg6:"要求内容完整通顺，无特殊符号，请勿添加任何联系方式如微博、手机号、推特等。",
    placeholder9:"请输入您的用户简介",

  },
  download:{
    title:'全球第一款元宇宙社交资讯平台',
    title2:'开启Wbe3.0新的时代',
    title3:'App Store下载',
    title4:'谷歌商店下载',
    title5:'APK下载',
    title8:'APK下载',
    title6:'(CN站下载)',
    title7:'(EN站下载)',
    title9:'下载国际版本，适用于非受限的全球用户下载',
    title10:'下载优化版本，适用于部分IP限制用户下载',
  },
  //钱包
  wallet:{
    tips:'提 示',
    msg:'钱包功能正在完善优化中敬请期待',
    understood:'我知道了',
    Swap:'兑换',
    Balance:'余额',
    Balance2:'可兑换余额',
    Maximum:'最大值',
    msg1:'提示：若USDT余额不足，请到钱包内进行USDT充值',
    msg2:'已经充值成功，您可以在你的钱包中查看余额',
    msg29:'兑换已经发起成功，请等待审核，通过后，您可以在你的钱包中查看余额',
    msg3:'请输入兑换数量',
    msg4:'兑换数量不得超过最大值',
    msg5:'您当前的gas费不足，该笔交易所需gas费约为',
    msg6:'USDT,您是否需要兑换？',
    msg66:',您是否需要兑换？',
    msg7:'笔gas费交易正在链上，您是否需要再次兑换？',
    msg8:'您有',
    msg9:'gas费正在兑换中...',
    msg10:'仅在 BSC 链上使用此地址进行充值，否则资产可能丢失！',
    msg11:'暂无您的历史账单记录',
    msg17:'钱包功能未解锁',
    Quantity:'数量',
    Completed:'成功',
    consume:'消耗金额',
    again:'再次兑换',
    Attention:'提示',
    wait:'等待',
    Wallet:'钱包',
    Total:'总资产',
    mineTotal:'我的资产',
    Withdraw:'提现',
    Address:'钱包地址',
    placeholder1:'请输入钱包地址',
    placeholder2:'请输入验证码',
    Paste:'粘贴',
    emailAddress:'邮箱地址',
    Transaction:'预估交易费用',
    History:'历史账单记录',
    navList: [{name:'充值'},{name:'提现'},{name:'兑换'},{name:'收入'},{name:'支出'}],
    completed1:'充值成功',
    completed2:'提现成功',
    completed3:'兑换成功',
    Failed1:'充值失败',
    Failed2:'提现失败',
    Failed3:'兑换失败',
    Waiting1:'充值中',
    Waiting2:'提现中',
    Waiting3:'兑换中',
    Details1:'充值详情',
    Details2:'兑换详情',
    Details3:'提现详情',
    Failed4:'失败',
    Waiting4:'交易中',
    Date:'交易日期',
    Hash:'交易哈希',
    Address2:'地址',
    Mainnet:'主网络',
    msg12:'已经兑换成功，您可以在你的钱包中查看余额',
    msg13:'已经提现成功，您可以在你的钱包中查看余额',
    Consumption:'消耗数量',
    Address3:'充值地址',
    Copy:'复制地址',
    msg14:'请输入提现数量',
    msg15:'提现数量不得超过最大值',
    msg16:'gas费不足',
    Guild:'公会费用',
    Mall:'商城费用',
    msg18:'新入账的DMD，15天后自动解除冻结',
    msg19:'是否开通钱包功能',
    msg20:'我的钱包',
    msg21:'长按粘贴钱包地址',
    fee:'手续费',
    received:'实际到账',
    msg22:'敬请期待',
    msg23:'最少兑换100SLT',
    msg24:'兑换审核中情耐心等待',
    msg26:'兑换审核已通过',
    msg27:'兑换审核已驳回',
    msg28:'可兑换金额不足',
    msg25:'每个账号限兑换一次',
    rule:'1.每个账号仅限兑换1次，最小兑换金额100slt。 2.兑换比例：每次需要燃烧提现slt总额的50%，并扣除20slt+5%的手续费。 3.注意：本兑换不受BP金额影响。 4.本次兑换以2024年6月1日0点前的slt数量为准'
  },
  error:{
    msg1:"邮箱格式有误",
    msg2:"验证码发送成功",
    msg3:"请输入您的邮箱",
    msg4:"请输入您的邮箱验证码",
    msg5:"登录成功",
    msg6:"请输入6位验证码",
    msg7:"两次输入密码不一致!",
    msg8:"密码必须由不少于8位不大于16位字符的英文加数字组成",
    msg9:"注册成功",
    msg10:"密码找回成功",
    msg11:"请输入您的密码",
    msg12:"请输入文章标题",
    msg13:"请输入文章摘要",
    msg14:"请输入文章内容",
    msg15:"请上传文章封面图",
    msg16:"请选择文章标签",
    msg17:"请选择文章发布语言频道",
    msg18:"编辑成功",
    msg19:"草稿保存成功",
    msg20:"请选择",
    msg21:"保存成功",



  },
  cropper:{
    rotate1:"逆时针旋转",
    rotate2:"顺时针旋转",
    Upload:"上传"
  },
  //新年任务活动
  newYear:{
    time:'活动时间：2024年1月5日 20:00 - 2024年1月19日 20:00',
    rule:'所有任务均在APP内完成，点击登录查看排名',
    userMsg1:'您好，',
    userMsg2:'当前积分',
    userMsg3:'，我的排名',
    userMsg4:'暂无排名信息 -- ',
    out:'退出',
    taskTitle:'任 务 详 情',
    taskTitle2:'个人任务：',
    taskList1:'1.练习和答题：练习不设次数，但只有答对1题才能获得1分。答题一天两次，答对1题得1分。',
    taskList2:'2.加入公会： 不设上限，用户可以随时加入新的公会，加入一个公会获得2分。',
    taskList3:'3.发表评论（公会和文章都可以）： 每天的评论上限为5条，一条1分，评论字数不少于5个字符。答满5条一次性发放5分。',
    taskTitle3:'社交任务：',
    taskList4:'1.分享公会文章或快讯或推文：分享一次获得2分。',
    taskList5:'2.邀请好友：不设上限，邀请一个好友可获得5分。',
    taskTitle4:'奖 励 发 放',
    taskList6:'我们将会在2024年1月19日20:00锁定排名',
    taskList7:'奖励将于7个工作日内发放',
    taskList8:'排名前',
    taskList9:'名的参与用户每人将获得',
    taskList10:'SLT，前',
    taskList11:'名的用户每人将额外空投一个',
    taskList12:'稀有性NFT',
    taskList13:'，这些NFT可以在SoulLand后续活动中使用。',
    taskTitle5:'活动提醒',
    taskList14:'活动解释权归SoulLand所有，',
    taskList15:'包括活动规则、分数计算、奖励发放等',
    taskList16:'。我们会对获奖用户进行审核，若被判定为机器人，或存在不良行为，一经发现，即取消获奖资格，获奖名额将顺延给其他合规用户。',
    taskTitle6:'下 载 A P P',
    taskList17:'下载SoulLand APP开启精彩活动！',
    taskList18:' 提示：',
    taskList19:'以上分数用于记录排名，与SLT无关。',
    msg:'向上滑动收起榜单',
    msg2:'正在虚位以待中…',
    download:'立即下载',
  },
  //龙年活动
  loong:{
    Details:'活动详情',
    time:'活动时间：2月7日早上10点-2月17日早上10点结束',
    residue:'剩余抽奖次数',
    residue2:'次',
    getResidue:'获取抽奖次数',
    award:'抽奖',
    content1:'为热烈庆祝龙年来临，SoulLand 为大家准备了惊喜满满的盲盒狂欢活动！',
    content2:'登录SoulLand账户，绑定推特并分享，即刻解锁盲盒大门！',
    content3:'盲盒内包括SLT、NFT、推送卡、喇叭、USDT等，超多大奖等你来拿！',
    content4:'邀请好友一起加入，机会多多！',
    content5:'',
    content6:'分享邀请链接到推特，每邀请5个新用户则增加一次抽奖机会。邀请用户越多，抽奖机会越多，越能抽中战舰NFT！',
    content7:'分享活动，狂欢不止于此！',
    content8:'将邀请链接和活动文案分享给更多朋友，不仅能让你的盲盒机会翻倍，还能开启更多惊喜！',
    content9:'立即参与，解锁盲盒，开启属于你的龙年狂欢！',
    content10:'活动提醒：',
    content11:'活动解释权归SoulLand所有， 包括活动规则、奖励发放等 。我们会对参与用户进行审核，若被判定为机器人，或存在不良行为，一经发现，即取消获奖资格。',
    content12:'获取抽奖资格',
    content13:'绑定您的推特账号',
    content14:'立即绑定',
    content15:'已绑定',
    content16:'分享到您的推特',
    content17:'立即分享',
    content18:'已分享',
    content19:'每邀请',
    content20:'个新用户则增加一次抽奖机会。邀请用户越多，抽奖机会越多，越能抽中',
    content21:'战舰NFT',
    content22:'活动分享链接：',
    content23:'复制',
    content24:'以下是你的邀请链接，只计算通过该邀请链接邀请的新用户，其他链接无效',
    content25:'我的邀请码：',
    content26:'当前累计受邀请人数',
    content27:'人，共计获得抽奖次数 ',
    content28:'次',
    content29:'盲盒抽奖',
    content30:'恭喜您获得',
    content31:'请先绑定您的推特账号',
    content32:'刷新成功',
    content33:'抽中的奖品将发送到对应的账号和背包  ',
    content34:'请获取更多抽奖次数  ',
    content35:'绑定成功',
    content36:'绑定失败',
    
  },
  //游戏规则
  gameRule:{
    title:'战舰战斗规则',
    content1:'1.选择战舰和星域',
    content2:'拥有NFT的用户即可参与星域挑战玩法',
    content3:'在进入后，点选下方选择战舰位置，选择自己想要参加挑战的飞船',
    content4:'选择后，用户可以选择下方不同slt作为损管费，进入相对应的星域',
    content5:'消耗slt如下',
    content6:'(1)初级星域 10slt',
    content7:'(2)中级星域 30slt',
    content8:'(3)高级星域 50slt',
    content9:'(4)魔鬼星域 100slt',
    content10:'(5)末日星域 200slt',
    content11:'(6)深渊星域 500slt',
    content12:'进入目标星域时，会自动扣除固定的slt',
    content13:'2.战斗',
    content14:'用户进入目标星域后，等待战斗开始',
    content15:'战斗开始后，开启30秒倒计时，用户可以在30秒内，自行选择不同的星球房间，一经选定，不能再次选择',
    content16:'倒计时结束时，如果有用户没有选择星球房间，系统会自动将剩余用户随机分配到房间内',
    content17:'分配结束后，boss出现，boss会在星球之间来回走动，扫描各个星球，随机挑选1个星球进行挑战',
    content18:'根据战力值，触发通杀/幸存/失败3个结果',
    content19:'(1)打败boss判定为通杀',
    content20:'(2)战斗存活，判定幸存',
    content21:'(3)被boss打败，判定战斗失败',
    content22:'胜负判定后，本次战斗结束，进入战斗结算',
    content23:'战斗结算：',
    content24:'(1)当判定结果为通杀时，即boss攻击用户，boss死亡，该名击败boss的用户获得扣除手续费后的该10人星域的损管费slt总和',
    content25:'(2)当判定结果为幸存，即boss攻击非最高战力用户，该名用户死亡，其余用户用户计为幸存，平分扣除手续费后的该10人星域的损管费slt',
    content26:'(3)当判定结果为失败，失败用户不会获得任何奖励',
  },
  game:{
    Rules:'游戏规则',
    Leaderboard:'历史榜单',
    costs:'损管费',
    SpaceFighter:'请选择您的战舰',
    Change:'更换战舰',
    Choose:'选择战舰',
    Level:'级',
    Galaxy:'请选择星域',
    Start:'开始探索',
    Choose2:'选择',
    Injecting:'注入燃料中',
    Injecting2:'注入燃料完毕',
    speak:'请发言',
    Countdown:'倒计时',
    Choose3:'我选择的',
    txt1:'请您选择希望登陆的星球！',
    txt2:'您是要选择',
    txt3:'号星球登陆吗（确定后无法再次更改）？',
    txt4:'本局游戏结束',
    txt5:'很遗憾，本次战斗您不幸战败，请重整旗鼓，再行挑战吧！',
    txt6:'恭喜您，幸存了下来！',
    txt7:'恭喜您，成为本局的唯一胜利者！',
    Rewards:'奖励',
    Back:'返回',
    Battle:'历史战场',
    Total:'总积分',
    Weekly:'周积分',
    Monthly:'月积分',
    Yearly:'年积分',
    Battles:'总场次',
    Wins:'胜利场次',
    Defeat:'击败boss',
    Invincible:'最强战舰',
    Defeats:'失败场次',
    Performance:'星球战绩',
    Location:'位置',
    Appearances:'场次',
    Rate:'胜率',
    Planet:'号星球',
    times:'次',
    download:'下载APP',
    txt8:'下载APP获取更多SLT',
    invite:'邀请好友',
    tip:'消息提示',
    tipContent1:'该段位匹配人数较少，时间较长，请选择其他星域匹配战斗',
    tipContent2:'您当前正在游戏中，请稍后再试。',
    tipContent3:'正在匹配中，是否退出',
    available:'暂未开放',
    txt9:'进入战斗序列需要',
    txt10:'slt作为战斗损管费，是否消耗？请勿在游戏中刷新，以免造成数据丢失。',
    txt11:'选择星球',
    txt12:'本场游戏战绩',
    txt13:'号星球',
    txt14:'',
    txt15:'幸存',
    txt16:'失败',
    txt17:'通杀',
    txt18:'很遗憾，本次战斗您不幸战败，请重整旗鼓，再行挑战吧！',
    txt19:'奖励您',
    room:'房间',
    popularity:'当前星域热度',
    txt20:'本场战斗',
    txt21:'玩家完成了通杀，独得',
    txt22:'slt！',
    txt23:'已经选择了',
    txt24:'号星球',
    txt25:'请选择您的战舰',
    txt26:'掠夺者攻击用户，掠夺者死亡，该名击败掠夺者的用户获得扣除手续费后的该10人星域的损管费slt总和；掠夺者攻击用户战舰，该名用户被击败，其余用户用户计为幸存，平分扣除手续费后的该10人星域的损管费slt，被击败的用户判定结果为失败，不会获得任何奖励',
    txt27:'由于游戏中途刷新，您当前账号数据仍在游戏中，请耐心等待游戏结果',
    txt28:'您已经登陆了',
    txt29:'请您耐心等待游戏开始。',
    txt30:'暂未有玩家登陆，您要登陆吗？（登陆后无法再次更改）',
    txt31:',能源不足以航行到',
    txt32:'已被其他玩家抢先登陆了！请您选择其他星球登陆。',
    txt33:'星球详情',
    txt34:'登陆',
    txt35:'已登陆',
    txt36:'扫一扫',
    txt37:'准备探索',
    txt38:'查看SLT',
  },
  BP:{
    txt1:'参与星际挑战赛，每消耗',
    txt4:'slt损管费，获得',
    txt5:'BP，首次发起slt兑换，需要最低',
    txt6:'BP,兑换平台slt可获得等额BP',
    txt2:'最大可提金额',
    txt3:'当前可提余额'
  },
  star:{
    title:'星球详情',
    Planet1:{
      name:'尤塔帕',
      detail:'尤塔帕(Utarpa)是一颗被许多卫星环绕的行星，是遥远的避难所，表面布满巨大的天坑。其狂风大作的表面干燥如沙漠，但在多孔渗水的地壳深处，每个天坑底部都有一堂堂可支持生命的水。这颗行星有不少小型大陆。在其中一块大陆上，很多天坑内都排列着复杂的城市。这些城市延伸进地表以下的洞穴和岩缝深处。就是在这个星球上，战争无时无刻不在进行着，也就是在这个星球上，丰富的地下资源也吸引着越来越多探索的财阀和强盗。'
    },
    Planet2:{
      name:'霍斯尼亚',
      detail:'霍斯尼亚（Holessinia）主行星是一个被不断扩张的城区覆盖的城市星球，位于银河系的传统富裕区--核心世界。从德卡出发，向北航行，走科雷利亚航程或海迪亚航路皆可，通过中环、扩张区域和内环，直到迪南。横穿至科雷利亚贸易之脊，进入核心世界，即可抵达霍斯尼亚星系，其中的霍斯尼亚主行星即共和国议会临时所在地。科雷利亚贸易之脊向北延伸至科雷利亚和杜罗，这一区域的行星通常经济发达、影响力大。'
    },
    Planet3:{
      name:'卡希克',
      detail:'卡希克(Kaxic)是个战略要地，它是银河系整个西南象限的通航门户。卡希克的生态系统很独特，表现为分层的生物多样性。这颗行星的森林从上往下分成几个不同的生态层，越往下就越危险。这个星球的智慧种族伍基人占据着最上面一层林冠层。在内陆，有些低层已经上千年没有接触阳光了，而且充满了致命的原始生物。伍基人的文明则把森林分成七个垂直层。胜利是短暂的。随着银河帝国的崛起，帝国士兵控制卡希克，对这颗行星实施了戒严。'
    },
    Planet4:{
      name:'霍斯',
      detail:'霍斯(Holes)是遥远的同名星系的第六颗行星。它是一个被许多卫星环绕的冰雪世界。从附近小行星带游离出的陨石不断轰击霍斯的冰雪表面。这个冰雪世界孕育了不少本土生命形式，包括万帕冰兽和汤汤。霍斯的白天气温对身穿合适御寒衣物的人类来说尚可忍受，但霍斯的黑夜即使对当地生物来说都过于恶劣。霍斯勉强适宜居住，其转轴倾角、轨道位置和大气组成导致这个世界的温度跌至零下。霍斯的白天气温升高到-32标准度左右。晚上，气温降至-60度，大风甚至让人感觉更冷。'
    },
    Planet5:{
      name:'纳布',
      detail:'纳布（Naboo）是纳布星系的第三颗行星。这个星球是一个田园诗般的世界，星球上居住着以和平而闻名的纳布人，以及聪明的两栖类土著生物刚嘎人。纳布气候温和，表面由沼泽的湖泊、起伏的旷野和苍翠的小山组成。其人口聚居地非常美丽纳布的河流城市被古典建筑和温室装点得分外辉煌，而位于水下的刚嘎人居住地则是一种富有异国情调的静水力学气泡技术的美丽展示。'
    },
    Planet6:{
      name:'恩多',
      detail:'气体星球恩多(Enndoo)隐藏在外围星系的版图里。恩多编码名为避难所卫星。在太空的黑暗的背景下，恩多就像一颗紫色的宝石。特殊大气影响下，产生了独特的紫色生态链，河流在紫色的遮盖下蜿蜒流动，森林在肥沃的特殊白土和低矮的山脉上高高耸立。这颗卫星物种繁多，智慧生物包括伊沃克人和他们的远亲杜洛克人。'
    },
    Planet7:{
      name:'达戈巴',
      detail:'达戈巴(Dargbar)位于外环星域遥远的斯卢伊斯星区，距离银河系核心50000光年。它是被浓雾笼罩的沼泽星球，在任何现代的星图上都找不到，是一个被遗忘的世界，当银河系逐渐纷争的时候，它就成为旅人和避难者的隐居地。这个星球被粗糙的树木和恶臭的沼泽所包围。巨大的泄湖成为了可怕的蛇类和其他生活在黑暗的水域中的生物的家园。'
    },
    Planet8:{
      name:'卡米诺',
      detail:'卡米诺(Kamino)是一个在外围星系以外的孤独世界，位于哲人迷宫的南方。是一个充满汹涌海洋和无尽风暴的星球。只有少数部分突出在洋面之上，这就是那些巨大的高脚城市，里面居住着这个星球的原住民，卡米诺人。卡米诺未能从全球变暖导致内陆巨大冰层融化的灾难中恢复过来。它的海洋迅速膨胀，覆盖了大陆。卡米诺星球存在的全部证据仅储存于完善的档案馆中，然而现在它已经被完全是个谜了。'
    },
    Planet9:{
      name:'吉奥诺西斯',
      detail:'吉奥诺西斯（Geonosis）是一颗带有行星环的严酷岩石星球，位于银河共和国边界外，距离塔图因不到一个秒差距。其缺少生机的表面到处是台地、山丘和大片贫瘠炎热的沙漠硬土。这里的天空和岩石都泛着红色，在吉奥诺西斯进化的生物都有在严酷生态环境下生存的本领。这里最高级的生命形式是吉奥诺西斯人——这种有智慧的昆虫形生物居住在高耸的尖塔巢穴内。吉奥诺西斯人经营着生产机器人和武器的大型工厂。除了接待敢于在这颗星球下订单的客户，吉奥诺西斯人通常不与外界接触。'
    },
    Planet10:{
      name:'塔图因',
      detail:'塔图因(Tatooine)，属于星系外层空间的阿卡尼斯区域，是一颗围绕着一个双星系统运动的行星。它是一颗巨大的沙漠星球，塔图因贫瘠荒凉的地表演化出多样化的地形。从宇宙空间远处望去，塔图因的外观由于沙子含钠量高呈现出发亮的亮黄色，长久以来这一特点使很多人误以为它是一颗暗色的恒星，直到超空间引擎的发明使人类得以近距离接触了它。起初一般人都不会相信这样一颗环境恶劣的行星会有生命存在，但事实上由于处在一个双星系统轨道的特殊位置，塔图因形成了一种稳定的气候。尽管干燥炎热的塔图因的表面水覆盖不到1%，在北半球一块相对适宜居住的区域内仍然有多样的物种在繁衍生息。'
    },
  },
  logout:'您的账号在别处登录,您已被踢出SoulLand app，请确认是否本人操作，如非本人操作，为了您的账号安全，请及时修改密码。',
  chat:'欢迎来到SoulLand，作为全球首个游戏化社交订阅超级应用，SoulLand旨在引导全球Web3爱好者和社区拥抱区块链的未来。让我们共同在元宇宙中探索如何建立个人品牌，并分享链上交易经验、寻找热点概念、在游戏中探索Web3世界的无限可能。',
  invite:{
    txt1:'紧张又刺激的战舰PVP游戏，和我一起来赚SLT!',
    txt2:'游戏链接：',
    txt3:'当前PC端暂未上线',
    txt4:'请使用手机浏览器打开该网址',
    txt5:'进入官网',
  }
};