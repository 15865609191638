<template>
  <div v-if="state">
    <div class="exchange-layer" @click="close"></div>
    <div class="exchange">
      <div class="exchange-title">
        <!-- @click="checkConversion()" -->
        <p style="font-family: 'Medium';">{{ $t('wallet.Swap') }}</p>
        <!-- <div v-if="changeType == 2">
          <img @click="checkConversion()" class="exchange-icon" src="@/assets/pc/wallet/change_icon.png" alt="">
        </div> -->
        <div style="flex: 1;"></div>
        <img @click="close" class="exchange-close" src="@/assets/m/wallet/close_icon.png" alt="">
      </div>
      <!-- SLT切换 -->
      <div v-if="changeType == 2" class="flex">
        <div class="exchange-nav flex">
          <div class="flex" @click="exchangeNavState = !exchangeNavState">
            <img v-if="exchangeNavIndex == 0 || exchangeNavIndex == 2" class="exchange-nav-icon"
              src="@/assets/m/wallet/wallet_icon2.png" alt="">
            <img v-if="exchangeNavIndex == 1" class="exchange-nav-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
            <p class="exchange-nav-txt">SLT</p>
            <img class="exchange-nav-img" src="@/assets/pc/wallet/exchange_icon4.png" alt="">
            <img v-if="exchangeNavIndex == 0" class="exchange-nav-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
            <img v-if="exchangeNavIndex == 1" class="exchange-nav-icon" src="@/assets/m/wallet/wallet_icon2.png" alt="">
            <img v-if="exchangeNavIndex == 2" class="exchange-nav-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
            <p v-if="exchangeNavIndex == 0 || exchangeNavIndex == 1" class="exchange-nav-txt">SLT</p>
            <p v-if="exchangeNavIndex == 2" class="exchange-nav-txt">SLC</p>
            <img class="exchange-nav-img2" src="@/assets/pc/wallet/wallet_change_icon.png" alt="">
          </div>
          <transition name="el-zoom-in-top">
            <div v-show="exchangeNavState" class="exchange-nav-detail">
              <div class="exchange-nav-list flex" :style="{ background: 0 == exchangeNavIndex ? '#F4F5F9' : '#fff' }"
                @click="exchangeNavIndex = 0, exchangeNavState = !exchangeNavState, exchangeNum = '', exchangeNum2 = ''">
                <img class="exchange-nav-icon" src="@/assets/m/wallet/wallet_icon2.png" alt="">
                <p class="exchange-nav-txt">SLT</p>
                <img class="exchange-nav-img" src="@/assets/pc/wallet/exchange_icon4.png" alt="">
                <img class="exchange-nav-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
                <p class="exchange-nav-txt">SLT</p>
              </div>
              <div class="exchange-nav-list flex" :style="{ background: 1 == exchangeNavIndex ? '#F4F5F9' : '#fff' }"
                @click="exchangeNavIndex = 1, exchangeNavState = !exchangeNavState, exchangeState2 = true, exchangeNum = '', exchangeNum2 = ''">
                <img class="exchange-nav-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
                <p class="exchange-nav-txt">SLT</p>
                <img class="exchange-nav-img" src="@/assets/pc/wallet/exchange_icon4.png" alt="">
                <img class="exchange-nav-icon" src="@/assets/m/wallet/wallet_icon2.png" alt="">
                <p class="exchange-nav-txt">SLT</p>
              </div>
              <div class="exchange-nav-list flex" :style="{ background: 2 == exchangeNavIndex ? '#F4F5F9' : '#fff' }"
                @click="exchangeNavState = !exchangeNavState, checkConversion(), exchangeNum = '', exchangeNum2 = ''">
                <img class="exchange-nav-icon" src="@/assets/m/wallet/wallet_icon2.png" alt="">
                <p class="exchange-nav-txt">SLT</p>
                <img class="exchange-nav-img" src="@/assets/pc/wallet/exchange_icon4.png" alt="">
                <img class="exchange-nav-icon" src="@/assets/pc/wallet/SLT_icon.png" alt="">
                <p class="exchange-nav-txt">SLC</p>
              </div>
            </div>
          </transition>
        </div>
        <p style="flex: 1;"></p>
        <img v-show="exchangeNavIndex == 0" @click="ruleFun" class="exchange-nav-rule"
          src="@/assets/pc/wallet/rule_icon2.png" alt="">
      </div>
      <p v-if="changeType == 2 && exchangeNavIndex == 0" class="exchange-nav-msg">{{ $t('wallet.msg25') }}</p>
      <div v-if="type">
        <div v-if="changeType == 1">
          <div class="exchange-list flex">
            <!-- onkeyup="value=value.replace(/[^\d]/g,'')" -->
            <el-input
              onkeyup="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>999999999){value=999999999}"
              @blur="exchangeNum = $event.target.value" v-model="exchangeNum" class="exchange-input"
              placeholder="0"></el-input>
            <div class="exchange-list-detail">
              <div class="exchange-list-top flex" style="justify-content:right;">
                <img class="exchange-list-icon"
                  :src="exchangeState3 ? require('@/assets/pc/wallet/USDT_icon.png') : require('@/assets/pc/wallet/DMD_icon.png')"
                  alt="">
                <p class="exchange-list-name">{{ exchangeState3 ? 'USDT' : 'DMD' }}</p>
              </div>
              <div class="exchange-list-top flex" style="margin-top: 16px;">
                <p v-show="usdtProportion" class="exchange-list-balance">
                  {{ $t('wallet.Balance') }}：{{ exchangeState3 ?
    Math.floor(usdtProportion.max_balance * 1000000) / 1000000 : Math.floor(dmdProportion.max_balance *
      1000000) / 1000000 }}
                </p>
                <p class="exchange-list-max"
                  @click="exchangeState3 ? exchangeNum = Math.floor(usdtProportion.max_balance) : exchangeNum = Math.floor(dmdProportion.max_balance)">
                  {{ $t('wallet.Maximum') }}</p>

              </div>
            </div>
            <img @click="exchangeState3 = !exchangeState3, exchangeNum = '', exchangeNum2 = ''" class="exchange_icon"
              src="@/assets/pc/wallet/exchange_icon.png" alt="">
          </div>
          <div class="exchange-list flex" style="margin-top: 16px;">
            <!-- <el-input v-model="exchangeNum2" class="exchange-input" placeholder="0"></el-input> -->
            <p class="exchange-input">{{ exchangeNum2 }}</p>
            <div class="exchange-list-detail">
              <div class="exchange-list-top flex" style="justify-content:right;">
                <img class="exchange-list-icon"
                  :src="!exchangeState3 ? require('@/assets/pc/wallet/USDT_icon.png') : require('@/assets/pc/wallet/DMD_icon.png')"
                  alt="">
                <p class="exchange-list-name">{{ !exchangeState3 ? 'USDT' : 'DMD' }}</p>
              </div>
              <div class="exchange-list-top flex" style="margin-top: 16px;">
                <p v-show="usdtProportion" class="exchange-list-balance">{{ $t('wallet.Balance') }}：{{ !exchangeState3 ?
    Math.floor(usdtProportion.max_balance * 1000000) / 1000000 : Math.floor(dmdProportion.max_balance *
      1000000) / 1000000 }}</p>
              </div>

            </div>
          </div>
          <p v-if="exchangeState3" class="exchange-tips">1USDT≈{{ 1 / usdtProportion.pric }}DMD</p>
          <p v-if="!exchangeState3" class="exchange-tips">1DMD≈{{ 1 * dmdProportion.pric }}USDT</p>
          <p v-if="!exchangeState3" class="exchange-tips" style="margin-top: 10px;">{{ $t('wallet.fee') }}：{{
    dmdProportion.fee }}%</p>

        </div>
        <div v-if="changeType == 2">
          <div v-show="exchangeNavIndex == 1 || exchangeNavIndex == 2">
            <div class="exchange-list flex">
              <!-- onkeyup="value=value.replace(/[^\d]/g,'')" -->
              <el-input
                onkeyup="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>999999999){value=999999999}"
                @blur="exchangeNum = $event.target.value" v-model="exchangeNum" class="exchange-input"
                placeholder="0"></el-input>
              <div class="exchange-list-detail">
                <div class="exchange-list-top flex" style="justify-content:right;">
                  <img class="exchange-list-icon"
                    :src="exchangeState2 ? require('@/assets/pc/wallet/SLT_icon2.png') : require('@/assets/pc/header/SLT_icon.png')"
                    alt="">
                  <p class="exchange-list-name">{{ exchangeState2 ? 'SLT' : 'SLT' }}</p>
                </div>
                <div class="exchange-list-top flex" style="margin-top: 16px;">
                  <p v-if="userBalance" class="exchange-list-balance">
                    {{ $t('wallet.Balance') }}：{{ exchangeState2 ?
    Math.floor(userBalance.balance.slt.balance * 1000000) / 1000000 :
    Math.floor(slcConversion.slt_max *
      1000000) / 1000000 }}
                  </p>
                  <p class="exchange-list-max"
                    @click="exchangeState2 ? exchangeNum = Math.floor(userBalance.balance.slt.balance) : exchangeNum = Math.floor(slcConversion.slt_max)">
                    {{ $t('wallet.Maximum') }}</p>
                </div>
              </div>
            </div>
            <div class="exchange-list flex" style="margin-top: 16px;">
              <!-- <el-input v-model="exchangeNum2" class="exchange-input" placeholder="0"></el-input> -->
              <p class="exchange-input">{{ exchangeNum2 }}</p>
              <div class="exchange-list-detail">
                <div class="exchange-list-top flex" style="justify-content:right;">
                  <img class="exchange-list-icon"
                    :src="exchangeState2 ? require('@/assets/pc/header/SLT_icon.png') : require('@/assets/pc/wallet/SLT_icon.png')"
                    alt="">
                  <p class="exchange-list-name">{{ exchangeState2 ? 'SLT' : 'SLC' }}</p>
                </div>
              </div>
            </div>
            <p v-if="exchangeState2" class="exchange-tips">1SLT ≈ 1SLT</p>
            <p v-if="!exchangeState2 && slcConversion" class="exchange-tips">{{ $t('wallet.fee') }}：{{ slcConversion.fee
              }}% +
              {{ slcConversion.sub_slt }} SLT</p>
            <p v-if="!exchangeState2 && slcConversion" class="exchange-tips">
              {{ $t('wallet.received') }} ≈ {{ ((((Number(exchangeNum) *
    ((100 - Number(slcConversion.fee)) * 0.01)) - Number(slcConversion.sub_slt)) *
    slcConversion.proportion).toFixed(2)) > 0 ? (((Number(exchangeNum) *
      ((100 - Number(slcConversion.fee)) * 0.01)) - Number(slcConversion.sub_slt)) *
      slcConversion.proportion).toFixed(2) : 0 }} SLC
            </p>
          </div>
          <div v-show="exchangeNavIndex == 0">
            <div class="exchange-list flex">
              <!-- onkeyup="value=value.replace(/[^\d]/g,'')" -->
              <el-input
                onkeyup="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>999999999){value=999999999}"
                @blur="exchangeNum = $event.target.value" v-model="exchangeNum" class="exchange-input" placeholder="0"
                :min="100" :disabled="integralconversionData.conversion_status == 1 ? false : true"></el-input>
              <div class="exchange-list-detail">
                <div class="exchange-list-top flex" style="justify-content:right;">
                  <img class="exchange-list-icon" src="@/assets/m/wallet/wallet_icon2.png" alt="">
                  <p class="exchange-list-name">SLT</p>
                </div>
                <div class="exchange-list-top flex" style="margin-top: 16px;">
                  <p v-if="integralconversionData" class="exchange-list-balance">
                    {{ $t('wallet.Balance2') }}：{{ integralconversionData.max_balance }}
                  </p>
                  <p class="exchange-list-max" @click="exchangeNum = Math.floor(integralconversionData.max_balance)">
                    {{ $t('wallet.Maximum') }}</p>
                </div>
              </div>
            </div>
            <div class="exchange-list flex" style="margin-top: 16px;">
              <p class="exchange-input">{{ exchangeNum2 }}</p>
              <div class="exchange-list-detail">
                <div class="exchange-list-top flex" style="justify-content:right;">
                  <img class="exchange-list-icon" src="@/assets/pc/wallet/SLT_icon2.png" alt="">
                  <p class="exchange-list-name">SLT</p>
                </div>
              </div>
            </div>
            <p class="exchange-tips">1SLT ≈ 1SLT</p>
            <p v-if="exchangeNum > 99" class="exchange-tips">
              {{ $t('wallet.received') }} ≈ {{ exchangeNum2 }} SLT
            </p>
          </div>
        </div>
      </div>
      <div v-if="!type">
        <div class="detail-box-content">
          <p class="detail-box-content-title">{{ $t('wallet.Quantity') }}</p>
          <div class="flex" style="margin-top: 10px;align-items: end;">
            <p class="detail-box-num">{{ Math.floor(exchangeNum2 * 1000000) / 1000000 }}</p>
            <p v-if="changeType == 1" class="detail-box-unit">{{ exchangeState3 ? 'DMD' : 'USDT' }}</p>
            <p v-if="changeType == 2" class="detail-box-unit">{{ exchangeState2 ? 'SLT' : 'SLC' }}</p>
            <img class="detail-box-state-icon" src="@/assets/pc/wallet/state_icon.png" alt="">
            <p class="detail-box-state" style="color: #30BC85;">{{ $t('wallet.Completed') }}</p>
          </div>
          <p v-if="changeType == 1" class="detail-box-msg">{{ exchangeState3 ? 'DMD' : 'USDT' }}{{ $t('wallet.msg2') }}
          </p>
          <div v-if="changeType == 2">
            <p v-if="exchangeNavIndex == 1 || exchangeNavIndex == 2" class="detail-box-msg">{{ exchangeState2 ? 'SLT' :
    'SLC' }}{{ $t('wallet.msg2') }}
            </p>
            <p v-if="exchangeNavIndex == 0" class="detail-box-msg">SLT{{ $t('wallet.msg29') }}
            </p>
          </div>
        </div>
        <div class="detail-box-list flex">
          <p class="detail-box-list-name">{{ $t('wallet.consume') }}</p>
          <p v-if="changeType == 1" class="detail-box-list-content">{{ exchangeNum }} {{ exchangeState3 ? 'USDT' : 'DMD'
            }}</p>
          <p v-if="changeType == 2" class="detail-box-list-content">{{ exchangeNum }} {{ exchangeState2 ? 'SLT' : 'SLT'
            }}</p>
        </div>
      </div>
      <div v-if="type">
        <div v-if="this.exchangeNavIndex == 0 && integralconversionData.conversion_status == 0">
          <p v-if="integralconversionData.status == 1" style="opacity: 0.5;" class="exchange-btn">{{ $t('wallet.msg24')
            }}</p>
          <p v-if="integralconversionData.status == 2" style="opacity: 0.5;" class="exchange-btn">{{ $t('wallet.msg26')
            }}</p>
          <p v-if="integralconversionData.status == 3" style="opacity: 0.5;" class="exchange-btn">{{ $t('wallet.msg27')
            }}</p>
          <p v-if="integralconversionData.status == 0" style="opacity: 0.5;" class="exchange-btn">{{ $t('wallet.msg28')
            }}</p>
        </div>
        <p v-else class="exchange-btn" @click="exchangeFun">{{ $t('wallet.Swap') }}</p>
      </div>
      <p v-if="!type" class="exchange-btn" @click="close">{{ $t('login.Completed') }}</p>
      <p @click="close" class="exchange-btn" style="background: #F9FAFD;color: #222222;margin: 0 auto;">
        {{ $t('common.Cancel') }}
      </p>
    </div>
    <!-- gas费用不足 -->
    <div v-show="gasState">
      <div class="exchange-layer" style="z-index: 902;" @click="gasState = false"></div>
      <div class="gas-box">
        <div class="gas-top flex">
          <p class="gas-top-title">{{ $t('wallet.Attention') }}</p>
          <img class="gas-top-icon" @click="gasState = false" src="@/assets/pc/common/common_close_01.png" alt="">
        </div>
        <p class="gas-content">{{ gasContent }}</p>
        <div class="gas-footer flex">
          <p style="flex: 1;"></p>
          <p class="gas-btn gas-cancel" @click="gasState = false">{{ gasChangeState == 1 ? $t('common.Cancel') :
            $t('wallet.wait') }}</p>
          <p class="gas-btn gas-confirm" @click="gasCreate">{{ gasChangeState == 1 ? $t('wallet.Swap') :
            $t('wallet.again') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Decimal } from 'decimal.js'
import {
  createSlttoslc, createSltintegral, gasfeeStatus, createGasfeeMW,
  createUsdorder, conversionProportion, getProportionIntegral, getProportion,
  createDiamondorder, checkConversion, getIntegralconversion, sltIntegralconversion
} from '@/api/wallet';
import {
  getuser
} from '@/api/user';

export default {
  name: "exchange",
  props: {
    "exchangeState": { state: Boolean, default: false },
    "userBalanceDate": { state: Array, default: {} },
    "exchangeType": { state: Number, default: 1 },
  },
  components: {
  },
  data() {
    return {
      exchangeNum: '',
      exchangeNum2: '',
      exchangeState2: true, //true-SLT false -SLC
      exchangeState3: true, //true-U false -D
      type: true,
      state: this.exchangeState,
      changeType: this.exchangeType,
      usdtProportion: '',//USDT兑换信息
      dmdProportion: '',//DMD兑换信息
      gasState: false,
      gasContent: '',
      gasInfo: '',//gas内容
      gasChangeState: 1,
      integralProportion: '',//积分兑换信息
      userInfo: '',//用户信息
      fee: 0, //手续费
      timer: null,//定时器
      userBalance: {
      },
      slcConversion: '',
      exchangeNavIndex: 1,
      exchangeNavState: false,
      integralconversionData: null,
    };
  },
  watch: {
    exchangeState: {
      handler(newVal) {
        this.state = newVal
        if (newVal) {
          this.getProportion()
          this.getuser()
          this.getIntegralconversion()
        }
      },
    },
    userBalanceDate: {
      handler(newVal) {
        this.userBalance = newVal
      },
    },
    exchangeType: {
      handler(newVal) {
        this.changeType = newVal
      },
    },
    //监听输入框内容
    exchangeNum: {
      handler(newVal) {
        if (newVal) { newVal = String(newVal).replace(/[^\d]/g, '') }
        if (newVal <= 0) { newVal = '' }
        if (newVal > 999999999) { newVal = 999999999 }
        this.exchangeNum = newVal
        if (this.changeType == 1) {
          if (this.exchangeState3) {
            this.exchangeNum2 = this.exchangeNum / this.usdtProportion.pric
          } else {
            this.fee = this.exchangeNum * (this.dmdProportion.fee * 0.01)
            this.exchangeNum2 = (this.exchangeNum - this.fee) * this.dmdProportion.pric
          }
        } else {
          if (this.exchangeNavIndex == 0) {
            if (this.exchangeNum > 99) {
              // this.exchangeNum2 = ((Number(this.exchangeNum) * 0.5) - 20) * 0.95
              // this.exchangeNum2 = Number((parseFloat((this.exchangeNum * 0.5) - 20) * 0.95).toPrecision(16))
              this.exchangeNum2 = ((new Decimal(this.exchangeNum).mul(new Decimal(0.5))).sub(new Decimal(20))).mul(new Decimal(0.95))
            } else {
              this.exchangeNum2 = 0
            }
          } else {
            if (this.exchangeState2) {
              this.exchangeNum2 = this.exchangeNum
            } else {
              this.exchangeNum2 = this.exchangeNum * this.slcConversion.proportion
            }
          }
        }

      },
    }
  },
  mounted() {
    // this.conversionProportion()
  },
  methods: {
    ...mapMutations(["GetUserInfo"]), //登录弹窗.
    close() {
      this.$emit("close")
      this.type = true
      this.gasInfo = ''
      this.gasState = false
      this.exchangeState2 = true
      this.exchangeState3 = true
      this.gasChangeState = 1
      this.exchangeNum = ''
      this.exchangeNum2 = ''
      this.exchangeNavIndex = 1
    },
    //获取兑换基本信息
    getProportion() {
      getProportion({ todo: 'usdt_diamond' }).then(res => {
        if (res.errorCode) {
          return
        }
        this.usdtProportion = res.data
      }).catch((err) => {
      });
      getProportion({ todo: 'diamond_usdt' }).then(res => {
        if (res.errorCode) {
          return
        }
        this.dmdProportion = res.data
      }).catch((err) => {
      });
    },
    //获取积分兑换信息
    getProportionIntegral() {
      getProportionIntegral().then(res => {
        if (res.errorCode) {
          return
        }
        this.integralProportion = res.data
      }).catch((err) => {
      });
    },

    //兑换
    exchangeFun() {
      if (!this.exchangeNum || this.exchangeNum == 0) {
        this.$message.error(this.$t('wallet.msg3'));
        return
      }
      if (this.changeType == 1) {
        if (this.exchangeState3) {
          if (this.exchangeNum > Number(this.usdtProportion.max_balance)) {
            this.$message.error(this.$t('wallet.msg4'));
            return
          }
        } else {
          if (this.exchangeNum > Number(this.dmdProportion.max_balance)) {
            this.$message.error(this.$t('wallet.msg4'));
            return
          }
        }
      } else {
        if (this.exchangeNavIndex == 0) {
          if (this.exchangeNum > Number(this.integralconversionData.max_balance)) {
            this.$message.error(this.$t('wallet.msg4'));
            return
          } else if (this.exchangeNum < 100) {
            this.$message.error(this.$t('wallet.msg23'));
            return
          }
        } else {
          if (this.exchangeState2) {
            if (this.exchangeNum > Number(this.userBalance.balance.slt.balance)) {
              this.$message.error(this.$t('wallet.msg4'));
              return
            }
          } else {
            if (this.exchangeNum > Number(this.slcConversion.slt_max)) {
              this.$message.error(this.$t('wallet.msg4'));
              return
            }
          }
        }

      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      if (this.changeType == 1) {
        if (this.exchangeState3) {
          let data = {
            amount: this.exchangeNum,
            diamond_amount: this.exchangeNum2
          }
          createDiamondorder(data).then(res => {
            if (res.errorCode) {
              loading.close();
              this.$message.error(res.msg);
              return
            }
            if (res.data.status == 0) {
              this.gasInfo = res.data
              this.getGasfeeStatus()
              loading.close();
            } else {
              loading.close();
              this.type = false
              this.getuser()
            }
          }).catch((err) => {
            loading.close();
          });
        } else {
          let data = {
            diamond_amount: this.exchangeNum,
            amount: this.exchangeNum2,
            fee: this.fee
          }
          createUsdorder(data).then(res => {
            if (res.errorCode) {
              loading.close();
              this.$message.error(res.msg);
              return
            }
            if (res.data.status == 0) {
              this.gasInfo = res.data
              this.getGasfeeStatus()
              loading.close();
            } else {
              loading.close();
              this.type = false
              this.getuser()
            }
          }).catch((err) => {
            loading.close();
          });
        }
      }
      else {
        if (this.exchangeNavIndex == 0) {
          let data = {
            integral_amount: this.exchangeNum,
            slt_amount: this.exchangeNum2
          }
          sltIntegralconversion(data).then(res => {
            loading.close();
            if (res.errorCode) {
              this.$message.error(res.msg);
              return
            }
            this.type = false
          }).catch((err) => {
            loading.close();
          });
        } else {
          if (this.exchangeState2) {
            let data = {
              amount: this.exchangeNum,
              integral_amount: this.exchangeNum2
            }
            createSltintegral(data).then(res => {
              if (res.errorCode) {
                loading.close();
                this.$message.error(res.msg);
                return
              }
              if (res.data.status == 0) {
                this.gasInfo = res.data
                this.getGasfeeStatus()
                loading.close();
              } else {
                loading.close();
                this.type = false
                this.getuser()
              }
            }).catch((err) => {
              loading.close();
            });
          } else {
            let data = {
              slt_amount: this.exchangeNum,
              slc_count: this.exchangeNum2,
              fee: this.fee
            }
            createSlttoslc(data).then(res => {
              if (res.errorCode) {
                loading.close();
                return
              }
              if (res.data.status == 0) {
                this.gasInfo = res.data
                this.getGasfeeStatus()
                loading.close();
              } else {
                loading.close();
                this.type = false
                this.getuser()
              }
            }).catch((err) => {
              loading.close();
            });
          }
        }
      }


    },
    //查询链上是否有gasfee
    getGasfeeStatus() {
      gasfeeStatus().then(res => {
        if (res.errorCode) {
          return
        }
        if (res.data.status == 1) {
          this.gasContent = this.$t('wallet.msg5') + this.gasInfo.gas_fee + this.gasInfo.coin_title + this.$t('wallet.msg66')
          this.gasState = true
        } else if (res.data.status == 0) {
          this.gasContent = this.$t('wallet.msg8') + res.data.count + this.$t('wallet.msg7')
          this.gasState = true
        }
        this.gasChangeState = res.data.status
      }).catch((err) => {
      });
    },
    //兑换gas费
    gasCreate() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      createGasfeeMW({ amount: this.gasInfo.gas_fee, coin_type: this.gasInfo.coin_type }).then(res => {
        if (res.errorCode) {
          loading.close();
          return
        }
        loading.close();
        this.gasState = false
        this.close()
        this.$message(this.$t('wallet.msg9'));
      }).catch((err) => {
        loading.close();
      });
    },
    getuser() {
      getuser().then(res => {
        if (res.errorCode) {
          return
        }
        this.userInfo = res.data
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.GetUserInfo()
      }).catch(() => {
        // this.$message.error('发送失败');
      });
    },
    clearDetail() {
      this.exchangeNum = ''
      this.exchangeNum2 = ''
      this.type = true
      this.getProportion()
      // this.getProportionIntegral()
      this.getuser()

    },
    //查询积分兑换SLC
    // conversionProportion() {
    //   if (!this.exchangeNum) { return }
    //   conversionProportion({ slt_amount: this.exchangeNum }).then(res => {
    //     if (res.errorCode) {
    //       return
    //     }
    //     this.slcConversion = res.data
    //     this.exchangeNum2 = res.data.slc_count
    //     this.fee = Number(this.exchangeNum) * (Number(this.slcConversion.fee) * 0.01)
    //   }).catch((err) => {
    //   });
    // },
    checkConversion() {
      this.$confirm(this.$t('wallet.msg22'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel'),
        customClass: 'msgbox'
      })
        .then(_ => {
        })
        .catch(_ => { });

      // checkConversion().then(res => {
      //   if (res.data == 1) {
      //     conversionProportion().then(res => {
      //       this.slcConversion = res.data
      //       this.exchangeState2 = !this.exchangeState2
      //       this.exchangeNavIndex = 2
      //       this.exchangeNum = ''
      //       this.exchangeNum2 = ''
      //       this.type = true
      //     }).catch((err) => {
      //     });
      //   }
      // }).catch((err) => {
      // });

    },
    getIntegralconversion() {
      getIntegralconversion().then(res => {
        if (res.errorCode) {
          return
        }
        this.integralconversionData = res.data
      }).catch((err) => {
      });
    },
    ruleFun() {
      this.$alert(this.$t('wallet.rule'), this.$t('wallet.Attention'), {
        confirmButtonText: this.$t('common.Confirm'),
        customClass: 'msgbox',
        callback: action => {
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  background: #fff !important;
  font-size: 32px !important;
  color: #222222;
  border: none;
  padding: 0;
  font-family: 'Bold' !important;
  width: 80%;

  &::placeholder {
    font-size: 26px;
  }
}

.exchange-layer {
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.5;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
}

.exchange {
  width: 750px;
  // height: 760px;
  background: #F9FAFD;
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 902;
  padding-top: 30px;
  padding-bottom: 50px;

  .exchange-title {
    margin: 0 30px;
    font-size: 32px;
    color: #222222;
    font-family: 'Medium';
    line-height: 44px;
    position: relative;
    display: flex;
    align-items: center;

    .exchange-close {
      width: 34px;
      height: 34px;
      cursor: pointer;
    }

    .exchange-icon {
      width: 21px;
      height: 24px;
      margin-left: 6px;
    }
  }

  .exchange-nav {
    width: 326px;
    height: 72px;
    border: 1px solid #E8E9F0;
    border-radius: 16px;
    position: relative;
    margin: 30px 0 0 30px;
    padding-left: 20px;
    z-index: 10;

    .exchange-nav-icon {
      width: 40px;
      height: 40px;
    }

    .exchange-nav-txt {
      font-size: 28px;
      color: #161B2C;
      margin-left: 6px;
      font-family: 'Bold';
    }

    .exchange-nav-img {
      width: 20px;
      height: 18px;
      margin: 0 14px;
    }

    .exchange-nav-img2 {
      width: 16px;
      height: 11px;
      margin: 0 28px 0 22px;
    }

    .exchange-nav-detail {
      width: 326px;
      height: auto;
      padding: 10px;
      background: #fff;
      border-radius: 16px;
      border: 1px solid #E8E9F0;
      position: absolute;
      top: 80px;
      left: 0;

      .exchange-nav-list {
        width: 306px;
        height: 72px;
        padding-left: 10px;
        border-radius: 16px;

        .exchange-nav-icon {
          width: 40px;
          height: 40px;
        }

        .exchange-nav-txt {
          font-size: 28px;
          color: #161B2C;
          margin-left: 6px;
          font-family: 'Bold';
        }

        .exchange-nav-img {
          width: 20px;
          height: 18px;
          margin: 0 14px;
        }

        .exchange-nav-img2 {
          width: 16px;
          height: 11px;
          margin: 0 28px 0 22px;
        }
      }
    }
  }

  .exchange-nav-rule {
    width: 36px;
    height: auto;
    margin: 20px 40px 0 0;
  }

  .exchange-nav-msg {
    font-size: 26px;
    color: #FF3B8A;
    margin: 16px 0 0 30px;
  }

  .exchange-list {
    width: 690px;
    height: 144px;
    background: #fff;
    border-radius: 16px;
    margin: 30px auto 32px auto;
    position: relative;

    .exchange-input {
      flex: 1;
      height: 144px;
      margin: 0 18px 0 50px;
      line-height: 144px;
      font-size: 32px;
      color: #222222;

      .el-input__inner {
        height: 144px !important;
        font-size: 32px;
        font-family: 'Medium';
        resize: none;
        border: none;
        padding: 0;
        color: #222222;
        background: #fff !important;
      }
    }

    .exchange-list-detail {
      text-align: right;
      font-family: 'Medium';
      margin-right: 50px;

      .exchange-list-top {
        .exchange-list-icon {
          width: 32px;
          height: auto;
        }

        .exchange-list-name {
          font-size: 24px;
          color: #222222;
          margin-left: 8px;
        }

        .exchange-list-balance {
          font-size: 24px;
          color: #C6C9D3;
        }

        .exchange-list-max {
          cursor: pointer;
          font-size: 24px;
          color: #7E6CFF;
          margin-left: 20px;
        }
      }
    }

    .exchange_icon {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: -36px;
      left: 355px;
      z-index: 1;
      cursor: pointer;
    }
  }

  .exchange-tips {
    font-size: 24px;
    color: #C6C9D3;
    margin-left: 30px;
    font-family: 'Medium';
  }

  .exchange-msg {
    font-size: 24px;
    color: #8F93A6;
    margin: 20px 0 0 30px;
    font-family: 'Medium';
  }

  .exchange-btn {
    width: 690px;
    height: 88px;
    background: #6930FF;
    border-radius: 40px;
    margin: 50px auto 20px auto;
    text-align: center;
    font-size: 28px;
    color: #fff;
    font-family: 'Medium';
    line-height: 88px;
    cursor: pointer;
  }

  //详情
  .detail-box-content {
    width: 670px;
    margin: 40px;
    border-radius: 30px;
    padding: 32px;
    background: #F9FAFD;

    .detail-box-content-title {
      font-size: 28px;
      color: #8F93A6;
    }

    .detail-box-num {
      font-size: 48px;
      color: #222222;
      line-height: 76px;
      font-family: 'Bold';
    }

    .detail-box-unit {
      font-size: 24px;
      color: #8F93A6;
      flex: 1;
      margin-left: 8px;
      line-height: 60px;
    }

    .detail-box-state-icon {
      width: 40px;
      height: auto;
    }

    .detail-box-state {
      font-size: 36px;
      font-family: 'Bold';
      margin-left: 8px;
    }

    .detail-box-msg {
      font-size: 24px;
      color: #C6C9D3;
      margin-top: 20px;
      line-height: 32px;
    }

    .detail-box-consume {
      font-size: 28px;
      color: #222222;
      font-family: 'Bold';
      line-height: 44px;
      margin-top: 12px;
    }
  }

  .detail-box-list {
    margin: 0 40px 30px 40px;
    border-bottom: 1px solid #F2F1F5;
    padding-bottom: 32px;

    .detail-box-list-name {
      flex: 1;
      font-size: 28px;
      color: #8F93A6;
      font-family: 'Medium';
    }

    .detail-box-list-content {
      font-size: 28px;
      color: #222222;
      font-family: 'Medium';
      max-width: 340px;
      word-break: break-word;
    }

    .detail-box-copy {
      width: 24px;
      height: auto;
      margin-left: 12px;
    }
  }

  .detail-box-list:last-child {
    border-bottom: none;
  }
}

//gas费
.gas-box {
  width: 690px;
  height: 360px;
  background: #FFFFFF;
  border-radius: 16px;
  position: fixed;
  z-index: 903;
  top: 50%;
  left: 50%;
  margin-top: -160px;
  margin-left: -345px;
  padding: 40px 0;

  .gas-top {
    margin: 0 40px;
    align-items: start;

    .gas-top-title {
      flex: 1;
      font-size: 32px;
      color: #1E2333;
      font-family: 'Bold';
      line-height: 52px;
    }

    .gas-top-icon {
      width: 16px;
      height: auto;
      cursor: pointer;
    }
  }

  .gas-content {
    margin: 44px 40px 36px 40px;
    font-size: 28px;
    color: #222222;
    line-height: 36px;
  }

  .gas-footer {
    margin: 0 40px;

    .gas-btn {
      height: 60px;
      font-size: 28px;
      line-height: 60px;
      padding: 0 36px;
      border-radius: 8px;
      margin-left: 20px;
    }

    .gas-cancel {
      color: #222222;
      border: 1px solid #9FA1AC;
    }

    .gas-confirm {
      border: 1px solid #5B38FF;
      color: #fff;
      background: #5B38FF;
    }
  }
}
</style>
