<template>
  <div v-if="show" class="privacyPolicy">
    <div class="privacy-layer" @click="closeFun()"></div>
    <div class="privacy-box">
      <div class="privacy-header">
        <p class="privacy-title">{{ $t('privacy.title') }}</p>
        <img @click="closeFun()" class="privacy-close" src="@/assets/pc/common/common_close_01.png" alt="">
      </div>
      <div class="privacy-detail">
        <p class="content">{{ $t('privacy.content1') }}
          <b>{{ $t('privacy.content55') }}</b>
          {{ $t('privacy.content56') }}
          <b>{{ $t('privacy.content57') }}</b>
          {{ $t('privacy.content58') }}
        </p>
        <p class="content">{{ $t('privacy.content2') }}
          <b>{{ $t('privacy.content59') }}</b>
          {{ $t('privacy.content60') }}
        </p>
        <p class="content">{{ $t('privacy.content3') }}</p>
        <p class="content">{{ $t('privacy.content4') }}</p>
        <p class="content">{{ $t('privacy.content5') }}</p>
        <p class="title">{{ $t('privacy.title1') }}</p>
        <p class="content">{{ $t('privacy.content6') }}</p>
        <p class="title">{{ $t('privacy.title2') }}</p>
        <p class="content">{{ $t('privacy.content7') }}</p>
        <p class="content">{{ $t('privacy.content8') }}</p>
        <li class="content circle">{{ $t('privacy.content9') }}</li>
        <li class="content circle">{{ $t('privacy.content10') }}</li>
        <li class="content circle">{{ $t('privacy.content11') }}</li>
        <li class="content circle">{{ $t('privacy.content12') }}</li>
        <li class="content circle">{{ $t('privacy.content13') }}</li>
        <li class="content circle">{{ $t('privacy.content14') }}</li>
        <li class="content circle">{{ $t('privacy.content15') }}</li>
        <p class="content">{{ $t('privacy.content16') }}</p>
        <li class="content circle">{{ $t('privacy.content17') }}</li>
        <li class="content circle">{{ $t('privacy.content18') }}</li>
        <li class="content circle">{{ $t('privacy.content19') }}</li>
        <p class="content">{{ $t('privacy.content20') }}</p>
        <p class="content">{{ $t('privacy.content21') }}</p>
        <p class="content">{{ $t('privacy.content22') }}</p>
        <li class="content circle">{{ $t('privacy.content23') }}</li>
        <li class="content circle">{{ $t('privacy.content24') }}</li>
        <li class="content circle">{{ $t('privacy.content25') }}</li>
        <li class="content circle">{{ $t('privacy.content26') }}</li>
        <p class="title">{{ $t('privacy.title3') }}</p>
        <p class="content">{{ $t('privacy.content27') }}</p>
        <p class="title">{{ $t('privacy.title4') }}</p>
        <p class="content">{{ $t('privacy.content28') }}</p>
        <p class="title">{{ $t('privacy.title5') }}</p>
        <p class="content">{{ $t('privacy.content29') }}</p>
        <p class="title">{{ $t('privacy.title6') }}</p>
        <p class="content">{{ $t('privacy.content30') }}</p>
        <p class="title">{{ $t('privacy.title7') }}</p>
        <p class="content">{{ $t('privacy.content31') }}</p>
        <p class="title">{{ $t('privacy.title8') }}</p>
        <p class="content">{{ $t('privacy.content32') }}</p>
        <p class="title">{{ $t('privacy.title9') }}</p>
        <p class="content">{{ $t('privacy.content33') }}</p>
        <p class="title">{{ $t('privacy.title10') }}</p>
        <p class="content">{{ $t('privacy.content34') }}</p>
        <p class="title">{{ $t('privacy.title11') }}</p>
        <p class="content">{{ $t('privacy.content35') }}</p>
        <p class="title">{{ $t('privacy.title12') }}</p>
        <p class="content">{{ $t('privacy.content36') }}</p>
        <p class="content">{{ $t('privacy.content37') }}</p>
        <p class="title">{{ $t('privacy.title13') }}</p>
        <p class="content">{{ $t('privacy.content38') }}</p>
        <p class="title">{{ $t('privacy.title14') }}</p>
        <p class="content">{{ $t('privacy.content39') }}</p>
        <p class="title">{{ $t('privacy.title15') }}</p>
        <p class="content">{{ $t('privacy.content40') }}</p>
        <p class="title">{{ $t('privacy.title16') }}</p>
        <p class="content">{{ $t('privacy.content41') }}</p>
        <p class="content">{{ $t('privacy.content42') }}</p>
        <li class="content circle">{{ $t('privacy.content43') }}</li>
        <li class="content circle">{{ $t('privacy.content44') }}</li>
        <li class="content circle">{{ $t('privacy.content45') }}</li>
        <li class="content circle">{{ $t('privacy.content46') }}</li>
        <li class="content circle">{{ $t('privacy.content47') }}</li>
        <li class="content circle">{{ $t('privacy.content48') }}</li>
        <li class="content circle">{{ $t('privacy.content49') }}</li>
        <p class="title">{{ $t('privacy.title17') }}</p>
        <p class="title">{{ $t('privacy.title18') }}</p>
        <p class="content">{{ $t('privacy.content50') }}</p>
        <p class="content">{{ $t('privacy.content51') }}</p>
        <p class="content">{{ $t('privacy.content52') }}</p>
        <p class="content">{{ $t('privacy.content54') }}</p>
      </div>
      <p @click="closeFun()" class="privacy-footer">{{ $t('common.understoodBtn') }}</p>
    </div>
  </div>
</template>
<script>

export default {
  name: "privacyPolicy",
  props: {
    "type": { type: Boolean, default: false }
  },
  components: {
  },
  data() {
    return {
      show:this.type
    };
  },
  watch: {
    type: {
      handler(newVal) {
        this.show = newVal
      },
    }
  },
  mounted() {
  },
  methods: {
    closeFun(){
      this.$emit("privacyClose")
    }
  },
};
</script>

<style lang="scss">
.privacy-layer {
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.5;
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
}

.privacy-box {
  width: 800px;
  height: 612px;
  background: #fff;
  border-radius: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -306px;
  margin-left: -400px;
  z-index: 103;

  .privacy-header {
    width: 100%;
    height: 70px;
    position: relative;

    .privacy-title {
      font-size: 20px;
      color: #333333;
      text-align: center;
      line-height: 70px;
      font-family: 'Bold';
    }

    .privacy-close {
      width: 8px;
      height: 8px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  .privacy-detail {
    width: 740px;
    height: 462px;
    overflow-y: auto;
    margin: 0 auto;
    background: #F9FAFC;
    border-radius: 12px;
    padding-top: 22px;

    .title {
      font-size: 14px;
      color: #222222;
      line-height: 18px;
      font-family: 'Bold';
      margin: 0 20px 30px 20px;
    }

    .content {
      font-size: 14px;
      color: #222222;
      line-height: 18px;
      margin: 0 20px 30px 20px;
    }

    .circle {
      list-style-type: disc;
    }
  }

  .privacy-footer {
    width: 220px;
    height: 40px;
    background: #6F38FF;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin: 20px auto;
  }
}
</style>
