module.exports = {
  language: {
    name: 'English',
    change: 'zh',
    text:'Language'
  },
  header: {
    nav: [{ name: 'Selected', path: '/' }, { name: 'Research', path: '/deep' }, { name: 'Newsflash', path: '/news' }, { name: 'Creation Center', path: '/create' }],
    navM: [{ name: 'Selected', path: '/' }, { name: 'Research', path: '/deep' }, { name: 'Newsflash', path: '/news' }],
    // nav:[{name:'首页',path:'/'},{name:'精选',path:'/selected'},{name:'快讯',path:'/news'},{name:'创作中心',path:'/create'}],
    // navM:[{name:'首页',path:'/'},{name:'精选',path:'/selected'},{name:'快讯',path:'/news'}],
  },
  footer: {
    introduce: 'A feeds-themed platform with blockchain-related information. Contents here are fast, accurate, and comprehensive. SoulLand is dedicated to providing 24/7 global real-time financial news for blockchain enthusiasts.',
    name: 'Soul Land Group Inc.',
    aboutTitle: 'About',
    about: ['Privacy Policy'],
    contactTitle: 'Contact us',
    contact: ['Contact Us']
  },
  //首页
  home: {
    lableTitle: 'Recommended Tags',
    appMsg: 'Open in SoulLand App',
    newsTitle: 'Newsflash',
    lodingMsg: 'More',
    allMsg: 'View all ',
    hotTitle: 'Hot Articles',
    articleAll: 'More',
    common: '',
  },
  about: {
    title: '我是中文',
  },
  //创作中心
  create: {
    manageTitle: 'Articles Review & Management',
    nav: ['All', 'Under review', 'Succeeded', 'Unaccepted article', 'Drafts'],
    createName: 'Start',
    createTitle: 'Creation Center',
    edit: 'Edit',
    del: 'Delete',
    prompt: 'Unaccepted article. Please contact our Customer Service.',
    kefu: '',
    createTitle1: 'Article Creation',
    draft: 'Save to Drafts',
    articletitle: 'Please enter the article title...',
    abstract: 'Summary (The content displayed when posting or sharing this article. Please summarize main ideas of this article with less than 100 words.)',
    articlePhoto: 'Covers',
    articlePhotoMsg: 'High-resolution images are preferred as article covers.Recommended size: 16/9. Supported formats: .jpg, .jpeg, .png. Maximum size: 5MB.',
    lableTitle: 'Article Tags',
    languageTitle: 'Language',
    lableTitleMsg: 'You can choose up to 5 tags.',
    submitText: 'Article completed！',
    successMsg: 'Congratulations!',
    successMsg2: 'Your article is under review. Once accepted, it will be automatically posted on the APP. Please wait patiently.',
    confirmText: 'Confirm',
    state1: 'Succeeded',
    state2: 'Application rejected',
    state3: 'Under review',
    msg1: "You haven't created any content/ You have deleted it.Come on!",
    msg2: 'Are you sure to delete it?',
    msg3: "Once deleted, this article will be removed from the SoulLand world, and you won't be able to retrieve it.",
  },
  //活动
  activity: {
    time: 'Dec 21st, 2023 12 pm (UTC) - Jan 4th, 2024 12 pm (UTC)',
    IntroductionTitle: 'Introduction',
    StepsTitle: 'Steps',
    DistributionTitle: 'Distribution',
    AttentionsTitle: 'Attentions',
    IntroductionTime: 'Dec 21st, 2023 12 pm (UTC) - Jan 4th, 2024 12 pm (UTC)',
    IntroductionContent: "Welcome to the SoulLand X HopeCard Knowledge Quest Championship! In this 14-day event, you'll have the chance to win a total prize of ",
    IntroductionContent3: "10000 USDT and 10 pieces of HopeCard! ",
    IntroductionContent4: "Participants who successfully answer at least 6 out of 10 quizzes correctly are eligible for the drawing, with each lucky winner receiving 20 USDT or a Hope Card V3 as rewards. There are also opportunities for users to earn additional 10 SLT and enjoy great experiences by downloading SoulLand APP and creating a wallet.",
    StepsName1: 'Registration',
    StepsName2: 'Quizzes',
    StepsName3: 'APP download and wallet creation',
    StepsName4: 'Get your rewards',
    StepsContent1: "During this event, users need to register a SoulLand account first. If you haven't registered yet, you can download SoulLand APP via the App Store or Google Play Store, or register online via our webpage.",
    StepsContent2: "Users can participate in this event by simply registering a SoulLand account on our official website. Each group of quizzes consists of 10 items. Users need to answer correctly at least 6 quizzes in one group to gain eligibility to the drawing. Each user has 10 chances to participate in answering the quizzes.",
    StepsContent3: "After completing the quizzes, users will be guided to download the SoulLand APP, register and log in SoulLand account and create a wallet . It will help users to earn additional SLT and other bonus.",
    StepsContent4: "The drawing is to run within 7 business days as this event ends, and lucky winners will be notified via the official announcement. The rewards of 20 USDT will be distributed to the winners' wallet, Hope Card V3 will give away by Hope official.",
    IntroductionContent2: "Once the number of answered quizzes exceeds 1,000, the drawing will be triggered. The drawing will be conducted automatically by the SoulLand APP. Inviting friends to participate will increase your chances of being the winner.",
    BonusTitle: 'Download App',
    BonusContent: "Download the SoulLand APP, log in the account and create a wallet on it, to open the gateway to more exciting experiences.",
    Eligibility: "Answering at least 6 out of 10 quizzes correctly and downloading our SoulLand App will qualify you for the drawing. We aim to attract at least 1000 participants to activate the Prize Pool, and it will be nice to invite your friends to participate in this event.",
    Duration: "After this 14-day event ends, we will count the number of participants and run the drawing automatically to ensure fairness. Please make sure to complete the quizzes before the deadline, which is on Jan 4th, 2024 12 pm (UTC).",
    Distribution: "Distribution:The prize distribution for the event will take place within 7 business days after the event's deadline. The system announcement will automatically notify the winning users. All prize-related information will be based on official announcements by SoulLand. ",
    UserIdentity: "Users who have already downloaded the SoulLand APP and have previously received SLT are not considered new users. Additionally, users can invite others to join in the quizzes, but they cannot invite themselves.",
    Qualification: "Each user has only one chance to participate in the drawing.",
    FinalInterpretation: "The final interpretation of the event belongs to the SoulLand official. If there are any disputes or questions, please feel free to contact our customer service.",
    tips: 'Friendly Reminder',
    tipsContent: "No more chances to answer quizzes in this SoulLand event. Thanks for your participation! The results for drawing is coming soon, please stay tuned!",
    down: 'Click to Download Now',
    Question: 'Quiz.',
    ti: '',
    total: 'Total of 10 Questions',
    erroeTitle: 'Incorrect Answer!',
    Analysis: 'Analysis：',
    correctAnswer: 'Correct Answer',
    correct: 'Correct Answer!',
    next: "Congratulations! Next question!",
    try: "Keep it up! Next question!",
    completeTitle: 'Quizzes Completed',
    completeMsg1: 'In this round, you answered a total of 10 questions, with',
    completeMsg2: 'correct and ',
    completeMsg3: 'wrong, earning n Experience. Accuracy：',
    completeMsg4: "Congratulations! You've qualified for the draw!",
    completeMsg5: "You can participate in another round of the quiz (Your eligibility is secured, answering again won't affect your eligibility).",
    completeMsg6: "Sorry, you didn't qualify for the draw!",
    completeMsg7: 'You can try again for a chance to qualify (Each person has ten attempts)',
    completeMsg8: "When the event ends, we'll randomly select 500 lucky winners from the participants and send the rewards via private messages after you download our App. You'll also earn an extra ",
    completeMsg9: "SLT and enjoy great experiences by downloading the APP and creating a wallet. Come and join the challenge! The event kicks off on December 21st, so stay tuned.",
    completeMsg10: 'Download the SoulLand APP, log in the account and create a wallet on it, to open the gateway to more exciting experiences.',
    complete: 'FINISH',
    copy: 'Copy the invitation link',
    copyMsg: 'Invitation link copied successfully.',
    EligibilityTitle: 'Eligibility',
    Rewards: "Rewards",
    Sponsorship: "Keen Support",
    EligibilityContent: "All Web3 users interested in SoulLand and Hope are welcomed to join our campaign, as long as you meet the requirements for quizzes.",
    PrizePool: "Prize Pool",
    PrizePoolContent: "The total rewards are 10000 USDT plus 10 pieces of Hope Card V3.",
    Rules: "Rules for Drawings",
    RulesContent: "Once the number of participants exceeds 1,000 as our campaign ends, the drawing will be triggered. Inviting friends to participate in our event will increase your chances of being the lucky one.",
    SLT: "SLT Rewards",
    SLTContent: "First-time users who complete the quizzes and download the SoulLand APP will receive 10 SLT.",
    AdditionalBonus: "Additional Bonus",
    AdditionalBonusContent: "Download the SoulLand APP to open the gateway to more engaging experiences.",
    DistributionContent: "The drawing is to run within 7 business days as this event ends, and lucky winners will be notified via the official announcement. The rewards of 20 USDT will be distributed to the winners' wallet, Hope Card V3 will give away by Hope official.",
    Individual: "Individual Rewards",
    IndividualContent: "Participants who successfully answer at least 6 out of 10 quizzes correctly are eligible for the drawing, with each lucky winner receiving 20 USDT or a Hope Card V3 as rewards.",
    Duration1: "Duration:",
    UserIdentity1: "User Identity:",
    Qualification1: "Qualification:",
    Final: "Final Interpretation:",
    endMsg: "The activity was finished",
    startMsg: "The activity has not started yet",

    points: "%",
  },
  // 设置中心
  set: {
    nav: ['User Information'],
    title: "Settings",
    username: "Username",
    Edit: "Edit",
    brief: "Personal Description",
    photo: "profile picture",
    picture: "Change profile picture",
    msg1: "To apply for KOL identity",
    msg2: "Please apply for KOL identity first.",
    msg3: "Under review, please wait patiently.",
    msg4: "Application rejected. Please reapply after 24 hours.",
    msg5: "Application approved. You can download SoulLand APP and create your own Guild.",
    msg6: "To apply for KOL identity",
    msg7: "Under Review",
    msg8: "Apply",
    msg9: "Download SoulLand APP ",
  },
  //隐私政策
  privacy: {
    title: 'Privacy Policy',
    content1: 'This Privacy Policy applies to',
    content55: ' SoulLand Group Inc {"SoulLand", "we", "us" or "our"}',
    content56: 'and explains how we collect, use, disclose, and store information related to your use of our services and any other related platforms, as well as online and offline interactions and exchanges, including but not limited to any sales, marketing, or events {collectively, the',
    content57: '"Services"',
    content58: '}.',
    content2: 'Your use of our Services signifies your acceptance of our Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Services or provide us with any information that may directly or indirectly identify, relate to, describe, or reasonably link to a specific individual, including any information subject to data protection laws {',
    content59: '"Personal Information"',
    content60: '}.',
    content3: 'Please note that if you do not provide us with the Personal Information we request, or if you do not authorize us to process your Personal Information, you may not be able to use all or part of the Services.',
    content4: 'Under the principles of legitimacy, legality, and necessity, we collect and use the Personal Information you voluntarily provide or generated due to using our products or services, and relevant Personal Information obtained from third parties, for the purposes stated in this Privacy Policy as followings. Should we use your Personal Information for purposes not specified in this Privacy Policy, or use information collected for specific purposes for another purpose, we would notify you in a proper way and obtain your consent before using it. When you cancel your account, we will cease using and delete the foregoing information.',
    content5: 'SoulLand is dedicated to ensuring confidentiality and security of the Personal Information collected through using its Services. Please read carefully the following terms.',
    content6: 'Any information collected through our Services is subject to the Privacy Policy in effect at the time such information was collected. We may make revisions to this Privacy Policy from time to time. If any significant revisions were to made to this Privacy Policy, we would notify you in proper ways, for example, by posting them on the Services or sending emails. It will be considered that you have accepted the updated Privacy Policy if you continue to use our Services.',
    content7: 'Personal Information',
    content8: "We may collect Personal Information based on the nature of the provided or used Services, including the following categories (subject to applicable laws and regulations):",
    content9: 'Identification Information: such as your name, Web3 wallet address, blockchain address, social media accounts, date of birth, etc. If you choose to link the service to your social network, such as Facebook, Google+, or Twitter, they may share information with us in accordance with their privacy policies and your settings;',
    content10: 'Contact information: such as your email address and phone number;',
    content11: 'Customer records: such as electronic customer records containing Personal Information, or records when payments are processed by third party payment processors;',
    content12: 'Communication records with you: including any records of interactions you make to our posts;',
    content13: 'Financial history and details: such as records of purchase and payment information;',
    content14: 'Location Information: information that allows us to determine your geographic location;',
    content15: 'Preference Records: preference information collected during your use of our Services, including but not limited to likes, follows, bookmarks, interactions, searches, etc.',
    content16: 'We collect certain Personal Information in the following ways:',
    content17: 'Directly from you, for example, when you use our Services, register an account, interact with us, connect a Web3 wallet, provide your email address, participate in transactions, tasks or quests, engage in activities or events, and make enquiries;',
    content18: 'From third parties, such as our affiliates and other third parties, social media software, public records, third party services providers, social networking, and joint marketing partners, etc.',
    content19: 'Data collected automatically, for example, data collected when you use our Services or communicate with us, or data collected about yourself, your interests, and your preferences based on your Personal Information.',
    content20: 'We collect information from various sources, such as from offline, online, and third-party channels.',
    content21: 'When you provide Personal Information of others, it represents that you are authorized to act on their behalf and have provided that person with a copy of this Privacy Policy and how we may use this information.',
    content22: 'Additionally, in accordance with relevant laws, regulations, and national standards, we may process your Personal Information without obtaining your consent in the following cases:',
    content23: 'Necessary for the fulfillment of legal obligations or statutory duties of personal information processors;',
    content24: 'Necessary for responding to public health emergency or protecting the life, health, and property of natural persons in emergencies;',
    content25: 'Process Personal Information disclose publicly on your own or has been legally disclosed in accordance with the laws within reasonable limits;',
    content26: 'Other cases as specified by laws and administrative regulations.',
    content27: 'We collect information such as device IDs, cookies, user settings and preferences, operating system and application versions, as well as technical data such as IP addresses, referer sources (Web, App, Wap), browser types and versions, time zone settings, and location information.',
    content28: 'As a content provider, you can provide information such as the name, description, images, coverage, blog websites, etc., of the content you intend to publish.',
    content29: "We use cookies and similar technologies to collect information generated when using the Website by all visitors, to help us remember you and your preferences when you revisit the Website. These cookies may be retained in your browser until they expire or you delete them. For more general information about cookies and how they work, please visit www.allaboutcookies.org. We may allow selected third parties to place cookies on the Website for analytical or advertising purposes, and they may collect information about consumers' online activities on different websites while using our Website over time.",
    content30: 'The primary purposes for which we collect and process your Personal Information are to better provide our Services to you (to execute our contract with you), improve our Services, manage your use of the Services, market our Services to you, and offer you better experience when browsing our website. Besides, we may process your information based on our legitimate interests (such as fraud prevention, cyber security and information security, research, including marketing research and direct marketing), and with your consent. We will use your information for one or more of the following purposes:',
    content31: 'Automatically update the applications on your device; enhance user experience;conducting data statistics and marketing analysis on the app users; communicate with you regarding your enquiries, claims, or technical support; informing you about our policy changes and send you information about iterations of our website and apps.Advertising Customization and Measurement',
    content32: 'Personalize the content and experience of our Services, including providing reports, recommendations, advertisements, and feedback based on your preferences; measure and analyze advertisements (e.g. measure and analyze the effectiveness and attractiveness of each advertisement); recommend products and Services that we believe may interest you.',
    content33: 'Assist us in evaluating and improving existing Services and developing other Services that users may be interested in; optimize or improve our products, Services, and operations; monitor quality control and ensure compliance with legal obligations, guidelines, regulations, policies, and procedures.',
    content34: 'Detect, investigate and prevent activities that may violate our policies (including all applicable terms of our Services) or the applicable laws; identify repeat visitors and enhance user stickiness to our Services; verify the identity of you or your authorized agents.',
    content35: 'We are in collaboration with government and law enforcement officials or private parties to enforce and comply with applicable laws and regulations.',
    content36: 'Our Services are not applicable for children, so we prevent anyone under the age of 18 or below the age of legal majority in the relevant jurisdiction from using our Services. We will not knowingly or intentionally collect or process any Personal Information concerning children. Once we become aware that a child has provided us with Personal Information, we will take immediate steps to delete it.',
    content37: 'For juveniles under the age of 14, apart from complying with this Privacy Policy regarding the user Personal Information, we will also adhere to the principles of legitimacy and necessary, informed consent, clear purpose, safety and security, and lawful utilization, and strictly comply with the requirements of laws and regulations for the storage, use and disclosure of the Personal Information of the child. Meanwhile, we will not exceed the necessary time limit to achieve the collection and use purposes, and after the specific time limit, we will delete or anonymize the Personal Information of the child.',
    content38: "All Personal Information provided to SoulLand will be securely stored and accessible only by authorized persons. We use encryption and security software to encrypt data transmission, ensuring the privacy and security of your Personal Information through security formats and preventing it from unauthorized access or disclosure, accidental loss, alteration, or destruction. While the security of your Personal Information is significant to us, please understand that no transmission methods over the internet is fully and completely secure. While we strive to protect your Personal Information through commercially acceptable means, we cannot guarantee it's absolutely secure.",
    content39: 'If we transfer your Personal Information to countries or regions outside of your country of residence, we will take appropriate measures to comply with the requirements of applicable laws and regulations in your home country or region.',
    content40: 'Once your Personal Information is no longer needed, we will cease to retain it, or delete or de-identify it.In accordance with applicable laws and regulations, we reserve the right to charge reasonable fees for processing data requests.We will take reasonable measures to ensure the accuracy of the Personal Information we collect. If we become aware that the foregoing Personal Information is inaccurate, we will cease to use it and will inform any third parties that such Personal Information has been transferred to.',
    content41: 'We share your Personal Information in accordance with the principles of legality and legitimacy, minimum necessity, and clear purposes. Additionally, we will conduct a personal information protection impact assessment before sharing any Personal Information, and adopt effective technical protection measures for data output, circulation, and usage. As for cooperation agreements, we strictly require partners to fulfill obligations and responsibilities for information protection.',
    content42: 'We will not share your information with any third parties, except in the following situations:',
    content43: 'With your clear consent or authorization.',
    content44: 'When required by applicable laws, regulations, legal procedures, or mandatory administrative or judicial requests to share your information.',
    content45: 'When sharing your personal information is of necessity to unleash the core functions of our products and Services or to provide the Services you require.',
    content46: 'In order to provide you with our products or Services, show you content that may interest you, and ensure the security of your account and transactions, we may share your Personal Information with our affiliated companies. We will only share necessary and essential information and will be subject to the purposes stated in this Privacy Policy.         ',
    content47: 'We may share your Personal Information with third parties or partners to provide Services to you. Please note that we will only share your Personal Information for legal, legitimate, necessary, specific, and clear purposes, and only the information necessary to provide our Services will be shared. Our partners have no right to utilize the shared Personal Information for any other purposes.',
    content48: 'For academic research.',
    content49: 'As required in the separate Service agreements you signed or other legal documents.',
    content50: 'Each Party (i) waives all its respective right(s) to have any and all disputes, claims, suits, actions, causes of action, demands or proceedings (collectively, "Disputes") arising from or related to this Agreement resolved in a court, and (ii) waive all its respective right(s) to have any Disputes heard before a court. Instead, each Party shall arbitrate Disputes through binding arbitration (which is the referral of a Dispute to one or more persons charged with reviewing the Dispute and making a final and binding determination to resolve it instead of having the Dispute decided by a judge or jury in court). ',
    content51: 'Any Dispute arising out of or related to this Agreement is personal to you and will be resolved solely through individual arbitration, and in no circumstances shall be brought as a class arbitration, class action or any other type of representative proceeding. There will be no class arbitration or arbitration in which an entity attempts to resolve a Dispute as a representative of another individual or group of individuals. Further, a Dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals.',
    content52: 'Any Dispute arising out of or in connection with this Agreement (including without limitation the enforceability of this Clause or any question regarding its existence, validity or termination) shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre ("SIAC") in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in this Clause. The seat of the arbitration shall be Singapore. The Tribunal shall consist of 1 arbitrator. The language of the arbitration shall be English.',
    content53: 'The arbitrator does not have the authority to conduct a class arbitration or a representative or class action, which is prohibited by this Agreement. The arbitrator may only conduct an individual arbitration and may not consolidate more than one individual’s claims, preside over any type of class or representative proceeding or preside over any proceeding involving more than one individual. ',
    content54: 'If any term, clause or provision of the dispute resolution clause is held invalid or unenforceable, it will be so held to the minimum extent applicable and required by law, and all other terms, clauses and provisions of this dispute resolution clause  will remain valid and enforceable.',
    title1: 'Revisions to this Privacy Policy',
    title2: 'Categories of the Collected Information',
    title3: 'Device-Related Information',
    title4: 'Information Provided as a Content Provider',
    title5: 'Cookies',
    title6: 'How We Use Your Information',
    title7: 'Providing Services to You',
    title8: 'Customizing and Measuring Advertisements ',
    title9: 'Internal Research and Development',
    title10: 'Security Detection, Protection and Enforcement; Function Debugging, Bug Fixing',
    title11: 'Legal Obligations',
    title12: "Children's Privacy",
    title13: 'Security',
    title14: 'Transfer of Personal Information',
    title15: 'Retention',
    title16: 'Sharing',
    title17: 'DISPUTE RESOLUTION',
    title18: 'ARBITRATION PLEASE READ THE FOLLOWING CLAUSE CAREFULLY BECAUSE IT CONTAINS CERTAIN PROVISIONS, SUCH AS A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER, WHICH AFFECT YOUR LEGAL RIGHTS. THIS CLAUSE REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH US.',
  },
  //登录注册
  login: {
    log: 'Login',
    Forget: 'Forget password',
    passwardLog: 'Log in with password',
    emailLog: 'Log in with your email verification code',
    register: 'Sign up',
    welcome: 'Welcome to SoulLand! Please log in your account.',
    placeholder1: 'Please enter your email',
    getCode: 'Get Verification Code',
    again: 'Resend in ',
    placeholder2: 'Please enter correct email verification code.',
    placeholder3: 'Please enter your password.',
    placeholder4: 'Please enter your email address.',
    placeholder5: 'Please enter your password',
    placeholder6: 'Please enter your Invitation code',
    logIn: 'Log in ',
    msg1: 'Your Login/Registration signifies your understanding and acceptance of',
    msg2: 'Register your SoulLand account for more.',
    msg3: 'Please enter a valid email address to register your SoulLand account.',
    msg4: 'Please enter a valid email address to get verification code.',
    msg5: 'We have sent a verification code to the email ',
    msg6: ' please enter the correct verification code.',
    msg7: 'Please set your password and remember it.',
    msg8: 'You can now reset your password.',
    msg9: 'Your SoulLand account is created. Congratulations! ',
    msg10: 'New password created. Please remember it.',
    find: 'Password Recovery',
    signUp: 'Sign up',
    next: 'Next',
    Completed: 'Completed',
    set: 'Set up',
    logOut: 'Log out',
  },
  //公用
  common: {
    Refresh: ' Refresh',
    retuen: 'Return',
    Confirm: 'Confirm',
    download: 'Download',
    msg1: 'Global First Social Feeds-Themed Metaverse',
    msg2: 'Disclaimer: This article is solely and independently written by creators within the SoulLand, and only represent their personal ideas. Please note that the views expressed do not reflect the stance of SoulLand.',
    msg3: 'Attention: Investment involves risks, exert you caution. This material should not be deemed as investment or financial advice. ',
    Comments: 'Comments',
    Comment: 'Comment',
    Author: 'Author',
    Delete: 'Delete',
    Reply: 'Reply',
    CommentAll: 'More',
    CommentAll2: 'View all replies',
    Share: 'Share',
    time: 'Release time',
    Cancel: 'Cancel',
    understood: 'Understood',
    Submit: 'Submit',
    noMore: 'The end',
    Source: 'Sources',
    search: 'Search',
    floolw: 'Following',
    say: 'What you want to say...',
    understoodBtn: "I understood",
    downloadMsg: 'Stay tuned!',
    Intro: "Intro",
    enter: "Please enter the content",
    logoutMsg: "Are you sure to log out?",
    delMsg: "Succeeded",
    SLT: "DMD: DMD is used for joining Guilds with Paid Content and purchasing Notification Cards. DMD can only be purchased with USDT, and you need to pay 1 USDT for 1 DMD.SLT: SLT is the points in the SoulLand app. Users can earn SLT rewards by doing tasks within SoulLand app (such as Check-in, answering quizzes, inviting new users, engaging in Space Fighter Arena, etc) . SLT can be used to join regular Guilds, level up Guilds, purchase props in the Shopping Center, participate in drawings, level up or upgrade Space Fighters and engage in Space Fighter Arena, etc.",
    transfer: 'When transferring funds from the SoulLand wallet to another wallet, users are required to pay a certain amount of gas fee based on the blockchain conditions. Please make sure that the transfer is conducted on the same network; otherwise, the transaction will not be successful. SoulLand is not responsible for any losses or consequences resulting from personal errors or misoperations.',
    pay: '1.The costs for joining Guilds with Paid Content is determined by Guild Chief Administrators, and the SoulLand APP will not be involved in it. 2.Before joining Guilds with Paid Content, users should fully understand details and rules of the Guild, including but not limited to the amount of Guild fees, payment frequency, and accepted payment methods. 3.Guild Chief Administrators should formulate rules to maintain order and harmony within the Guild, preventing any form of abuse or improper behaviors. 4.The SoulLand APP shall not be liable for any agreements reached between users and any third parties.',

  },
  // KOL认证
  Kol: {
    state1: 'Please apply for KOL identity first.',
    state2: 'To apply for KOL identity',
    msg1: 'Please apply for KOL identity first before entering the Creation Center.',
    msg2: 'Your application is under review, please wait patiently.',
    msg3: 'Application rejected. Please resubmit or contact our Customer Service.',
    msg4: 'Congratulations! You are now a KOL in the SoulLand world!',
    apply: 'To apply for KOL identity',
    placeholder1: "Nickname",
    placeholder2: "Fields of Expertise",
    placeholder3: "Twitter",
    placeholder4: "Telegram",
    placeholder5: "Please enter your personal experience",
    placeholder6: "Contact Information",
    placeholder7: "Contact Information",
    placeholder8: "Followers",
    userName: "Edit Username",
    msg5: "Username should avoid special symbols or obvious marketing intentions. ",
    save: "Save",
    brief: "Personal Description",
    msg6: "Ensure the content is complete and readable, without any special symbols. Do not input any contact information such as Weibo, phone numbers, Twitter, etc.",
    placeholder9: "Enter your personal description",

  },
  download: {
    title: 'Global First Social Feeds-Themed Metaverse Platform',
    title2: 'Open a new era of Web3.0',
    title3: 'App Store',
    title4: 'Google Play',
    title5: 'APK for Android Download',
    title8: 'Android Download',
    title6: '(CN)',
    title7: '(EN)',
    title9: 'International version, for users with non-restricted IP address',
    title10: 'Optimized version, for users with restricted IP address',
  },
  //钱包
  wallet: {
    tips: 'Attention',
    msg: 'Wallet feature is being optimized. Stay tuned!',
    understood: 'I understood',
    Swap: 'Swap',
    Balance: 'Balance',
    Balance2: 'Exchange amount',
    Maximum: 'Maximum',
    msg1: 'Attention: Please deposit USDT to your wallet in case of insufficient balance.',
    msg2: 'Deposit is completed, you can check balance in your wallet.',
    msg29: 'Request submitted! Once review is passed, you can check balance in your wallet.',
    msg3: 'Please enter...',
    msg4: 'Exceeded maximum amount',
    msg5: 'Insufficient gas fee! This transaction requires ',
    msg6: 'USDT as gas fee, do you want to exchange for more?',
    msg66: ' as gas fee, do you want to exchange for more?',
    msg7: 'on-chain gas fee. Do you want to exchange for more?U',
    msg8: 'You have',
    msg9: 'In exchanging...',
    msg10: 'Only use this Deposit Address on the BSC chain, otherwise you may lose your funds.',
    msg11: 'No history records...',
    msg17: 'Wallet locked',
    Quantity: 'Quantity',
    Completed: 'Completed',
    consume: 'Amount',
    again: 'Exchange for more',
    Attention: 'Attention',
    wait: 'Waiting...',
    Wallet: 'Wallet',
    Total: 'Total Balance',
    mineTotal: 'My assets',
    Withdraw: 'Withdraw',
    Address: 'Wallet Address',
    placeholder1: 'Please enter wallet address...',
    placeholder2: 'Please enter correct email verification code.',
    Paste: 'Paste',
    emailAddress: 'Email address',
    Transaction: 'Estimated transaction Fees',
    History: 'History Records',
    navList: [{ name: 'Deposit' }, { name: 'Withdraw' }, { name: 'Swap' }, { name: 'Income' }, { name: 'Expenditure' }],
    completed1: 'Completed',
    completed2: 'Completed',
    completed3: 'Completed',
    Failed1: 'Failed',
    Failed2: 'Failed',
    Failed3: 'Failed',
    Waiting1: 'Waiting...',
    Waiting2: 'Waiting...',
    Waiting3: 'In exchanging...',
    Details1: 'Details',
    Details2: 'Details',
    Details3: 'Details',
    Failed4: 'Failed',
    Waiting4: 'Waiting...',
    Date: 'Date',
    Hash: 'Transaction Hash',
    Address2: 'Address',
    Mainnet: 'The Mainnet',
    msg12: 'Exchange is completed, you can check balance in your wallet.',
    msg13: 'Withdrawal is completed, you can check balance in your wallet.',
    Consumption: 'Quantity',
    Address3: 'Deposit Address',
    Copy: 'Copy',
    msg14: 'Please enter...',
    msg15: 'Exceeded maximum amount',
    msg16: 'Insufficient gas fee',
    Guild: 'Guild expenses',
    Mall: 'Mall expenses',
    msg18: 'Newly received DMD can be used after 15 days',
    msg19: 'Are you sure to enable the wallet now?',
    msg20: 'My wallet',
    msg21: 'Long-press to paste wallet address',
    fee: 'Fee',
    received: 'Actual received',
    msg22: 'Comming soon',
    msg23: 'Redeem at least 100SLT',
    msg24: 'Under Review',
    msg25: 'Each account can only be redeemed once',
    msg26: 'Approved',
    msg27: 'Review rejected',
    msg28: 'Insufficient amount',
    rule: `1.Each account can only make one exchange, with a minimum amount of 100 SLT.
    2. Exchange Ratio: Each exchange involves 50% of the total SLT withdrawal amount being burnt, along with a deduction of 20 SLT and a 5% transaction fee.
    3.Tip: This exchange is independent of BP values.
    4.This exchange is based on your SLT before 00:00 on June 1, 2024.`
  },
  error: {
    msg1: "Invalid email format",
    msg2: "A verification code has been sent.",
    msg3: "Please enter your email address",
    msg4: "Please enter correct email verification code.",
    msg5: "Succeeded",
    msg6: "Please enter your 6-digit verification code",
    msg7: "The passwords you entered do not match.",
    msg8: "The password must be composed of letters and numbers, with 8-16 characters",
    msg9: "Succeeded",
    msg10: "Your password is recovered",
    msg11: "Please enter your password.",
    msg12: "Please enter the article title",
    msg13: "Please enter article summary...",
    msg14: "Please enter article content...",
    msg15: "Please upload article cover",
    msg16: "Please select article tags",
    msg17: "Please select language",
    msg18: "Succeeded",
    msg19: "Saved to Drafts",
    msg20: "Please select",
    msg21: "Saved",
  },
  cropper: {
    rotate1: "counterclockwise",
    rotate2: "clockwise",
    Upload: "Upload"
  },
  //新年任务活动
  newYear: {
    time: 'Duration: Jan 5th, 2024 12 pm (UTC)- Jan 19th, 2024 12 pm (UTC)',
    rule: 'All tasks are completed within the SoulLand APP. Log in your account to check your ranking.',
    userMsg1: '',
    userMsg2: 'Your points:',
    userMsg3: ',Ranking',
    userMsg4: 'Ranking: ?',
    out: 'Log out',
    taskTitle: 'Details',
    taskTitle2: 'Individual Tasks:',
    taskList1: `1.Quizzes: Limitless chances for "Practice", 1 point for each correct answer. Only two chances one day for "Start today's quizzes" , 1 point for each correct answer.`,
    taskList2: '2.Join Guilds in SoulLand: Limitless chances. Users can join new Guilds whenever they want, 2 points for joining a Guild.',
    taskList3: '3.Post Comments (on Guild Content or on Articles): Maximum of 5 comments per day, 1 point for a comment, with no less than 5 words. A total of 5 points will be awarded for 5 comments.',
    taskTitle3: 'Socializing Tasks:',
    taskList4: '1.Share Guild Articles, Newsflash, or Tweets: 2 points for each sharing content.',
    taskList5: '2.Invite Friends: 5 points for each friend invited. Limitless chances for invitations.',
    taskTitle4: 'Reward Distribution',
    taskList6: 'Leaderboard will be finalized on Jan 19th, 2024 12 pm (UTC)',
    taskList7: 'Rewards will be distributed within 7 business days.',
    taskList8: 'The top',
    taskList9: 'winners on the Leaderboard will earn',
    taskList10: 'SLT, and additional rare NFT airdrops will be distributed to the top',
    taskList11: 'ones.',
    taskList12: '',
    taskList13: 'These NFTs can be used in upcoming activities within SoulLand.',
    taskTitle5: 'Friendly Reminder',
    taskList14: 'The final interpretation of this event belongs to the SoulLand official, including issues concerning detailed rules, point calculation, reward distribution, etc. Audits will be conducted on winners in this event, and if there is any misbehavior or being identified as a bot, the corresponding rewards will be revoked and redistributed to the next eligible user.',
    taskList15: '',
    taskList16: '',
    taskTitle6: 'Download App',
    taskList17: 'Unlock engaging events!',
    taskList18: ' Tip: ',
    taskList19: 'The points above are solely used to record rankings and have nothing to do with SLT.',
    msg: 'Swipe up to show less',
    msg2: 'Stay Tuned...',
    download: 'Download now',
    invite: 'Invite friends',
    tip: 'Attention',
    tipContent1: 'Few players at this level. Wait time may be long. Try another Galaxy.',
    tipContent2: 'The game is on. Try again later.',
  },
  //龙年活动
  loong: {
    Details: 'Details',
    time: 'Feb 7th, 2024, 2 AM (UTC) - Feb 17th, 2024, 2 AM (UTC)',
    residue: 'Lottery Entries Left',
    residue2: '',
    getResidue: ' Get Lottery Entries',
    award: 'Draw',
    content1: 'To celebrate the arrival of the Year of the Dragon, we are thrilled to present you "Year of the Dragon:  Mystery Box Festivity for SoulLanders"!',
    content2: 'Login SoulLand, Authorize Twitter - Unlock Mystery Box',
    content3: 'The Mystery Box contains SLT, NFT, Notification Card, Trumpet, USDT, and more. Generous rewards await you!!!',
    content4: 'Invite Friends -  Every 5 Users Invited, 1 Extra Lottery Chance',
    content5: '',
    content6: 'Post tweets to invite friends to register SoulLand accounts! For every 5 new users you invite, you will receive an extra chance for the lottery draw. The more invites, the greater chances of winning SpaceFighter NFTs!',
    content7: 'Share the Festivity - Greater Rewards, Greater Surprises',
    content8: 'Share your invitation link and the Festivity details with others to double your chances in the Mystery Box draw and unlock even greater surprises!',
    content9: 'Come and join us to open your Mystery Box, kicking off the Year of the Dragon 2024 with good luck!',
    content10: 'Friendly Reminder:',
    content11: 'The final interpretation of this event belongs to the SoulLand official, including detailed rules, reward distribution, etc. Audits will be conducted on winners in this event, and if there is any misbehavior or being identified as a bot, the corresponding rewards will be revoked.',
    content12: 'For Lottery Entry',
    content13: 'Authorize your Twitter account',
    content14: 'Authorize',
    content15: 'Authorized',
    content16: 'Share via Twitter',
    content17: 'Share',
    content18: 'Shared',
    content19: 'For every',
    content20: 'new users you invite, you will receive an extra chance for the lottery draw. The more invites, the greater chances of winning ',
    content21: 'SpaceFighter NFTs!',
    content22: 'Share the Festivity:',
    content23: 'Copy',
    content24: 'This is your invitation link. Only invites via this link will be counted, while other links are invalid for this event.',
    content25: 'My invitation code:',
    content26: 'Number of invites:',
    content27: 'Lottery entries via invites:',
    content28: '',
    content29: 'Mystery Box Draw',
    content30: 'Congratulations',
    content31: 'Please authorize your Twitter account first!',
    content32: 'Refreshed',
    content33: 'Rewards in your Mystery Box will be distributed to your SoulLand account and in-app Pack.',
    content34: 'Please get more lottery entries!',
    content35: 'Succeeded',
    content36: 'Failed',

  },
  //游戏规则
  gameRule: {
    title: 'Rules of SpaceFighter Arena Game',
    content1: '1.Select SpaceFighter and Galaxy',
    content2: 'Users who own NFTs can participate in the SpaceFighter Arena Game. ',
    content3: 'Users can select the SpaceFighter they want to use in the game, ',
    content4: 'and then choose different slts below as damage control fees and enter the corresponding Galaxy. ',
    content5: 'Details are as follows:',
    content6: '(1) Beginner Galaxy 10 slt',
    content7: '(2) Intermediate Galaxy 30 slt',
    content8: '(3) Advanced Galaxy 50 slt',
    content9: '(4) Demonic Galaxy 100 slt',
    content10: '(5) Dark Galaxy 200 slt',
    content11: '(6) Abyssal Galaxy 500 slt',
    content12: 'Upon entering the target Galaxy, a fixed number of slts will be automatically deducted.',
    content13: '2.Join in the Battle',
    content14: 'After entering the target Galaxy, users wait for the battle to begin.',
    content15: 'Once the battle commences, a 30-second countdown will start, during which users can choose any one of the planets at their will. ',
    content16: `Once selected, the planet can’t be changed. If users haven't chosen a planet when the countdown ends, the system will randomly allocate planets to the remaining users. `,
    content17: 'Then, the Boss SpaceFighter appears and roams among the planets and randomly selects one for the battle.',
    content18: 'Based on combat power, there are three results: Clean Sweep, Survival, or Defeat.',
    content19: '(1) Defeating the the Boss SpaceFighter is deemed as Clean Sweep.',
    content20: '(2) Surviving the battle is deemed as Survival.',
    content21: '(3) Being defeated by the Boss SpaceFighter is deemed as Defeat.',
    content22: 'Once the result is determined, the battle ends, and the settlement phase begins.',
    content23: 'Battle Settlement:',
    content24: '(1) If the result is Clean Sweep, meaning the Boss SpaceFighter attacks a user but fails and dies, the user receives total damage control fees of the 10-person Galaxy after transaction fees have been deducted.',
    content25: '(2) If the result is Survival, meaning the Boss SpaceFighter attacks a user with a non-maximum combat power and that user is killed, the remaining users are considered survivors and evenly split the damage control fees of the 10-person Galaxy after transaction fees have been deducted.',
    content26: '(3) If the result is Defeat, the defeated user will not receive any rewards.',
  },
  game: {
    Rules: 'Rules',
    Leaderboard: 'Leaderboard',
    costs: 'Maintenance costs',
    SpaceFighter: 'Choose a SpaceFighter',
    Change: 'Change',
    Choose: 'Choose',
    Level: 'Level',
    Galaxy: 'Choose a Galaxy',
    Start: 'Start',
    Choose2: 'Choose',
    Injecting: 'Injecting Fuel',
    Injecting2: 'Fuel injection completed',
    speak: `It's your turn to speak.`,
    Countdown: 'Countdown',
    Choose3: 'Selected',
    txt1: 'Choose a Planet to land on!',
    txt2: 'To land on Planet',
    txt3: ` (It can't be changed!)`,
    txt4: 'Game Over！',
    txt5: 'Sorry, you are defeated. Try again!',
    txt6: 'Congratulations! You survived!',
    txt7: 'Well done! You are the only winner in this round!',
    Rewards: 'Rewards',
    Back: 'Back',
    Battle: 'Battle Record',
    Total: 'Total Points',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly',
    Battles: 'Total Battles',
    Wins: 'Wins',
    Defeat: 'Defeat Boss',
    Invincible: 'Invincible',
    Defeats: 'Defeats',
    Performance: 'Performance',
    Location: 'Location',
    Appearances: 'Appearances',
    Rate: 'Win Rate',
    Planet: 'Planet ',
    times: 'times',
    download: 'Download APP',
    txt8: 'Download SoulLand app for more SLT.',
    invite: 'Invite friends',
    tip: 'Attention',
    tipContent1: 'Few players at this level. Wait time may be long. Try another Galaxy.',
    tipContent2: 'The game is on. Try again later.',
    tipContent3: 'Matching in progress, do you want to quit?',
    available: 'Not available yet!',
    txt9: '',
    txt10: 'SLT is required as damage control fees. Do you want to continue?No refreshing to prevent data loss.',
    txt11: 'Choose your planet',
    txt12: 'Records',
    txt13: '',
    txt14: 'Planet',
    txt15: 'Survive',
    txt16: 'Defeat',
    txt17: 'Clean Sweep',
    txt18: 'Sorry, you are defeated. Try again!',
    txt19: 'Battle Rewards',
    room: 'Room',
    popularity: 'Galaxy Popularity',
    txt20: 'Player',
    txt21: 'secured a clean sweep and garnered',
    txt22: 'SLT rewards.',
    txt23: 'selected Planet',
    txt24: '.',
    txt25: 'Choose a SpaceFighter',
    txt26: 'When a ravager attacks a user but fails and dies, the user receives the total damage control fees from the 10-person Galaxy after transaction fees have been deducted. When a ravager attacks a user’s Space Fighter and that user is killed, other users are considered survivors and evenly split the damage control fees of the 10-person Galaxy after transaction fees have been deducted; while the defeated user does not receive any rewards.',
    txt27: 'Your current account data remains active in the game due to a mid-game refresh. Please wait patiently.',
    txt28: 'You have landed on',
    txt29: '. The game is about to begin...',
    txt30: 'is available. Would you like to land on it? (Unchangeable once chosen)',
    txt31: '. Insufficient energy to travel to',
    txt32: 'other players have already logged in first! Please choose another planet to land on.',
    txt33: 'Details',
    txt34: 'Landing',
    txt35: 'Logged in',
    txt36:'Scan it',
    txt37:'Explore',
    txt38:'View SLT',
  },
  BP: {
    txt1: 'For users participating in Galaxy Arena, every',
    txt4: 'slt spent as damage control fees will get you',
    txt5: 'BP. The first time for exchanging SLT requires a minimum of',
    txt6: 'BP. Exchanging SLT points token on the platform earns you equivalent BP.',
    txt2: 'Max withdrawal amount',
    txt3: 'Current Available balance'
  },

  star: {
    title: 'Planet details',
    Planet1: {
      name: 'Utarpa',
      detail: 'Utarpa is a planet surrounded by many moons, a distant sanctuary covered with massive craters. Its windy surface is dry as a desert, but deep within the porous crust, there are halls of life-sustaining water at the bottom of each crater. The planet has a number of small continents. On one of these continents, complex cities line many of the craters. These cities extend into the depths of caves and rock crevices below the surface. Wars are fought all the time on this planet, and it is also on this planet that the rich underground resources are attracting more and more plutocrats and bandits.'
    },
    Planet2: {
      name: 'Holessinia',
      detail: 'Holessinia is the main planet of a city-covered urban planet that is constantly expanding, located on the Core Worlds, a traditionally wealthy region of the galaxy. From Decat, heading north, either on the Corellian Run or the Hydian Voyage, through the Central Rim, the Expansion Zone, and the Inner Rim, up to Denon. Crossing to the Corellian Trade Ridge and entering the Core Worlds leads to the Holessinia system, where the main planet of Holessinia is the temporary seat of the Republican Council. The Corellian Trade Ridge extends northward to Corellia and Duro, a region where planets are usually economically developed and influential.'
    },
    Planet3: {
      name: 'Kaxic',
      detail: 'Kaxic is a strategic location, serving as the gateway to the entire southwest quadrant of the galaxy. The ecosystem of Kaxic is unique, characterized by layered biodiversity. The forests of this planet are divided into several different ecological layers from top to bottom, becoming more dangerous as you go down. The intelligent species, the Wookiees, inhabit the uppermost layer of the forest canopy. Inland, some lower layers have not seen sunlight for thousands of years and are filled with deadly, primitive creatures. The Wookiee civilization divides the forest into seven vertical layers. Victory is short-lived. With the rise of the Galactic Empire, Imperial soldiers took control of Kaxic and imposed martial law on this planet.'
    },
    Planet4: {
      name: 'Holes',
      detail: 'Holes is the sixth planet in the distant galaxy, an icy world surrounded by many moons. Meteorites straying from the nearby asteroid belt constantly bombard the icy surface of Holes. This ice world has given rise to a number of native life forms, including the Wampa Ice Beasts and Soup Soup. Daytime temperatures here are tolerable for humans in suitable warm clothing, yet night is too harsh even for native life. Holes is barely habitable, with its axial inclination, orbital position, and atmospheric composition causing its temperature to fall below zero. Daytime temperatures on Holes may rise to around -32 degrees. At night, temperatures drop to -60 degrees, with gusty winds making it feel even colder.'
    },
    Planet5: {
      name: 'Naboo',
      detail: 'Naboo is the third planet in the Naboo system. It’s an idyllic world, inhabited by the peace-loving Naboo people, as well as the intelligent amphibious indigenous creatures, the Gungans. Naboo has a mild climate, and its surface consists of swampy lakes, rolling wilderness, and verdant hills. Its river cities are brilliantly decorated with classical architecture and greenhouses, while the underwater habitats of the Gungans are a vivid display of an exotic hydrostatic bubble technology.'
    },
    Planet6: {
      name: 'Enndoo',
      detail: 'The gas planet Enndoo is hidden in the the Outer Rim. Enndoo is codenamed the Sanctuary Satellite. Against the dark backdrop of space, Enndoo looks like a purple jewel. Unique atmosphere have produced a distinct purple ecosystem, with rivers meandering under a purple cover and forests rising high above the fertile native white soil and low mountains. Enndoo is rich in species, with intelligent creatures including the Ivoks and their distant cousins, the Durocs.'
    },
    Planet7: {
      name: 'Dargbar',
      detail: `Dargbar, located in the distant Sluis sector of the Outer Rim, lies 50,000 light-years from the galactic core. It is a swampy planet shrouded in thick fog, a forgotten and nowhere-to-found world that becomes a refuge for travelers and refugees amidst the galaxy's increasing turmoil. The planet is surrounded by rugged trees and foul-smelling swamps. Huge lakes became home to scary snakes and other creatures that lived in the dark waters.`
    },
    Planet8: {
      name: 'Kamino',
      detail: `Kamino is an isolated world beyond the Outer Rim, located south of the Rishi Maze. It is a planet of raging oceans and endless storms. Only a few parts protrude above the ocean's surface, and these are the towering cities inhabited by the planet's natives, the Kaminoans. Kamino has failed to recover from the catastrophe of global warming that caused the melting of huge inland ice sheets. Its oceans rapidly expanded and covered the continent. All evidence for the existence of Kamino is stored only in well-established archives, yet it is now a complete mystery.`
    },
    Planet9: {
      name: 'Geonosis',
      detail: 'Geonosis is a harsh rocky planet with a planetary ring, lies just outside the borders of the Galactic Republic, less than a parsec from Tatooine. Its lifeless surface is covered with tablelands, hills, and vast expanses of barren, hot sandy deserts. The skies and rocks here are reddish in color, and the creatures that evolved in Geonosis have adapted to survive in its harsh ecological environment. The most advanced form of life here is the Geonosian - an intelligent, insectoid creature that dwells within a nest of towering spires. Geonosians operate large factories producing robots and weapons. The Geonosis usually have no contact with the outside world, except to receive customers who dare to place orders on the planet.'
    },
    Planet10: {
      name: 'Tatooine',
      detail: `Tatooine, part of the Arkanis Sector of the Outer Rim, is a planet moving around a binary star system. It is a vast desert planet, and Tatooine's barren and inhospitable surface has evolved a diverse terrain. When viewed from a distance in space, Tatooine's appearance is a shiny bright yellow due to the high sodium content of the sand, a feature that long led many to mistakenly perceive it as a dark-colored star until the invention of the hyperspace engine allowed for closer examination by humans. At first it seemed unlikely that life could exist on such a harsh planet, but in fact Tatooine has developed a stable climate due to its special position in the orbit of a binary star system. Despite the fact that less than 1% of Tatooine's surface is covered with water, a wide variety of species thrive in a relatively habitable area of the northern hemisphere.`
    },
  },
  logout: `Dear SoulLand user, we detected an attempt to log into your account from a new device.
  Your login in the current device is terminated.
  If this wasn't you, change your password now to protect your account. `,
  chat: `Welcome to SoulLand, the World's First Gamified Social Feeds-Themed Super Application. SoulLand aims to guide Web3 enthusiasts and communities around the world to embrace the future of blockchain. Let's explore how to build personal brands in the meta-universe, share on-chain trading experience, find hot concepts, and explore the infinite possibilities of the Web3 world in gaming.`,
  invite: {
    txt1: 'Come and join this enticing and thrilling PvP game to earn SLT rewards!',
    txt2: 'Link:',
    txt3: 'The current PC version is not online yet.',
    txt4: ' Please use your mobile browser to open the website.',
    txt5: 'Website',
  }

};