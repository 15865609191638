import request from '@/utils/axios';

//获取验证码
export function sendcode(data) {
    return request({
        url: '/api/v1/user/sendcode',
        method: 'post',
        data
    });
}
//登录
export function login(data) {
    return request({
        url: '/api/v1/user/emaillogin',
        method: 'post',
        data
    });
}
//邮箱注册
export function emailRegister(data) {
    return request({
        url: '/api/v1/user/emailreg',
        method: 'post',
        data
    });
}
//邮箱密码登录
export function pwdLogin(data) {
    return request({
        url: '/api/v1/user/pwdlogin',
        method: 'post',
        data
    });
}
//忘记密码
export function fgPwd(data) {
    return request({
        url: '/api/v1/user/fgpwd',
        method: 'post',
        data
    });
}
// 找回密码 发送验证码
export function forGetsendcode(data) {
    return request({
        url: '/api/v1/user/forgetsendcode',
        method: 'post',
        data
    });
}
//查询用户信息
export function getuser() {
    return request({
        url: '/api/v1/getuser',
        method: 'post',
    });
}
//修改用户名
export function editUsername(data) {
    return request({
        url: '/api/v1/editusername',
        method: 'post',
        data
    });
}
//修改用户简介
export function editUserdesc(data) {
    return request({
        url: '/api/v1/edituserdesc',
        method: 'post',
        data
    });
}
//修改头像
export function editUserpic(data) {
    return request({
        url: '/api/v1/edituserpic',
        method: 'post',
        data
    });
}
//认证KOL
export function submitApp(data) {
    return request({
        url: '/api/v1/submitapp',
        method: 'post',
        data
    });
}